import { ActionReducerMap } from '@ngrx/store';
import * as dataSource from '../shared/store/divisions-assets-devices.reducer';
import { DivisionModel } from '../core/_models/DivisionModel';
import { AssetModel } from '../core/_models/AssetModel';
import { DeviceModel } from '../core/_models/DeviceModel';
import { AccountModel } from '../core/_models/AccountModel';
import { accountReducer } from '../pages/select-account/store/select-account.reducer';
import { DeviceConfigMapping } from '../core/_models/DeviceConfigMapping';
import { dataSourceReducer } from '../shared/store/data-source.reducer';
import { loaderReducer, State } from '../shared/store/loader.reducer';

export interface AppState {
  divisionsList: DivisionModel[];
  assetsList: AssetModel[];
  devicesList: DeviceModel[];
  account: AccountModel;
  dataSource: DeviceConfigMapping[];
  ui: State;
}

export const appReducers: ActionReducerMap<AppState> = {
  divisionsList: dataSource.divisionsListReducer,
  assetsList: dataSource.assetsListReducer,
  devicesList: dataSource.devicesListReducer,
  dataSource: dataSourceReducer,
  account: accountReducer,
  ui: loaderReducer
};
