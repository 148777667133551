import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IoTActiveCompanyModel } from 'src/app/core/_models/IoTActiveCompanyModel';
import { GlobalVariables } from 'src/app/core/_common/GlobalVariables';
import { DatadogService } from 'src/app/core/_services/datadog.service';
import { clear } from 'idb-keyval';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.state';
import { DivisionsAssetsDevicesService } from '../../core/_services/divisions-assets-devices.service';
import { addAccount, cleanAccount } from './store/select-account.actions';
import { DataSourceMappedService } from '../../core/_services/data-source-mapped.service';

@Component({
  selector: 'app-select-account',
  templateUrl: './select-account.component.html',
  styleUrls: ['./select-account.component.scss']
})
export class SelectAccountComponent implements OnInit {
  returnUrl: string;
  multipleAccountCheck: boolean = false;
  selectAccountForm: FormGroup;

  filteredCompanies: Observable<IoTActiveCompanyModel[]>;
  iotActiveCompanies: IoTActiveCompanyModel[] = [];

  filteredAccountCodes: Observable<string[]>;
  activeCompanyCodes: string[] = [];

  pageloading: boolean = true;
  accountCode: any;
  companyName: any;
  params: any;
  companyId: string;

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private route: ActivatedRoute,
    private datadogService: DatadogService,
    private store: Store<AppState>,
    private divisionsAssetsDevicesService: DivisionsAssetsDevicesService,
    private dataSourceMappedService: DataSourceMappedService
  ) {
    this.route.params.subscribe((params) => (this.params = params));
  }

  createSelectAccountForm() {
    this.selectAccountForm = new FormGroup({
      companyControl: new FormControl('', [Validators.required]),
      accountCodeControl: new FormControl('', [Validators.required])
    });
  }

  ngOnInit() {
    this.dataDogLogs();
    if (this.route.snapshot.paramMap.get('previousUrl') === '/')
      this.returnUrl = '/config-mapping';
    else this.returnUrl = this.route.snapshot.paramMap.get('previousUrl');
    this.createSelectAccountForm();
    this.loadCompanyList();
    this.filteredCompanies = this.selectAccountForm.controls[
      'companyControl'
    ].valueChanges.pipe(
      startWith(''),
      map((value) => this._filterCompanies(value || ''))
    );
  }

  dataDogLogs() {
    this.datadogService.log(
      `${GlobalVariables.USER_EMAIL} accessed Select Account Page`,
      {
        name: 'Select Account Component',
        id: 1009,
        userName: GlobalVariables.USER_GIVEN_NAME,
        userEmail: GlobalVariables.USER_EMAIL
      },
      'info'
    );
  }

  private _filterCompanies(value: string): IoTActiveCompanyModel[] {
    const filterValue = value.toLowerCase();

    return this.iotActiveCompanies.filter((option) =>
      option.companyName.toLowerCase().includes(filterValue)
    );
  }

  private _filterAccountCode(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.activeCompanyCodes.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }

  onCustomerSelect(selectedCustomer) {
    this.activeCompanyCodes = this.iotActiveCompanies.filter(
      (company) => company.companyId == selectedCustomer.companyId
    )[0].companyCode;
    this.activeCompanyCodes.sort((a, b) => a.localeCompare(b));
    this.filteredAccountCodes = this.selectAccountForm.controls[
      'accountCodeControl'
    ].valueChanges.pipe(
      startWith(''),
      map((value) => this._filterAccountCode(value || ''))
    );

    this.companyName = selectedCustomer.companyName;
    if (this.activeCompanyCodes.length > 1) {
      this.multipleAccountCheck = true;
      this.selectAccountForm.controls['accountCodeControl'].setValue('');
      this.accountCode = '';
    } else {
      this.multipleAccountCheck = false;
      this.selectAccountForm.controls['accountCodeControl'].setValue(
        this.activeCompanyCodes[0]
      );
      this.accountCode = this.activeCompanyCodes[0];
    }

    this.companyId = selectedCustomer.companyId;

    localStorage.setItem('companyCode', this.companyId);
    clear();
  }

  onAccountSelect(accountCode: String) {
    this.accountCode = accountCode;
  }

  navigateApp() {
    this.dataSourceMappedService.cleanContainersAndCache();
    this.dataSourceMappedService.cleanCompanyAccount();
    this.store.dispatch(cleanAccount());
    this.datadogService.log(
      `${GlobalVariables.USER_EMAIL} selected ${this.companyName} and ${this.accountCode}`,
      {
        name: 'Continue Button',
        id: 10091,
        userName: GlobalVariables.USER_GIVEN_NAME,
        userEmail: GlobalVariables.USER_EMAIL
      },
      'info'
    );
    localStorage.setItem('CUSTOMER_NAME', this.companyName);
    localStorage.setItem('CUSTOMER_ACCOUNT_CODE', this.accountCode);
    this.store.dispatch(
      addAccount({
        account: [
          {
            customer: this.companyName,
            account: this.accountCode,
            company: this.companyId
          }
        ]
      })
    );
    this.divisionsAssetsDevicesService.getDeviceList();
    this.router.navigate([this.returnUrl]);
  }

  // Function to load list of companies  from active companies api
  loadCompanyList() {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('per_page', '500');

    this.httpClient
      .get(`${environment.iotactivecompaniesapi}`, { params: queryParams })
      .subscribe(
        (activeCompanies: IoTActiveCompanyModel[]) => {
          activeCompanies.sort((a, b) =>
            a.companyName.localeCompare(b.companyName)
          );
          this.iotActiveCompanies = activeCompanies;
          this.pageloading = false;
        },
        (err) => {
          this.pageloading = false;
        }
      );
  }

  setAccount(customer: any) {
    this.selectAccountForm.controls['companyControl'].setValue(
      customer.companyName
    );
    this.onCustomerSelect(customer);
  }
}
