import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, mergeMap } from 'rxjs/operators';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  private activeRoles: any[] = [];
  public missingRole$ = new BehaviorSubject<string>('');

  constructor(private httpClient: HttpClient, private router: Router) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    if (route.data.role === Roles.NONE) {
      return of(true);
    }

    if (this.activeRoles.length > 0) {
      if (!this.activeRoles.includes(route.data.role)) {
        this.missingRole$.next(route.data.role);
        this.router.navigate(['/unauthorized']);
      }

      return of(this.activeRoles.includes(route.data.role));
    } else {
      return this.getUserId().pipe(
        mergeMap((userIdResponse) => {
          if (!userIdResponse || userIdResponse.length <= 0) {
            return of(false);
          }

          const userIdResponseToUse = userIdResponse[0];

          if (userIdResponseToUse === undefined) {
            return of(false);
          }

          return this.getRoles(userIdResponseToUse.id).pipe(
            map((roleResponse) => {
              if (!roleResponse || roleResponse.length === 0) {
                return false;
              }

              let roleToUse;

              if (environment.production) {
                roleToUse = roleResponse.find((response: any) => {
                  return (
                    response.applicationId ===
                    'e7cf7177-31e1-4b4a-896e-4ba209c0f9d9'
                  );
                });
              } else if (environment.stage) {
                roleToUse = roleResponse.find((response: any) => {
                  return (
                    response.applicationId ===
                    'e258cf60-6663-471f-a856-97a83f76930f'
                  );
                });
              } else {
                roleToUse = roleResponse.find((response: any) => {
                  return (
                    response.applicationId ===
                    '1943400d-549c-4425-a333-185c494a3921'
                  );
                });
              }

              if (!roleToUse) {
                return false;
              }

              this.activeRoles = roleToUse.roles.flatMap((rollInfo: any) => {
                return rollInfo.permissions;
              });

              if (route.data.role === Roles.NONE) {
                return true;
              }

              if (!this.activeRoles.includes(route.data.role)) {
                this.missingRole$.next(route.data.role);
                this.router.navigate(['/unauthorized']);
              }
              return this.activeRoles.includes(route.data.role);
            })
          );
        })
      );
    }
  }

  public getUserId(): Observable<any> {
    return this.httpClient.get<any>(environment.userId);
  }

  public getRoles(userId: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.roles}${userId}&embed=user,roles`
    );
  }
}

export enum Roles {
  WriteConfigs = 'iot-device-manager:write:configs',
  CheckConfigStatus = 'iot-device-manager:write:check_config_status',
  WriteProvision = 'iot-device-manager:write:provision',
  WriteDeprovision = 'iot-device-manager:write:deprovision',
  WriteReprovision = 'iot-device-manager:write:reprovision',
  WriteDeviceSettings = 'iot-device-manager:write:device_settings',
  CreateConfigPackages = 'iot-device-manager:create:config_packages',
  ViewConfigs = 'iot-device-manager:view:configs',
  ViewConfigPackages = 'iot-device-manager:view:config_packages',
  VieDeviceSettings = 'iot-device-manager:view:device_settings',
  ViewProvisioning = 'iot-device-manager:view:provisioning',
  AdminFullAccess = 'iot-device-manager:admin:full-access',
  NONE = 'DEFAULT',
  CustomerSuccess = '',
  ProductSolutionsEngineer = ''
}
