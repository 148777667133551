import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { NavigationEnd, Router } from '@angular/router';
import { ProfileModel } from './core/_models/ProfileModel';
import { AuthService } from '@auth0/auth0-angular';
import { GlobalVariables } from './core/_common/GlobalVariables';
import { EnvironmentValidatorService } from './core/_services/environment-validator.service';
import { NewsService } from './news.service';
import { Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'IoT Device Manager';
  profileJson: ProfileModel;
  public showHome = false;
  public newsMessage: string;
  displayMessage = false;
  private subscription: Subscription;
  private tokenCheckSubscription: Subscription;

  constructor(
    private router: Router,
    public auth: AuthService,
    private environmentValidatorService: EnvironmentValidatorService,
    private newsService: NewsService
  ) {
    console.log(environment.production); // Logs false for default environment
  }

  ngOnInit(): void {
    this.subscription = this.newsService.currentMessage.subscribe((message) => {
      this.newsMessage = message;
      this.displayMessage = true;

      setTimeout(() => {
        this.displayMessage = false;
      }, 5000);
    });

    this.auth.user$.subscribe((profile) => {
      try {
        this.profileJson = profile;
        GlobalVariables.USER_EMAIL = this.profileJson.email;
        this.environmentValidatorService.setCurrentUser(this.profileJson.email);
      } catch (_) {}
    });

    this.router.events.subscribe((routerValue) => {
      if (routerValue instanceof NavigationEnd) {
        const activeRoute = this.router.url.trim();
        this.showHome = activeRoute === '' || activeRoute === '/';
      }
    });

    // Check for token expiration once a minute
    this.tokenCheckSubscription = interval(60000).subscribe(() => {
      this.auth.isAuthenticated$.subscribe((isAuthenticated) => {
        if (!isAuthenticated) {
          this.auth.loginWithRedirect();
        }
      });
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();

    if (this.tokenCheckSubscription) {
      this.tokenCheckSubscription.unsubscribe();
    }
  }

  myOwnMethodToGrabCompany(event): void {
    this.router.navigate(['/account']).then(() => {
      window.location.reload();
    });
  }
}
