import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { PackageConfigModel } from 'src/app/core/_models/PackageConfigModel';
import {
  CREATE_PACKAGE_DIALOG_HEIGHT,
  CREATE_PACKAGE_DIALOG_WIDTH,
  DISPLAYED_COLUMNS,
  PACKAGE_CONFIG_URL
} from './constants';
import { CreatePackageComponent } from './create-package/create-package.component';
import { ConfirmDialogComponent } from '../../components/confirm-dialog/confirm-dialog.component';
import { ViewConfigDialogComponent } from './view-config-dialog/view-config-dialog.component';
import { DatasetApiService } from 'src/app/core/_services/dataset-api.service';
import { DatasetModel } from '../../core/_models/DatasetModel';
import { TriggerApiService } from '../../core/_services/trigger-api.service';
import { TriggerModel } from 'src/app/core/_models/TriggerModel';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  DEVICE_DIALOG_HEIGHT,
  DEVICE_DIALOG_WIDTH
} from '../publishconfig/constants';
import { get, set } from 'idb-keyval';
import { MatSort } from '@angular/material/sort';
import Swal from 'sweetalert2';
import { GlobalVariables } from 'src/app/core/_common/GlobalVariables';
import { DatadogService } from 'src/app/core/_services/datadog.service';
import { CsvExportService } from '../../csv-export.service';

@Component({
  selector: 'app-package-config',
  templateUrl: './package-config.component.html',
  styleUrls: ['./package-config.component.scss']
})
export class PackageConfigComponent implements OnInit {
  tableLoading: boolean = false;
  displayedColumns: string[] = DISPLAYED_COLUMNS;
  dataSource;
  allDatasets: DatasetModel[];
  allTriggers: TriggerModel[];
  packageData: PackageConfigModel[];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private httpClient: HttpClient,
    public dialog: MatDialog,
    private router: Router,
    private datasetApiService: DatasetApiService,
    private triggerApiService: TriggerApiService,
    private datadogService: DatadogService,
    private csvExport: CsvExportService
  ) {
    this.getAllDataset();
    this.getAllTriggers();
  }

  ngOnInit(): void {
    this.datadogService.log(
      `${GlobalVariables.USER_EMAIL} accessed Package-Config Page`,
      {
        name: 'Package-Config Component',
        id: 1006,
        userName: GlobalVariables.USER_GIVEN_NAME,
        userEmail: GlobalVariables.USER_EMAIL
      },
      'info'
    );
  }

  ngAfterViewInit() {
    get('PACKAGE_CONFIG_LIST_KEY').then(
      (packageConfigModel: PackageConfigModel[]) => {
        /*
        if (packageConfigModel) {
          console.log('Found cached data');
          this.loadTable(packageConfigModel);
        }

         */
      }
    );

    this.getAllPackageConfigList();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  async getAllPackageConfigList() {
    this.tableLoading = true;
    /*  get('TRIGGER_LIST_KEY').then((triggerModel: TriggerModel[]) => {
      if (triggerModel) {
        console.log('Found cached data');
        this.allTriggers = triggerModel;
      }
    });
    this.getAllTriggers();

    get('DATASET_LIST_KEY').then((datasetModel: DatasetModel[]) => {
      if (datasetModel) {
        console.log('Found cached data');
        this.allDatasets = datasetModel;
      }
    });
    this.getAllDataset(); */

    await this.httpClient
      .get<PackageConfigModel[]>(`${PACKAGE_CONFIG_URL}?per_page=200`)
      .toPromise()
      .then((data) => {
        /*    The below code is to get the Trigger name & dataset name of package config. Please dont delete */
        /*   for (let prop in data) {
          // Steps to find the trigger names.
          var triggerNames: any[] = [];
          if (data[prop].triggerId != null) {
            data[prop].triggerId.forEach((element) => {
              const trigger = this.allTriggers.find((t) => t.id === element);
              if (trigger) {
                triggerNames.push(trigger.name);
              }
            });
          }
          data[prop] = Object.assign({}, data[prop], {
            triggerNames: triggerNames
          });

          //Steps to find the datset names
          var datasetNames: any[] = [];
          if (data[prop].datasetId != null) {
            data[prop].datasetId.forEach((element) => {
              const dataset = this.allDatasets.find((t) => t.id === element);
              if (dataset) {
                datasetNames.push(dataset.name);
              }
            });
          }
          data[prop] = Object.assign({}, data[prop], {
            datasetNames: datasetNames
          });
        } */
        this.loadTable(data);
        set('PACKAGE_CONFIG_LIST_KEY', data).then(() => {
          console.log(
            'Package Config List stored in cache with the key "PACKAGE_CONFIG_LIST_KEY"'
          );
        });
      });
  }

  private loadTable(packageConfigModel: PackageConfigModel[]): void {
    packageConfigModel = packageConfigModel.sort((a: any, b: any) => {
      return a.name - b.name;
    });
    this.dataSource = new MatTableDataSource(packageConfigModel);
    this.paginator.pageSize = 10;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.tableLoading = false;
  }

  async getAllDataset() {
    await (
      await this.datasetApiService.getAllDatasets()
    ).subscribe((data: DatasetModel[]) => {
      this.allDatasets = data;
      set('DATASET_LIST_KEY', data).then(() => {
        console.log(
          'Dataset List stored in cache with the key "DATASET_LIST_KEY"'
        );
      });
    });
  }

  async getAllTriggers() {
    await (await this.triggerApiService.getAllTriggers())
      .toPromise()
      .then((data: TriggerModel[]) => {
        this.allTriggers = data;
        set('TRIGGER_LIST_KEY', data).then(() => {
          console.log(
            'Trigger List stored in cache with the key "TRIGGER_LIST_KEY"'
          );
        });
      });
  }

  actionPackage(functionType: any, data: PackageConfigModel = null) {
    //if (data == undefined) [(data = '')];
    this.datadogService.log(
      `${GlobalVariables.USER_EMAIL} clicked "Create/Clone" button in "Package-Config" Page`,
      {
        name: 'Create/Clone - Package-Config Component',
        id: 10062,
        userName: GlobalVariables.USER_GIVEN_NAME,
        userEmail: GlobalVariables.USER_EMAIL
      },
      'info'
    );

    let input = {
      data: data,
      functionType: functionType,
      datasetList: this.allDatasets,
      triggerList: this.allTriggers
    };

    const dialogRef = this.dialog.open(CreatePackageComponent, {
      data: input,
      height: CREATE_PACKAGE_DIALOG_HEIGHT,
      width: CREATE_PACKAGE_DIALOG_WIDTH
    });

    dialogRef.afterClosed().subscribe((response) => {
      var result = response.split(',');
      var actionType = result[0];
      var status = result[1];
      var message = result[2];

      if (actionType == 'Create') {
        if (status == 'Success') {
          this.successfulMessage(
            'Package Config created successfully!',
            actionType
          );
          this.getAllPackageConfigList();
        } else this.errorHandler(message, actionType);
      } else if (actionType == 'Clone') {
        if (status == 'Success') {
          this.successfulMessage(
            'Package Config cloned successfully!',
            actionType
          );
          this.getAllPackageConfigList();
        } else this.errorHandler(message, actionType);
      } else if (actionType == 'Update') {
        if (status == 'Success') {
          this.successfulMessage(
            'Package Config updated successfully!',
            actionType
          );
          this.getAllPackageConfigList();
        } else this.errorHandler(message, actionType);
      }
    });
  }

  viewConfig(jsonConfig: any, base64: any) {
    var configData = {
      base64: base64,
      jsonConfig: jsonConfig
    };
    this.dialog.open(ViewConfigDialogComponent, {
      data: configData,
      height: DEVICE_DIALOG_HEIGHT,
      width: DEVICE_DIALOG_WIDTH,
      panelClass: 'view-config'
    });
  }

  deletePackage() {
    this.dialog.open(ConfirmDialogComponent, {
      width: '300px'
    });
  }

  public homeClick(): void {
    this.router.navigate(['']);
  }

  reloadPackageConfigTable() {
    this.datadogService.log(
      `${GlobalVariables.USER_EMAIL} clicked "Refresh Data" button in "Package-Config" Page`,
      {
        name: 'Refresh button - Package-Config Component',
        id: 10061,
        userName: GlobalVariables.USER_GIVEN_NAME,
        userEmail: GlobalVariables.USER_EMAIL
      },
      'info'
    );
    this.getAllPackageConfigList();
  }

  errorHandler(error, actionType) {
    Swal.fire({
      icon: 'error',
      title: actionType + ' Dataset Error',
      text: error
    });
  }

  successfulMessage(message, actionType) {
    Swal.fire({
      icon: 'success',
      title: 'Success!',
      text: message
    });
  }

  getRootUid(jsonConfig: any): string {
    try {
      return jsonConfig[0].dataset.uid.uid;
    } catch {
      return '';
    }
  }

  public exportCSV(data: any): void {
    this.csvExport.exportCSV(data.filteredData);
  }
}
