import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { SignalTriggerMappingCreate } from '../signal-trigger-mapping/signal-trigger-mapping.component';

@Component({
  selector: 'zonar-signal-trigger-mapping-create-modal',
  templateUrl: './signal-trigger-mapping-create-modal.component.html',
  styleUrls: ['./signal-trigger-mapping-create-modal.component.scss']
})
export class SignalTriggerMappingCreateModalComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<SignalTriggerMappingCreateModalComponent>,
    private formBuilder: FormBuilder,
    private http: HttpClient
  ) {}

  public triggerEditGroup: FormGroup;

  ngOnInit(): void {
    this.triggerEditGroup = this.formBuilder.group({
      triggerName: ['', Validators.required],
      logicFieldName: ['', Validators.required],
      transformerName: ['', Validators.required]
    });
  }

  onSubmit(): void {
    const createPayload: SignalTriggerMappingCreate = {
      triggerName: this.triggerEditGroup.get('triggerName')?.value,
      logicFieldName: this.triggerEditGroup.get('logicFieldName')?.value,
      parsingLogic: {
        transformer: {
          name: this.triggerEditGroup.get('transformerName')?.value,
          arguments: {}
        },
        extra: {},
        parameters: []
      }
    };

    this.http
      .post(environment.assetTriggerMappingsCreate, createPayload)
      .subscribe((response) => {
        this.dialogRef.close({ updated: true });
      });
  }

  onClose(): void {
    this.dialogRef.close({ updated: false });
  }
}
