<div class="container">
  <h3>Add all selected companies to whitelist?</h3>

  <div mat-dialog-actions>
    <button mat-raised-button (click)="onNoClick()">No Thanks</button>
    <button mat-raised-button [mat-dialog-close]="true" cdkFocusInitial>
      Ok
    </button>
  </div>
</div>
