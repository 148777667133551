<div class="container" *ngIf="pageLoading">
  <app-loading></app-loading>
</div>

<mat-card *ngIf="!pageLoading">
  <div id="table-title">Asset/Device Config Mapping</div>

  <div id="refresh-container" class="submenu-margin">
    <button
      mat-raised-button
      matTooltip="Refresh Config-Mapping List"
      (click)="refreshData()"
      id="refresh-button"
    >
      <mat-icon [class.rotate]="refreshLoader">refresh</mat-icon>
    </button>

    <button
      class="export-button"
      mat-raised-button
      (click)="exportCSV(dataSource)"
    >
      Export CSV
    </button>
  </div>
  <mat-card id="selections">
    <mat-card-content>
      <div class="row">
        <div class="col-2">
          <input
            id="search-input"
            matInput
            (keyup)="applyFilter($event)"
            placeholder="Ex. 18000085"
          />
        </div>
        <div class="col-3 mx-0">
          <zui-searchable-dropdown
            [label]="'Asset Name'"
            [options]="assetNameList"
            [z_formGroup]="assetNameForm"
            [selectOptionsControlName]="'assetName'"
            [showSelectAllCheckbox]="true"
            [inputType]="'text'"
            [searching]="true"
            [isMultiple]="true"
            [sortByStringTitle]="true"
          >
          </zui-searchable-dropdown>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <div class="mat-elevation-z8 scrollmenu">
    <mat-progress-bar
      mode="indeterminate"
      *ngIf="tableLoading"
    ></mat-progress-bar>
    <table mat-table [dataSource]="dataSource" matSort>
      <!-- Account Code Column -->
      <ng-container matColumnDef="account_code">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Account Code</th>
        <td mat-cell *matCellDef="let element">{{ element.account_code }}</td>
      </ng-container>

      <!-- Asset Name Column -->
      <ng-container matColumnDef="asset_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Asset Name</th>
        <td mat-cell *matCellDef="let element">{{ element.asset_name }}</td>
      </ng-container>

      <!-- Serial Number Column -->
      <ng-container matColumnDef="serial_number">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Serial Number</th>
        <td mat-cell *matCellDef="let element">
          <a
            class="a-left"
            matTooltip="Click to view Asset/Device Details"
            href="javascript:void(0);"
            (click)="viewDeviceDialog(element)"
            >{{ element.serial_number }}
          </a>
        </td>
      </ng-container>

      <!-- VIN Number Column -->
      <ng-container matColumnDef="vin_number">
        <th mat-header-cell *matHeaderCellDef>VIN Number</th>
        <td mat-cell *matCellDef="let element">{{ element.vin_number }}</td>
      </ng-container>

      <!-- Firmware Version Column -->
      <ng-container matColumnDef="firmware_version">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Firmware Version
        </th>
        <td mat-cell *matCellDef="let element">
          <span [innerHtml]="element.firmware_version | customFirmware"></span>
        </td>
      </ng-container>

      <!-- Last Heart Beat Column -->
      <ng-container matColumnDef="last_heartbeat_time">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Last Heart Beat
        </th>
        <td mat-cell *matCellDef="let element">
          <div>
            {{ element.last_heartbeat_time | date: 'MM/dd/yyyy' }}
          </div>
          <div>
            {{ element.last_heartbeat_time | date: 'HH:mm:ss' }}
          </div>
        </td>
      </ng-container>

      <!-- Last Event Time  Column -->
      <ng-container matColumnDef="last_event_time">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Last Event Time
        </th>
        <td mat-cell *matCellDef="let element">
          <div>
            {{ element.last_event_time | date: 'MM/dd/yyyy' }}
          </div>
          <div>
            {{ element.last_event_time | date: 'HH:mm:ss' }}
          </div>
        </td>
      </ng-container>

      <!-- Last Config Send Time  Column -->
      <ng-container matColumnDef="last_config_send_time">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Last Config Send Time
        </th>
        <td mat-cell *matCellDef="let element">
          <div>
            {{ element.last_config_send_time | date: 'MM/dd/yyyy' }}
          </div>

          <div>
            {{ element.last_config_send_time | date: 'HH:mm:ss' }}
          </div>
        </td>
      </ng-container>

      <!-- Last Config Ack Time  Column -->
      <ng-container matColumnDef="last_config_ack_time">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Last Config Ack Time
        </th>
        <td mat-cell *matCellDef="let element">
          <div>
            {{ element.last_config_ack_time | date: 'MM/dd/yyyy' }}
          </div>
          <div>
            {{ element.last_config_ack_time | date: 'HH:mm:ss' }}
          </div>
        </td>
      </ng-container>

      <!-- Current Config Column -->
      <ng-container matColumnDef="config_label">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Current Config
        </th>
        <td mat-cell *matCellDef="let element">
          <span
            *ngIf="
              element.config_label === 'CONFIG NOT PUBLISHED' &&
              element.last_published === null
            "
            >CONFIG NOT PUBLISHED</span
          >
          <a
            class="a-left"
            matTooltip="Click to view Current Config"
            *ngIf="element.config_label !== 'CONFIG NOT PUBLISHED'"
            (click)="viewConfigDialog(element)"
            >{{ element.config_label }}</a
          ><br />
          <mat-hint *ngIf="element.last_published !== null">
            {{ element.last_published | date: 'MM/dd/yyyy HH:mm:ss' }}
          </mat-hint>
        </td>
      </ng-container>

      <!-- Config Status Column -->
      <ng-container matColumnDef="config_status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Config Status</th>

        <td
          mat-cell
          *matCellDef="let element"
          [ngStyle]="
            element.status === 'PUBLISHED_TO_IOT_NOT_SUCCESS'
              ? { color: 'red' }
              : null
          "
        >
          <div
            *ngIf="
              element.packageConfigId !== '00000000-0000-0000-0000-000000000000'
            "
          >
            {{ element.status }}
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      [pageSizeOptions]="[5, 10, 20]"
      showFirstLastButtons
      aria-label="Select page of periodic elements"
    >
    </mat-paginator>
  </div>
</mat-card>
