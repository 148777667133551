import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IoTDeviceModel, IOTModel } from 'src/app/core/_models/IoTDeviceModel';
import { catchError } from 'rxjs/operators';
import { IoTConfigModel } from 'src/app/core/_models/IoTConfigModel';

@Injectable({
  providedIn: 'root'
})
export class IotCoreService {
  constructor(private http: HttpClient) {}

  async getSingleDeviceFromIoT(deviceID: string): Promise<Observable<any>> {
    let queryParams = new HttpParams();

    queryParams = queryParams.append('device_id', deviceID);

    return await this.http.get<IoTDeviceModel>(
      `${environment.iotcoreapi}/devices`,
      { params: queryParams }
    );
  }

  async getConfigList(deviceID: string): Promise<Observable<any>> {
    return await this.http
      .get<IoTConfigModel[]>(
        `${environment.iotcoreapi}/devices/configs/${deviceID}`
      )
      .pipe(
        catchError((err) => {
          console.log('Error caught in IoT Core service');
          console.error(err);
          //Handle the error here
          return throwError(err); //Rethrow it back to component
        })
      );
  }

  getIOTConfigDetails(serialNumber: string) {
    return this.http.get<IOTModel>(
      `${environment.triggerapi}/device-state/${serialNumber}`
    );
  }
}
