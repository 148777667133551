import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DeviceModel } from 'src/app/core/_models/DeviceModel';
import { IotProvisioningApiService } from 'src/app/core/_services/iot-provisioning-api.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { AssetModel } from 'src/app/core/_models/AssetModel';
import { GlobalVariables } from 'src/app/core/_common/GlobalVariables';
import { DatadogService } from 'src/app/core/_services/datadog.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.state';
import * as actions from '../../shared/store/divisions-assets-devices.actions';
import { DivisionsAssetsDevicesService } from '../../core/_services/divisions-assets-devices.service';
import { get } from 'idb-keyval';
import { radioActionList } from '../../core/_models/RadioActionList';
import { displayedColumnsDeviceProvisioning } from '../../core/_models/DisplayedColumns';
import { DeviceProvisioningDataSource } from '../../core/_models/DataSource';

@Component({
  selector: 'app-device-provisioning',
  templateUrl: './device-provisioning.component.html',
  styleUrls: ['./device-provisioning.component.scss']
})
export class DeviceProvisioningComponent implements OnInit, OnDestroy {
  pageLoading = false;
  tableLoading = false;
  accountCode = '';
  companyId = '';
  deviceProvisioningForm: FormGroup;
  filteredDevices: Observable<DeviceModel[]>;
  deviceList: DeviceModel[] = [];
  assets: AssetModel[] = [];
  deviceDetails = {};
  buttonLabel = 'Provision Device';
  radioActionEvent = 'ADDED';
  radioActionList = radioActionList;
  displayedColumns = displayedColumnsDeviceProvisioning;
  dataSource: DeviceProvisioningDataSource[];
  assetsSub: Subscription;
  devicesSub: Subscription;
  loaderSub: Subscription;

  constructor(
    private iotProvisioningApiService: IotProvisioningApiService,
    private router: Router,
    private datadogService: DatadogService,
    private store: Store<AppState>,
    private divisionsAssetsDevicesService: DivisionsAssetsDevicesService
  ) {}

  ngOnInit(): void {
    this.dataDogLogs();
    this.createDeviceProvisioningForm();
    this.dataValidator();
  }

  /**
   * ngOnDestroy()
   * this.assetsSub
   * this.devicesSub subscriptions are destroyed to avoid memory leaks
   */
  ngOnDestroy() {
    this.assetsSub?.unsubscribe();
    this.devicesSub?.unsubscribe();
    this.loaderSub?.unsubscribe();
  }

  /**
   * dataDogLogs()
   * Share the logs to DD
   */
  dataDogLogs() {
    this.datadogService.log(
      `${GlobalVariables.USER_EMAIL} accessed Device-Provisioning Page`,
      {
        name: 'Device-Provisioning Component',
        id: 1004,
        userName: GlobalVariables.USER_GIVEN_NAME,
        userEmail: GlobalVariables.USER_EMAIL
      },
      'info'
    );
  }

  /**
   * createDeviceProvisioningForm()
   * Create the Form
   */
  createDeviceProvisioningForm() {
    this.deviceProvisioningForm = new FormGroup({
      serialNumberFormControl: new FormControl('', [Validators.required]),
      actionType: new FormControl('ADDED')
    });
  }

  /**
   * dataValidator()
   * validate that the credentials are properly set
   * otherwise it navigates to the login page.
   */
  dataValidator() {
    this.store.select('account').subscribe((res) => {
      if (res.account?.length === 0 || res.account === undefined) {
        this.router.navigate([
          '/account/select-account',
          { previousUrl: this.router.url }
        ]);
      } else {
        this.getDevicesAndAssets();
        this.loaderSub = this.store
          .select('ui')
          .subscribe((ui) => (this.tableLoading = ui.isLoading));
      }
    });
  }

  /**
   * getDevicesAndAssets()
   * returns Devices and Assets from Cache
   */
  getDevicesAndAssets() {
    get('Assets').then((data) => {
      if (data?.length > 0) {
        this.assets = data;
      }
    });

    get('Devices').then((data) => {
      if (data?.length > 0) {
        this.deviceList = data;
        this.getFilteredDevices();
      }
      if (data?.length === 0) {
        this.loaderSub = this.store
          .select('ui')
          .subscribe((ui) => (this.tableLoading = ui.isLoading));
      }
    });
  }

  /**
   * getFilteredDevices()
   * Filters the Devices
   */
  getFilteredDevices() {
    this.filteredDevices = this.deviceProvisioningForm.controls[
      'serialNumberFormControl'
    ].valueChanges.pipe(
      startWith(''),
      map((deviceId) =>
        deviceId ? this._filterDevices(deviceId) : this.deviceList.slice()
      )
    );
    this.loaderSub = this.store
      .select('ui')
      .subscribe((ui) => (this.tableLoading = ui.isLoading));
  }

  /**
   * _filterDevices()
   * @param value
   * @private
   * return Device List Filtered
   */
  private _filterDevices(value: string): DeviceModel[] {
    const filterValue = value.toLowerCase();
    return this.deviceList?.filter((device) => {
      device.mfrSerialNum.toLowerCase().includes(filterValue);
    });
  }

  /**
   * provisionDevice()
   * Promise used for DD and depending on the code returned
   * creates a message
   */
  async provisionDevice(): Promise<void> {
    this.datadogService.log(
      `${GlobalVariables.USER_EMAIL} clicked "Provision/De-provision" button in "Device-Provisioning" Page`,
      {
        name: 'Provision/De-provision button - Device-Provisioning Component',
        id: 10042,
        userName: GlobalVariables.USER_GIVEN_NAME,
        userEmail: GlobalVariables.USER_EMAIL
      },
      'info'
    );
    const body = {
      gpssn:
        this.deviceProvisioningForm.controls['serialNumberFormControl'].value,
      action: this.deviceProvisioningForm.controls['actionType'].value,
      registryType: 'CLEARBLADE_IOT'
    };

    (await this.iotProvisioningApiService.provisionDevice(body))
      .toPromise()
      .then(
        async (response) => {
          if (this.radioActionEvent === 'ADDED') {
            this.successMessage(
              'The device is provisioned successfully!',
              'Success!'
            );
          } else {
            this.successMessage(
              'The device is de-provisioned successfully!',
              'Success!'
            );
          }
        },
        (error: Response) => {
          if (error.status === 400) {
            this.errorHandler(
              'DP-400502 - The device was already provisioned/unprovisioned',
              'Device Provision Warning'
            );
          }

          if (error.status === 422) {
            this.errorHandler(
              'DP-422501 - Un processable entity',
              'Device Provision Error'
            );
          }
        }
      );
  }

  /**
   * radioActionChange()
   * @param event
   * set an event depending on the radio action selected
   */
  radioActionChange(event) {
    if (event.value === 'ADDED') {
      this.buttonLabel = 'Provision Device';
      this.radioActionEvent = 'ADDED';
    } else if (event.value === 'REMOVED') {
      this.buttonLabel = 'De-Provision Device';
      this.radioActionEvent = 'REMOVED';
    } else {
      this.buttonLabel = 'Re-Provision Device';
      this.radioActionEvent = 'REPROVISION';
    }
  }

  /**
   * getAssetDetails()
   * @param device
   * get, map and populate the table given the device details
   */
  getAssetDetails(device) {
    if (this.assets !== undefined || true) {
      this.assets?.forEach((asset) => {
        if (asset.id === device.assetId) {
          let provisioningStatusDescription: string;
          if (device.iotRegistryInfo.state === 'NEW') {
            provisioningStatusDescription =
              'Provisioned - (Device added to IoT Core, data is not flowing)';
          } else if (device.iotRegistryInfo.state === 'PENDING') {
            provisioningStatusDescription =
              'Provisioned - (Device is connected, data is flowing)';
          } else {
            provisioningStatusDescription = 'Not Provisioned';
          }
          this.deviceDetails = {
            assetName: asset.name,
            mfrSerialNum: device.mfrSerialNum,
            device_id: device.id,
            deviceType: device.deviceType,
            firmware: device.typeInfo.firmware,
            vin_number: asset.typeInfo.oemVin,
            iot_status: provisioningStatusDescription,
            registryType: device.iotRegistryInfo.registryType
          };

          const selectedDevice = [];
          selectedDevice.push(this.deviceDetails);
          this.dataSource = selectedDevice;
          if (device.iotRegistryInfo.state !== null) {
            this.radioActionList.forEach((t) => {
              if (t.action === 'REMOVED' || t.action === 'REPROVISION') {
                t.disabled = false;
                this.buttonLabel = 'De-Provision Device';
                this.radioActionEvent = 'REMOVED';
                this.deviceProvisioningForm.controls['actionType'].setValue(
                  'REMOVED'
                );
              }
              if (t.action === 'ADDED') {
                t.disabled = true;
              }
            });
          } else {
            this.radioActionList.forEach((t) => {
              if (t.action === 'REMOVED' || t.action === 'REPROVISION') {
                t.disabled = true;
              }
              if (t.action === 'ADDED') {
                t.disabled = false;
                this.buttonLabel = 'Provision Device';
                this.radioActionEvent = 'ADDED';
                this.deviceProvisioningForm.controls['actionType'].setValue(
                  'ADDED'
                );
              }
            });
          }
        }
      });
    }
  }

  /**
   * onDeviceSelect()
   * @param device
   * get, map and populate the table given the device details
   */
  async onDeviceSelect(device: DeviceModel) {
    this.getAssetDetails(device);
  }

  /**
   * refreshData()
   * refresh the data and triggers the DD Service
   */
  refreshData() {
    this.store.dispatch(actions.cleanDivisions());
    this.store.dispatch(actions.cleanAssets());
    this.store.dispatch(actions.cleanDevices());
    this.divisionsAssetsDevicesService.getDeviceList();

    this.datadogService.log(
      `${GlobalVariables.USER_EMAIL} clicked "Refresh Data" button in "Device-Provisioning" Page`,
      {
        name: 'Refresh button - Device-Provisioning Component',
        id: 10041,
        userName: GlobalVariables.USER_GIVEN_NAME,
        userEmail: GlobalVariables.USER_EMAIL
      },
      'info'
    );
  }

  /**
   * onSearch()
   * @param keyword
   * search given a keyword
   */
  onSearch(keyword: string) {
    this.deviceList.forEach((x: DeviceModel) => {
      if (x.mfrSerialNum === keyword) {
        this.onDeviceSelect(x);
      }
    });
  }

  /**
   * errorHandler()
   * @param error
   * @param title
   * creates an error message in case it exists
   */
  errorHandler(error: string, title: string) {
    Swal.fire({
      icon: 'warning',
      title,
      text: error
    });
  }

  /**
   * successMessage()
   * @param message
   * @param title
   * creates a success message in case it exists
   */
  successMessage(message: string, title: string) {
    Swal.fire({
      icon: 'success',
      title,
      text: message
    });
  }
}
