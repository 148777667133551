import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { DivisionModel } from '../_models/DivisionModel';

@Injectable({
  providedIn: 'root'
})
export class DivisionApiService {
  constructor(private http: HttpClient) {}

  async getAllDivisions(params?: any): Promise<Observable<any>> {
    let queryParams = new HttpParams();
    if (params) {
      const keys = Object.keys(params);
      keys.forEach((t) => {
        queryParams = queryParams.append(t, params[t]);
      });
    }
    return await this.http.get<DivisionModel[]>(
      `${environment.serviceapi}/divisions`,
      { params: queryParams }
    );
  }

  getCompanyIdByAccountCode(accountCode) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('legacyAccountCode', accountCode);
    return this.http.get<DivisionModel>(`${environment.serviceapi}/divisions`, {
      params: queryParams
    });
  }
}
