import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EnvironmentSelectorModel } from '../_models/EnviromentSelectorModel';
import { ProfileModel } from '../_models/ProfileModel';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../_models/UserModel';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentValidatorService {
  actions = [];
  environment = '';
  profileJson: ProfileModel;
  private currentUserSubject: BehaviorSubject<User> = new BehaviorSubject(
    {} as User
  );
  public readonly currentUser: Observable<User> =
    this.currentUserSubject.asObservable();

  environmentSelector(): EnvironmentSelectorModel[] {
    this.environment = environment.env;
    switch (this.environment) {
      case 'development':
        return (this.actions = [
          { key: 'MOVE_TO_QA', value: 'MOVE_TO_QA' },
          { key: 'MOVE_TO_PROD', value: 'MOVE_TO_PROD' },
          { key: 'REBOOT', value: 'REBOOT' }
        ]);
      case 'qa':
        return (this.actions = [{ key: 'REBOOT', value: 'REBOOT' }]);
      case 'production':
        return (this.actions = [{ key: 'REBOOT', value: 'REBOOT' }]);
      default:
    }
  }

  setCurrentUser(currentUser): void {
    this.currentUserSubject.next(currentUser);
  }
}
