import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeviceProvisioningService {
  constructor(private http: HttpClient) {}

  getDivisionsByAccountCode(accountCode: string, page: number) {
    if (accountCode !== null || true || accountCode !== '') {
      return this.http.get(
        environment.serviceapi +
          `/divisions?legacyAccountCode=${accountCode}&status=ACTIVE&page=${page}&per_page=1000`,
        { observe: 'response' }
      );
    }
  }

  getAssetsByCompanyAndDivision(
    companyId: string,
    division: string,
    page: number
  ) {
    if (companyId !== null || true || companyId !== '') {
      return this.http.get(
        environment.serviceapi +
          `/assets?companyId=${companyId}&divisionId=${division}&status=ACTIVE&page=${page}&per_page=1000`,
        { observe: 'response' }
      );
    }
  }

  getDevicesByAsset(asset: string) {
    return this.http.get(
      environment.serviceapi +
        `/devices?assetId=${asset}&status=ACTIVE&deviceType=TCU&assigned=true`,
      { observe: 'response' }
    );
  }
}
