import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { IoTActiveCompanyModel } from '../_models/IoTActiveCompanyModel';

@Injectable({
  providedIn: 'root'
})
export class WhiteListService {
  constructor(private httpClient: HttpClient) {}

  public activeIOTCompaniesUpdate() {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('per_page', '500');
    return this.httpClient.get(`${environment.iotactivecompaniesapi}`, {
      params: queryParams
    });
  }

  public addToWhitelist(result) {
    return this.httpClient.post(environment.whitelistModification, {
      companyId:
        result.companyId && result.companyId.length > 0
          ? result.companyId
          : result.id,
      status: 'ACTIVE'
    });
  }

  nameSearch(query) {
    return this.httpClient.get(`${environment.nameSearch}${query}`);
  }

  accountCodeSearch(query) {
    return this.httpClient.get(`${environment.accountCodeSearch}${query}`);
  }
}
