import { Injectable } from '@angular/core';
import {
  ZonarUITableModel,
  ZonarUITableCellType as cellType
} from '@zonar-ui/table';

@Injectable({
  providedIn: 'root'
})
export class ColumnDefinitionsService {
  private readonly _columns: ZonarUITableModel[];
  private readonly _deviceConfigMappingColumns: ZonarUITableModel[];

  constructor() {
    this._columns = [
      {
        columnDef: 'companyName',
        header: 'Company Name',
        cellType: cellType.Text,
        sortable: true,
        cell: (row: any) => row.companyName,
        columnStyle: { flex: '1' }
      }
    ];

    this._deviceConfigMappingColumns = [
      {
        columnDef: 'assetName',
        header: 'Asset Name',
        cellType: cellType.Text,
        sortable: true,
        cell: (row: any) => row.asset_name,
        columnStyle: {
          flex: '1'
        }
      },
      {
        columnDef: 'serialNumber',
        type: cellType.Portal,
        header: 'Serial Number',
        sortable: true
      },
      {
        columnDef: 'vinNumber',
        header: 'Vin Number',
        cellType: cellType.Text,
        sortable: true,
        cell: (row: any) => row.vin_number,
        columnStyle: {
          flex: '1'
        }
      },
      {
        columnDef: 'firmwareVersion',
        type: cellType.Portal,
        header: 'Firmware Version',
        sortable: true
      },
      {
        columnDef: 'lastHeartbeatTime',
        header: 'Last Heartbeat Time',
        cellType: cellType.Text,
        sortable: true,
        cell: (row: any) => row.last_heartbeat_time,
        columnStyle: {
          flex: '1'
        }
      },
      {
        columnDef: 'lastEventTime',
        header: 'Last Event Time',
        cellType: cellType.Text,
        sortable: true,
        cell: (row: any) => row.last_event_time,
        columnStyle: {
          flex: '1'
        }
      },
      {
        columnDef: 'lastConfigSendTime',
        header: 'Last Config Send Time',
        cellType: cellType.Text,
        sortable: true,
        cell: (row: any) => row.last_config_send_time,
        columnStyle: {
          flex: '1'
        }
      },
      {
        columnDef: 'lastConfigAckTime',
        header: 'Last Config Ack Time',
        cellType: cellType.Text,
        sortable: true,
        cell: (row: any) => row.last_config_ack_time,
        columnStyle: {
          flex: '1'
        }
      },
      {
        columnDef: 'currentConfig',
        type: cellType.Portal,
        header: 'Current Config',
        sortable: true
      },
      {
        columnDef: 'configStatus',
        type: cellType.Portal,
        header: 'Config Status',
        sortable: true
      }
    ];
  }

  public getColumnDefinitions() {
    return this._columns;
  }

  public getDeviceConfigMappingColumns() {
    return this._deviceConfigMappingColumns;
  }
}
