<div class="asset-mapping-trigger-edit-header">Signal Trigger Mapping Edit</div>

<div class="asset-mapping-trigger-edit-options" [formGroup]="triggerEditGroup">
  <div class="option">
    <div class="option-header">Trigger Status</div>

    <div class="option-value">
      <mat-form-field>
        <mat-label>Select Status</mat-label>
        <mat-select formControlName="status">
          <mat-option *ngFor="let status of statusOptions" [value]="status">
            {{ status }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>

<div class="asset-mapping-trigger-edit-buttons">
  <div>
    <button (click)="onSubmit()" mat-button>Update Trigger</button>
  </div>

  <div>
    <button (click)="onClose()" mat-button>Close</button>
  </div>
</div>
