<div id="unauthorized-container">
  <h1>Access forbidden</h1>

  <h3>
    The user profile being used is missing the following role:
    <strong>{{ authGuardService.missingRole$ | async }}</strong
    ><br />
    Please contact your system administrator to request access
  </h3>
</div>
