import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '@auth0/auth0-angular';
import { cleanAccount } from './pages/select-account/store/select-account.actions';
import { Store } from '@ngrx/store';
import { AppState } from './store/app.state';
import { DataSourceMappedService } from './core/_services/data-source-mapped.service';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {
  constructor(
    public auth: AuthService,
    private store: Store<AppState>,
    private dataSourceMappedService: DataSourceMappedService
  ) {}
  canActivate(): Observable<any> {
    return this.auth.user$.pipe(
      tap((state: any) => {
        if (state === null || state === undefined) {
          this.auth.loginWithRedirect();
          this.store.dispatch(cleanAccount());
          this.dataSourceMappedService.cleanContainersAndCache();
          this.dataSourceMappedService.cleanCompanyAccount();
          this.dataSourceMappedService.cleanPackageConfigListCache();
        }
      })
    );
  }
}
