import { Component, Inject, OnDestroy } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef
} from '@angular/material/dialog';
import { DeviceConfigMapping } from 'src/app/core/_models/DeviceConfigMapping';
import { DeviceConfigService } from 'src/app/core/_services/device-config.service';
import { GlobalVariables } from 'src/app/core/_common/GlobalVariables';
import { DatadogService } from 'src/app/core/_services/datadog.service';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-publish-config-dialog',
  templateUrl: './publish-config-dialog.component.html',
  styleUrls: ['./publish-config-dialog.component.scss']
})
export class PublishConfigDialogComponent implements OnDestroy {
  publishLoading: boolean = false;
  dialogData: DeviceConfigMapping[] = [];
  message = [];
  title = 'Config Publish';
  text = '';
  subtitle = 'Publishing device config to IoT Core';
  columns: string[] = [
    'asset_name',
    'serial_number',
    'id',
    'config_label',
    'selected_config_label',
    'published'
  ];
  action: string = 'Publish';
  publishConfigSubscription: Subscription;

  constructor(
    public dialogRef: MatDialogRef<PublishConfigDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DeviceConfigMapping[],
    private deviceConfigService: DeviceConfigService,
    public dialog: MatDialog,
    private datadogService: DatadogService
  ) {
    this.dialogData = Object.values(data);
    this.initializeConfig();
  }

  initializeConfig() {
    for (let prop in this.dialogData) {
      this.dialogData[prop] = Object.assign({}, this.dialogData[prop], {
        published: 'To be Published'
      });
    }
  }

  ngOnDestroy() {
    this.publishConfigSubscription?.unsubscribe();
  }

  actionSelected(event) {
    if (event.event === 'Publish') {
      this.publishConfig();
    }
  }

  publishConfig() {
    this.datadogService.log(
      `${GlobalVariables.USER_EMAIL} clicked "Publish Config" button in "Publish-Config-Dialog" Page`,
      {
        name: 'Publish Config Button - Publish-Config Component',
        id: 10033,
        userName: GlobalVariables.USER_GIVEN_NAME,
        userEmail: GlobalVariables.USER_EMAIL
      },
      'info'
    );

    this.dialogData.forEach((item) => {
      this.publishLoading = true;
      const configData = {
        trigger_id: item.trigger_id[0],
        config_data: item.selected_config,
        packageConfigId: item.packageConfigId,
        deviceId: item.id,
        assetId: item.asset_id,
        serialNumber: item.serial_number,
        companyId: localStorage.getItem('companyCode'),
        accountCode: item.account_code,
        status: 'READY_TO_PUBLISH'
      };

      this.datadogService.log(
        `${GlobalVariables.USER_EMAIL} published Serial#: "${item.serial_number}" `,
        {
          name: 'Publish-Config Component',
          id: 10033,
          userName: GlobalVariables.USER_GIVEN_NAME,
          userEmail: GlobalVariables.USER_EMAIL
        },
        'info'
      );
      this.message.push(configData);
    });

    const data = { message: this.message };

    this.publishConfigSubscription = this.deviceConfigService
      .publicConfig(data)
      .subscribe(
        (response: any) => {
          let res = JSON.parse(response);
          if (res['publishedMessages'] === this.dialogData.length) {
            if (res['publishedMessages'] === 1) {
              let oneConfig = res['publishedMessages'];
              this.text = `${oneConfig} configuration has been published successfully.`;
            } else {
              let multipleConfig = res['publishedMessages'];
              this.text = `${multipleConfig} configurations have been published successfully.`;
            }
          }
        },
        (error) => {
          this.messageHandler('Error', 'Config publish failed.', 'error');
        },
        () => {
          this.publishLoading = false;
          this.dialogRef.close({ event: 'refresh' });
          this.messageHandler('Success', this.text, 'success');
        }
      );
  }

  messageHandler(title: string, text: string, icon: SweetAlertIcon) {
    Swal.fire({
      icon,
      title,
      text
    });
  }
}
