import { environment } from '../../../environments/environment';

export const CONFIG_URL = `${environment.iotcoreapi}/configs`;
export const PACKAGE_CONFIG_URL = `${environment.packageapi}`;
export const PUBLISH_CONFIG_URL = '/account/publish-config';
export const ASSET_API_URL = `${environment.serviceapi}/assets`;
export const DEVICE_API_URL = `${environment.serviceapi}/devices`;
export const VALIDATION_API_URL = `${environment.validationapi}`;

export const QUERY_PARAM_PER_PAGE = 'per_page';
export const QUERY_PARAM_PER_PAGE_VALUE = '500';

export const CONFIG_DIALOG_HEIGHT = '60%';
export const CONFIG_DIALOG_WIDTH = '50%';

export const CREATE_DATASET_DIALOG_HEIGHT = '73%';
export const CREATE_DATASET_DIALOG_WIDTH = '80%';

// TODO: Possibly derive these from object attributes
export const DISPLAYED_COLUMNS: string[] = [
  'name',
  'type',
  'status',
  'created',
  'modified',
  'actions'
];
