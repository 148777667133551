<mat-card-title-group>
  <mat-card-title>Create Trigger</mat-card-title>
  <mat-card-subtitle>Create Trigger Form</mat-card-subtitle>
  <button mat-icon-button aria-label="close dialog" mat-dialog-close>
    <img src="assets/images/close-icon.png" width="30" height="30" />
  </button>
</mat-card-title-group>

<hr />
<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
<mat-dialog-content>
  <form class="container" [formGroup]="triggerForm">
    <div style="display: table; width: 100%">
      <div style="display: table-row">
        <div style="display: table-cell; vertical-align: top">
          <div>
            <mat-form-field appearance="standard">
              <mat-label>Trigger Name</mat-label>
              <input
                matInput
                placeholder="trigger name"
                formControlName="triggerNameFormControl"
                name="triggerNameFormControl"
              />
              <mat-error
                *ngIf="
                  triggerForm.get('triggerNameFormControl').hasError('required')
                "
                >Trigger Name is required</mat-error
              >
            </mat-form-field>
          </div>
          <div>
            <mat-form-field appearance="standard">
              <mat-label>Description</mat-label>
              <textarea
                matInput
                formControlName="descriptionFormControl"
                name="descriptionFormControl"
              ></textarea>
            </mat-form-field>
          </div>

          <div>
            <mat-form-field appearance="standard">
              <mat-label>Type</mat-label>
              <mat-select
                formControlName="typeFormControl"
                name="typeFormControl"
              >
                <mat-option *ngFor="let type of typeList" value="{{ type }}">{{
                  type
                }}</mat-option>
              </mat-select>
              <mat-error
                *ngIf="triggerForm.get('typeFormControl').hasError('required')"
                >Type is required</mat-error
              >
            </mat-form-field>
          </div>

          <div>
            <mat-form-field appearance="standard">
              <mat-label>Signal Type</mat-label>
              <mat-select
                formControlName="signalTypeFormControl"
                name="signalTypeFormControl"
                (selectionChange)="signalTypeChangeAction($event)"
              >
                <mat-option
                  *ngFor="let signalType of signalTypeList"
                  value="{{ signalType.name }}"
                  [disabled]="!signalType.status"
                  >{{ signalType.name }}</mat-option
                >
              </mat-select>
              <mat-error
                *ngIf="
                  triggerForm.get('signalTypeFormControl').hasError('required')
                "
                >Signal Type is required</mat-error
              >
            </mat-form-field>
          </div>

          <h2 *ngIf="displayField('samplingConfig.sampleTime')">
            <u>Sampling Config</u>
          </h2>

          <div>
            <mat-form-field
              appearance="standard"
              *ngIf="displayField('samplingConfig.sampleTime')"
            >
              <mat-label>Sample Time</mat-label>
              <input
                matInput
                placeholder="300"
                formControlName="sampleTimeFormControl"
                name="sampleTimeFormControl"
              />
              <mat-error
                *ngIf="
                  triggerForm.get('sampleTimeFormControl').hasError('required')
                "
                >Sample Time is required</mat-error
              >
            </mat-form-field>

            <mat-form-field appearance="standard" *ngIf="displayField('spn')">
              <mat-label>SPN</mat-label>
              <input
                matInput
                placeholder="300"
                formControlName="spnFormControl"
                name="spnFormControl"
              />
            </mat-form-field>
          </div>

          <div>
            <mat-form-field
              appearance="standard"
              *ngIf="displayField('samplingConfig.rearmTime')"
            >
              <mat-label>Rearm Time</mat-label>
              <input
                matInput
                placeholder="300"
                formControlName="rearmTimeFormControl"
                name="rearmTimeFormControl"
              />
              <mat-error
                *ngIf="
                  triggerForm.get('rearmTimeFormControl').hasError('required')
                "
                >Rearm Time is required</mat-error
              >
            </mat-form-field>
          </div>
          <div>
            <mat-form-field
              appearance="standard"
              *ngIf="displayField('eventType')"
            >
              <mat-label>Event Type</mat-label>
              <mat-select
                formControlName="eventTypeFormControl"
                name="eventTypeFormControl"
              >
                <mat-option
                  *ngFor="let eventType of activeEventTypeList"
                  value="{{ eventType }}"
                  >{{ eventType }}</mat-option
                >
              </mat-select>
            </mat-form-field>

            <mat-form-field
              appearance="standard"
              *ngIf="displayField('activeStateType')"
            >
              <mat-label>Active State Type</mat-label>
              <mat-select
                formControlName="activeStateTypeFormControl"
                name="activeStateTypeFormControl"
              >
                <mat-option
                  *ngFor="let stateType of activeStateType"
                  value="{{ stateType }}"
                  >{{ stateType }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>

          <div>
            <mat-form-field
              appearance="standard"
              *ngIf="displayField('segmentType')"
            >
              <mat-label>Segment Type</mat-label>
              <mat-select
                formControlName="segmentTypeFormControl"
                name="segmentTypeFormControl"
              >
                <mat-option
                  *ngFor="let segmentType of activeSegmentTypeList"
                  value="{{ segmentType }}"
                  >{{ segmentType }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </div>

          <div>
            <div *ngIf="displayField('operationConfig.operator')">
              <h2><u>Operation Config</u></h2>
              <mat-divider></mat-divider>
            </div>

            <mat-form-field
              appearance="standard"
              *ngIf="displayField('operationConfig.operator')"
            >
              <mat-label>Operator</mat-label>
              <mat-select
                formControlName="operatorFormControl"
                name="operatorFormControl"
              >
                <mat-option
                  *ngFor="let operator of operators"
                  value="{{ operator }}"
                  >{{ operatorLabel(operator) }}</mat-option
                >
              </mat-select>
              <mat-error
                *ngIf="
                  triggerForm.get('operatorFormControl').hasError('required')
                "
                >Operator is required</mat-error
              >
            </mat-form-field>
          </div>

          <div>
            <mat-form-field
              appearance="standard"
              *ngIf="displayField('operationConfig.comparedValue')"
            >
              <mat-label>Compared Value</mat-label>
              <input
                matInput
                placeholder="0"
                formControlName="comparedValueFormControl"
                name="comparedValueFormControl"
              />
              <mat-error
                *ngIf="
                  triggerForm
                    .get('comparedValueFormControl')
                    .hasError('required')
                "
                >Compared Value is required</mat-error
              >
            </mat-form-field>
          </div>

          <div>
            <mat-form-field appearance="standard" *ngIf="displayField('pgn')">
              <mat-label>PGN</mat-label>
              <input
                matInput
                placeholder="300"
                formControlName="pgnFormControl"
                name="pgnFormControl"
              />
              <mat-error
                *ngIf="triggerForm.get('pgnFormControl').hasError('required')"
                >PGN is required</mat-error
              >
            </mat-form-field>
          </div>

          <div>
            <mat-form-field
              appearance="standard"
              *ngIf="displayField('channel')"
            >
              <mat-label>Channel</mat-label>
              <mat-select
                formControlName="channelFormControl"
                name="channelFormControl"
              >
                <mat-option
                  *ngFor="let channel of channelTypeList"
                  value="{{ channel }}"
                  >{{ channel }}</mat-option
                >
              </mat-select>
              <mat-error
                *ngIf="
                  triggerForm.get('channelFormControl').hasError('required')
                "
                >Channel is required</mat-error
              >
            </mat-form-field>
          </div>

          <div>
            <mat-form-field
              appearance="standard"
              *ngIf="displayField('length')"
            >
              <mat-label>Bit length</mat-label>
              <input
                matInput
                placeholder="300"
                formControlName="bitLengthFormControl"
                name="bitLengthFormControl"
              />
              <mat-error
                *ngIf="
                  triggerForm.get('bitLengthFormControl').hasError('required')
                "
                >Bit length is required</mat-error
              >
            </mat-form-field>
          </div>
        </div>
        <div style="display: table-cell; vertical-align: top">
          <div>
            <mat-form-field
              appearance="standard"
              *ngIf="displayField('source')"
            >
              <mat-label>Source</mat-label>
              <input
                matInput
                placeholder="0"
                formControlName="sourceFormControl"
                name="sourceFormControl"
              />
              <mat-error
                *ngIf="
                  triggerForm.get('sourceFormControl').hasError('required')
                "
                >Source is required</mat-error
              >
            </mat-form-field>
          </div>

          <div>
            <mat-form-field
              appearance="standard"
              *ngIf="displayField('offset')"
            >
              <mat-label>Offset</mat-label>
              <input
                matInput
                placeholder="0"
                formControlName="offsetFormControl"
                name="offsetFormControl"
              />
              <mat-error
                *ngIf="
                  triggerForm.get('offsetFormControl').hasError('required')
                "
                >Offset is required</mat-error
              >
            </mat-form-field>
          </div>

          <div style="display: table-cell; align-items: center">
            <div>
              <mat-form-field appearance="standard">
                <mat-label>Dataset</mat-label>
                <mat-select
                  formControlName="datasetFormControl"
                  name="datasetFormControl"
                >
                  <mat-option
                    *ngFor="let dataset of datasetList"
                    [value]="dataset.id"
                    (click)="onDatasetSelect(dataset)"
                    >{{ dataset.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <mat-error
                *ngIf="
                  triggerForm.controls['datasetFormControl'].hasError(
                    'required'
                  ) && triggerForm.controls['datasetFormControl'].pristine
                "
              >
                Dataset is required</mat-error
              >
            </div>

            <div>
              <mat-form-field appearance="outline">
                <mat-label>Dataset Config</mat-label>
                <textarea
                  matInput
                  rows="20"
                  cols="150"
                  style="font-size: medium"
                  [disabled]="true"
                  class="dataset-text"
                >
              {{ datasetConfigView }}
              </textarea
                >
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div>
    <button
      mat-stroked-button
      (click)="onSubmit()"
      [disabled]="disableCreate()"
    >
      Create Trigger
    </button>
    <button id="refresh-button" mat-stroked-button mat-dialog-close>
      Cancel
    </button>
  </div>
</mat-dialog-content>
