import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProfileComponent } from './pages/profile/profile.component';
import { ErrorComponent } from './pages/error/error.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { AssetMappingComponent } from './pages/asset-mapping/asset-mapping.component';
import { SelectAccountComponent } from './pages/select-account/select-account.component';
import { PublishconfigComponent } from './pages/publishconfig/publishconfig.component';
import { SelectAccountResolverResolver } from './core/_resolvers/select-account-resolver.resolver';
import { DeviceProvisioningComponent } from './pages/device-provisioning/device-provisioning.component';
import { PackageConfigComponent } from './pages/package-config/package-config.component';
import { AdminComponent } from './pages/admin/admin.component';
import { PackageConfigResolver } from './core/_resolvers/package-config.resolver';
import { DeviceSettingsComponent } from './pages/device-settings/device-settings.component';
import { TriggerComponent } from './pages/trigger/trigger.component';
import { TriggerResolver } from 'src/app/core/_resolvers/trigger.resolver';
import { DatasetComponent } from './pages/dataset/dataset.component';
import { DatasetResolver } from 'src/app/core/_resolvers/dataset.resolver';
import { HomeComponent } from './pages/home/home.component';
import { SignalComponent } from './pages/signal/signal.component';
import { AuthGuardService, Roles } from './auth-guard.service';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { WhitelistingComponent } from './pages/whitelisting/whitelisting.component';
import { AuthenticationGuard } from './authentication.guard';
import { DeviceTrackerComponent } from './pages/device-tracker/device-tracker.component';
import { SignalTriggerMappingComponent } from './signal-trigger-mapping/signal-trigger-mapping.component';

const routes: Routes = [
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard, AuthenticationGuard]
  },
  {
    path: 'account/:path',
    component: SelectAccountComponent,
    canActivate: [AuthGuard, AuthenticationGuard]
  },
  {
    path: 'device-provisioning',
    component: DeviceProvisioningComponent,
    canActivate: [AuthGuardService, AuthenticationGuard],
    data: {
      role: Roles.NONE
    }
  },
  {
    path: 'package-config',
    component: PackageConfigComponent,
    canActivate: [AuthGuardService, AuthenticationGuard],
    data: {
      role: Roles.AdminFullAccess
    }
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard, AuthenticationGuard]
  },
  {
    path: 'publish-config',
    component: PublishconfigComponent,
    canActivate: [AuthGuardService, AuthenticationGuard],
    data: {
      role: Roles.NONE
    }
  },
  {
    path: 'device-settings',
    canActivate: [AuthGuardService, AuthenticationGuard],
    data: {
      role: Roles.AdminFullAccess
    },
    component: DeviceSettingsComponent
  },
  {
    path: 'config-mapping',
    component: AssetMappingComponent,
    canActivate: [AuthGuardService, AuthenticationGuard],
    data: {
      role: Roles.NONE
    }
  },
  {
    path: 'trigger',
    component: TriggerComponent,
    canActivate: [AuthGuardService, AuthenticationGuard],
    data: {
      role: Roles.AdminFullAccess
    },
    resolve: { allTriggers: TriggerResolver }
  },
  {
    path: 'dataset',
    component: DatasetComponent,
    canActivate: [AuthGuardService, AuthenticationGuard],
    data: {
      role: Roles.AdminFullAccess
    },
    resolve: { allDataset: DatasetResolver }
  },

  {
    path: 'whitelist',
    component: WhitelistingComponent,
    canActivate: [AuthGuardService],
    data: {
      role: Roles.NONE
    },
    resolve: { allDataset: DatasetResolver }
  },

  {
    path: 'signal',
    component: SignalComponent
  },
  {
    path: 'tracker',
    component: DeviceTrackerComponent
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthenticationGuard]
  },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'error',
    component: ErrorComponent
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent
  },
  {
    path: 'signal-trigger-mappings',
    component: SignalTriggerMappingComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
