import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import {
  DEVICE_DIALOG_HEIGHT,
  DEVICE_DIALOG_WIDTH
} from '../../pages/publishconfig/constants';
import { JsonDialogComponent } from '../json-dialog/json-dialog.component';

@Component({
  selector: 'zonar-table-dialog',
  templateUrl: './table-dialog.component.html',
  styleUrls: ['./table-dialog.component.scss']
})
export class TableDialogComponent implements OnInit {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() loader: boolean;
  @Input() columns: any;
  @Input() source: any;
  @Input() action: string;
  @Input() publishedLoader: boolean;
  @Input() validatedLoader: boolean;
  @Output() actionSelected = new EventEmitter<any>();
  public pageSize = 5;
  public currentPage = 0;
  public totalSize = 0;
  public array: any;
  public dataSource: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public dialogRef: MatDialogRef<TableDialogComponent>,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.populateTable();
  }

  private populateTable() {
    this.dataSource = new MatTableDataSource<Element>(this.source);
    this.dataSource.paginator = this.paginator;
    this.array = this.source;
    this.totalSize = this.array.length;
    this.iterator();
  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.iterator();
  }

  private iterator() {
    const end = (this.currentPage + 1) * this.pageSize;
    const start = this.currentPage * this.pageSize;
    this.dataSource = this.array.slice(start, end);
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onAction() {
    this.actionSelected.emit({ event: this.action });
  }

  openJSONDialog(configLabel: any, jsonConfig: any) {
    const configData = {
      config_label: configLabel,
      config_json: jsonConfig
    };
    this.dialog.open(JsonDialogComponent, {
      data: configData,
      height: DEVICE_DIALOG_HEIGHT,
      width: DEVICE_DIALOG_WIDTH
    });
  }
}
