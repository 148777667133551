import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VALIDATION_API_URL } from './constants';
import { environment } from 'src/environments/environment';
import { ValidationStatusModel } from '../_models/ValidationStatusModel';

@Injectable({
  providedIn: 'root'
})
export class DeviceConfigService {
  constructor(private httpClient: HttpClient) {}

  publicConfig(configData: any) {
    return this.httpClient.post(`${environment.publishapi}`, configData, {
      responseType: 'text'
    });
  }

  getConfigStatus(device_id: string) {
    var body = { message: { device_id: device_id } };
    return this.httpClient.post<ValidationStatusModel>(
      VALIDATION_API_URL,
      body
    );
  }
}
