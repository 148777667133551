import { environment } from '../../../environments/environment';

export const QUERY_PARAM_PER_PAGE = 'per_page';
export const QUERY_PARAM_PER_PAGE_VALUE = '500';

export const ACTION_DIALOG_HEIGHT = '85%';
export const ACTION_DIALOG_WIDTH = '70%';

export const SIGNAL_TYPE_LIST = ['STANDARD', 'PROPRIETARY'];

export const SPN_TYPE_LIST = ['FIXED', 'VARIABLE'];

export const CREATE_DATASET_DIALOG_HEIGHT = '78%';
export const CREATE_DATASET_DIALOG_WIDTH = '80%';
