<mat-card>
  <mat-card-title>{{ title }}</mat-card-title>
  <mat-divider></mat-divider>
  <mat-card-content>
    <div class="container">
      <ngx-skeleton-loader
        *ngIf="isDeviceLoading && title === 'Device Details'"
        count="1"
        appearance="line"
        animation="pulse"
      >
      </ngx-skeleton-loader>
      <div class="row" *ngIf="!isDeviceLoading && title === 'Device Details'">
        <div class="col-md-3">
          <span
            ><b>Device ID:</b
            ><span class="m-2">{{ data?.deviceId }}</span></span
          >
        </div>
        <div class="col-md-3">
          <span
            ><b>Asset Id:</b> <span class="m-2">{{ data?.assetId }}</span></span
          >
        </div>
        <div class="col-md-3">
          <span
            ><b>Package Config Id:</b>
            <span class="m-2">{{ data?.packageConfigId }}</span></span
          >
        </div>
        <div class="col-md-3">
          <span
            ><b>Company Id:</b>
            <span class="m-2">{{ data?.companyId }}</span></span
          >
        </div>
      </div>
      <div
        class="row mt-2"
        *ngIf="!isDeviceLoading && title === 'Device Details'"
      >
        <div class="col-md-3">
          <span
            ><b>Account Code:</b>
            <span class="m-2">{{ data?.accountCode }}</span></span
          >
        </div>
        <div class="col-md-3">
          <span
            ><b>Status:</b> <span class="m-2">{{ data?.status }}</span></span
          >
        </div>
        <div class="col-md-3">
          <span
            ><b>Created:</b> <span class="m-2">{{ data?.created }}</span></span
          >
        </div>
        <div class="col-md-3">
          <span
            ><b>Modified:</b>
            <span class="m-2">{{ data?.modified }}</span></span
          >
        </div>
      </div>
    </div>

    <ngx-skeleton-loader
      *ngIf="isAssetLoading && title === 'Asset Details'"
      count="1"
      appearance="line"
      animation="pulse"
    >
    </ngx-skeleton-loader>
    <div class="container">
      <div class="row" *ngIf="!isAssetLoading && title === 'Asset Details'">
        <div class="col-md-3">
          <span
            ><b>Asset ID:</b><span class="m-2">{{ data?.id }}</span></span
          >
        </div>
        <div class="col-md-3">
          <span
            ><b>Asset Type:</b>
            <span class="m-2">{{ data?.assetType }}</span></span
          >
        </div>
        <div class="col-md-3">
          <span
            ><b>Asset Status:</b
            ><span class="m-2">{{ data?.status }}</span></span
          >
        </div>
        <div class="col-md-3">
          <span
            ><b>Asset Name:</b><span class="m-2">{{ data?.name }}</span></span
          >
        </div>
      </div>
    </div>

    <ngx-skeleton-loader
      *ngIf="isCompanyLoading && title === 'Company Details'"
      count="1"
      appearance="line"
      animation="pulse"
    >
    </ngx-skeleton-loader>
    <div class="container">
      <div class="row" *ngIf="!isCompanyLoading && title === 'Company Details'">
        <div class="col-md-3">
          <span
            ><b>Company ID:</b><span class="m-2">{{ data?.id }}</span></span
          >
        </div>
        <div class="col-md-3">
          <span
            ><b>Company Name:</b>
            <span class="m-2">{{ data?.name }}</span></span
          >
        </div>
        <div class="col-md-3">
          <span
            ><b>Company Short Name:</b
            ><span class="m-2">{{ data?.shortName }}</span></span
          >
        </div>
        <div class="col-md-3">
          <span
            ><b>Company Status:</b
            ><span class="m-2">{{ data?.status }}</span></span
          >
        </div>
      </div>
    </div>

    <ngx-skeleton-loader
      *ngIf="isIOTLoading && title === 'IoT Config Details'"
      count="1"
      appearance="line"
      animation="pulse"
    >
    </ngx-skeleton-loader>
    <div class="container">
      <div class="row" *ngIf="!isIOTLoading && title === 'IoT Config Details'">
        <div class="col-md-3">
          <span
            ><b>Registry Type:</b
            ><span class="m-2">{{ data?.registryType }}</span></span
          >
        </div>
        <div class="col-md-3">
          <span
            ><b>State:</b> <span class="m-2">{{ data?.state }}</span></span
          >
        </div>
        <div class="col-md-2">
          <span
            ><b>Project Id:</b
            ><span class="m-2">{{ data?.projectId }}</span></span
          >
        </div>
        <div class="col-md-2">
          <span
            ><b>Registry Id:</b
            ><span class="m-2">{{ data?.registryId }}</span></span
          >
        </div>
        <div class="col-md-2">
          <span
            ><b>Region:</b><span class="m-2">{{ data?.region }}</span></span
          >
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
