<form [formGroup]="empForm" (ngSubmit)="onSchedule()">
  <mat-card *ngIf="!pageLoading">
    <div id="table-title">Device Settings</div>

    <div id="info" class="submenu-margin">
      <div class="info-block">
        <b>Customer Name: </b>&nbsp;{{ companyName }}
      </div>
      <div class="info-block"><b>Account Code: </b>&nbsp;{{ accountCode }}</div>
      <div id="publish-button">
        <button
          type="submit"
          mat-raised-button
          color="primary"
          matTooltip="Schedule Device"
          [disabled]="!showButton"
        >
          Schedule
        </button>
      </div>
    </div>
    <mat-card id="selections">
      <mat-card-content>
        <div id="top-row">
          <div class="top-row-item">
            <input
              id="search-input"
              matInput
              (keyup)="onSearch($event)"
              placeholder="Ex. 18000161"
            />
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <div class="mat-elevation-z8 scrollmenu">
      <mat-progress-bar
        mode="indeterminate"
        *ngIf="tableLoading"
      ></mat-progress-bar>
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="checked">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox
              [checked]="element.checked"
              (change)="updateChecked(element.serial_number, element.checked)"
            >
            </mat-checkbox>
          </td>
        </ng-container>

        <!-- Account Code -->
        <ng-container matColumnDef="account_code">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Account Code
          </th>
          <td mat-cell *matCellDef="let element">
            <div *ngIf="element.account_code !== null">
              {{ element.account_code }}
            </div>
          </td>
        </ng-container>

        <!-- Asset Name -->
        <ng-container matColumnDef="asset_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Asset Name</th>
          <td mat-cell *matCellDef="let element">
            <div *ngIf="element.asset_name">
              {{ element.asset_name }}
            </div>
          </td>
        </ng-container>

        <!-- Serial Number -->
        <ng-container matColumnDef="serial_number">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Serial Number
          </th>
          <td mat-cell *matCellDef="let element">
            <div *ngIf="element.serial_number">
              {{ element.serial_number }}
            </div>
          </td>
        </ng-container>
        <!-- Actions -->
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let element; let i = index">
            <mat-form-field
              class="a-left"
              id="select-form-field"
              appearance="fill"
            >
              <mat-label>Select Action</mat-label>
              <mat-select
                #selectElement
                [value]="element.action_selected"
                (click)="onRestartValues(element.serial_number)"
                (selectionChange)="selected(element, selectElement.value)"
              >
                <mat-option
                  *ngFor="let action of element.action_list"
                  [value]="action.key"
                >
                  {{ action.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </ng-container>

        <!-- Last Reboot -->
        <ng-container matColumnDef="last_reboot">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Last Reboot (UTC)
          </th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.last_reboot; then showData">
            </ng-container>
            <ng-template #showData>
              {{ element.last_reboot | date: 'MM/dd/yyyy HH:mm:ss' }}
            </ng-template>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          class="single-row"
          mat-row
          *matRowDef="let row; columns: displayedColumns"
        ></tr>
      </table>
      <mat-paginator
        [pageSizeOptions]="[5, 10, 20]"
        showFirstLastButtons
        aria-label="Select page of periodic elements"
      >
      </mat-paginator>
    </div>
  </mat-card>
</form>
