<mat-card>
  <div id="table-title">Master Signals</div>

  <div id="refresh-container">
    <button
      mat-raised-button
      id="add-pgn-button"
      matTooltip="Add New PGN"
      (click)="actionSigal('Create')"
    >
      <mat-icon>add</mat-icon>
      Add New PGN
    </button>

    <button
      mat-raised-button
      id="add-dataset-button"
      matTooltip="Add New Dataset"
      (click)="createDataset()"
      [disabled]="addDatasetButtonStatus"
    >
      <mat-icon>add</mat-icon>
      Add New Dataset
    </button>
    <button
      mat-raised-button
      id="refresh-button"
      matTooltip=" Refresh Signal List"
      (click)="refreshDataset()"
    >
      <mat-icon>refresh</mat-icon>
    </button>

    <button
      class="export-button"
      mat-raised-button
      (click)="exportCSV(dataSource)"
    >
      Export CSV
    </button>
  </div>

  <mat-card id="selections">
    <mat-card-content>
      <div id="top-row">
        <div class="top-row-item">
          <input
            id="search-input"
            matInput
            [(ngModel)]="filterInput"
            (keyup)="applyFilter($event)"
            placeholder="Ex. 1024"
            #input
          />
        </div>

        <div class="top-row-item">
          <mat-form-field>
            <mat-label>Select Package Config</mat-label>
            <mat-select
              (selectionChange)="getConfigPgns($event)"
              [(ngModel)]="matSelectedConfig"
            >
              <mat-option
                *ngFor="let config of packageConnfigList"
                [value]="config.id"
              >
                {{ config.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="top-row-item">
          <button
            mat-raised-button
            id="refresh-button"
            matTooltip="Reset the datasource"
            (click)="resetPgnDataSource()"
          >
            Reset All
          </button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-progress-bar
    mode="indeterminate"
    *ngIf="tableLoading"
  ></mat-progress-bar>
  <table
    mat-table
    [dataSource]="dataSource"
    multiTemplateDataRows
    class="mat-elevation-z8"
    matSort
  >
    <ng-container matColumnDef="checked">
      <th mat-header-cell *matHeaderCellDef>
        <!--  <mat-checkbox (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                </mat-checkbox> -->
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox
          [(ngModel)]="element.checked"
          (ngModelChange)="updateChecked($event, element)"
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <!--  <ng-container
      matColumnDef="{{ column }}"
      *ngFor="let column of columnsToDisplay"
    >
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ column }}</th>
      <td mat-cell *matCellDef="let element">{{ element[column] }}</td>
    </ng-container> -->
    <ng-container matColumnDef="pgn">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>PGN</th>
      <td mat-cell *matCellDef="let element">{{ element.pgn }}</td>
    </ng-container>
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
      <td mat-cell *matCellDef="let element">{{ element.type }}</td>
    </ng-container>
    <ng-container matColumnDef="label">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Label</th>
      <td mat-cell *matCellDef="let element">{{ element.label }}</td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
      <td mat-cell *matCellDef="let element">
        <p readMore="1" [readMoreOptions]="readMoreOption" class="readmore">
          {{ element.description }}
        </p>
      </td>
    </ng-container>
    <ng-container matColumnDef="expand">
      <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
      <td mat-cell *matCellDef="let element">
        <button
          style="justify-content: right"
          mat-icon-button
          aria-label="expand row"
          (click)="
            expandedElement = expandedElement === element ? null : element;
            $event.stopPropagation()
          "
        >
          <mat-icon *ngIf="expandedElement !== element"
            >keyboard_arrow_down</mat-icon
          >
          <mat-icon *ngIf="expandedElement === element"
            >keyboard_arrow_up</mat-icon
          >
        </button>
      </td>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td
        mat-cell
        *matCellDef="let element"
        [attr.colspan]="columnsToDisplayWithExpand.length"
      >
        <div
          class="example-element-detail"
          [@detailExpand]="
            element == expandedElement ? 'expanded' : 'collapsed'
          "
        >
          <!--      <div class="example-element-diagram">
            <div class="example-element-position"> {{element.pgn}} </div>
            <div class="example-element-symbol"> {{element.type}} </div>
            <div class="example-element-name"> {{element.label}} </div>
            <div class="example-element-weight"> {{element.created}} </div>
          </div> -->
          <div class="json-content">
            <table style="border: 1 solid #000000 !important">
              <tr>
                <th style="width: 5%">Sequence</th>
                <th style="width: 5%">SPN</th>
                <th style="width: 20%">Label</th>
                <th style="width: 40%">Description</th>
                <th style="width: 10%; text-align: center">Type</th>
                <th style="width: 10%; text-align: center">Offset</th>
              </tr>
              <tr *ngFor="let spn of element.spns" style="height: 40px">
                <td style="width: 5%">{{ spn.sequence }}</td>
                <td style="width: 5%">{{ spn.spn }}</td>
                <td style="width: 20%">{{ spn.label }}</td>
                <td style="width: 50%">
                  <p
                    readMore="1"
                    [readMoreOptions]="readMoreOption"
                    class="readmore"
                  >
                    {{ spn.description }}...
                  </p>
                </td>
                <td style="width: 10%; text-align: center">{{ spn.type }}</td>
                <td style="width: 10%; text-align: center">{{ spn.offset }}</td>
              </tr>
            </table>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
    <tr
      mat-row
      *matRowDef="let element; columns: columnsToDisplayWithExpand"
      class="example-element-row"
      [class.example-expanded-row]="expandedElement === element"
    ></tr>
    <!-- Add the this line to the above <tr> </tr> to enable the whole row clickable
    (click)="expandedElement = expandedElement === element ? null : element" -->
    <tr
      mat-row
      *matRowDef="let row; columns: ['expandedDetail']"
      class="example-detail-row"
    ></tr>
  </table>
  <mat-paginator
    [pageSizeOptions]="[10, 20, 50, 100]"
    showFirstLastButtons
    aria-label="Select page of periodic elements"
  >
  </mat-paginator>
</mat-card>
