import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalVariables } from 'src/app/core/_common/GlobalVariables';
import { DatadogService } from 'src/app/core/_services/datadog.service';

@Component({
  selector: 'app-view-dataset-config',
  templateUrl: './view-dataset-config.component.html',
  styleUrls: ['./view-dataset-config.component.scss']
})
export class ViewDatasetConfigComponent implements OnInit {
  sourceId: any;
  gpsDataConfig: any;
  digitalInputConfig: any;
  accelerometerConfig: any;
  appConfig: any;
  analyticsConfig: any;
  j1939Configs: any;
  j1587Configs: any;
  obd2Configs: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ViewDatasetConfigComponent>,
    private datadogService: DatadogService
  ) {}

  ngOnInit(): void {
    this.datadogService.log(
      `${GlobalVariables.USER_EMAIL} viewed Dataset-Config`,
      {
        name: 'View-Dataset-Config Component',
        id: 10072,
        userName: GlobalVariables.USER_GIVEN_NAME,
        userEmail: GlobalVariables.USER_EMAIL
      },
      'info'
    );
    this.parseConfigData();
  }

  parseConfigData() {
    this.sourceId = this.data.sourceId;
    this.gpsDataConfig = this.data.gpsDataConfig;
    this.digitalInputConfig = this.data.digitalInputConfig;
    this.accelerometerConfig = this.data.accelerometerConfig;
    this.appConfig = this.data.appConfig;
    this.analyticsConfig = this.data.analyticsConfig;
    this.j1939Configs = this.data.j1939Configs;
    this.j1587Configs = this.data.j1587Configs;
    this.obd2Configs = this.data.obd2Configs;
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
