<mat-card-title-group>
  <mat-card-title>Signal Config</mat-card-title>
  <mat-card-subtitle>Json view of signal config</mat-card-subtitle>
  <button mat-icon-button aria-label="close dialog" mat-dialog-close>
    <img src="assets/images/close-icon.png" width="30" height="30" />
  </button>
</mat-card-title-group>
<hr />
<div mat-dialog-content>
  <div class="json-content">
    <pre>
    <code [highlight]="signalConfig | json"></code>
  </pre>
  </div>
</div>
