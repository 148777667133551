import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PackageConfigModel } from '../_models/PackageConfigModel';

@Injectable({
  providedIn: 'root'
})
export class PackageConfigApiService {
  constructor(private http: HttpClient) {}

  async getAllPackageConfigs(): Promise<Observable<PackageConfigModel[]>> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('status', 'READY_FOR_CUSTOMER');
    queryParams = queryParams.append('per_page', '500');
    let url = `${environment.packageapi}`;
    return await this.http.get<PackageConfigModel[]>(url, {
      params: queryParams
    });
  }

  createPackageConfig(body: any): Observable<any> {
    let url = `${environment.packageapi}`;
    return this.http.post(url, body);
  }

  editPackageConfig(body: any, package_config_id: string): Observable<any> {
    let url = `${environment.packageapi}/${package_config_id}`;
    return this.http.patch(url, body);
  }

  generateConfig(packageConfigId: string): Observable<any> {
    let url = `${environment.packageapi}/${packageConfigId}/generate-configuration`;
    return this.http.post(url, null);
  }
}
