import { Component, OnInit, ViewChild } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SignalApiService } from 'src/app/core/_services/signal-api.service';
import { get, set } from 'idb-keyval';
import { PackageConfigModel } from 'src/app/core/_models/PackageConfigModel';
import { HttpClient } from '@angular/common/http';
import { PACKAGE_CONFIG_URL } from '../package-config/constants';
import { PGNModel } from 'src/app/core/_models/PGNModel';
import { MatDialog } from '@angular/material/dialog';
import { ActionSignalComponent } from './action-signal/action-signal.component';
import {
  ACTION_DIALOG_HEIGHT,
  ACTION_DIALOG_WIDTH,
  CREATE_DATASET_DIALOG_HEIGHT,
  CREATE_DATASET_DIALOG_WIDTH
} from './constants';
import Swal from 'sweetalert2';
import { ActionDatasetComponent } from '../dataset/action-dataset/action-dataset.component';
import { DatasetModel } from 'src/app/core/_models/DatasetModel';
import { ReadMoreOptions } from '@minni/read-more';
import { CsvExportService } from '../../csv-export.service';

export interface SpnModel {
  sequence: string;
  spn: string;
  type: string;
  start: string;
  length: string;
  label: string;
  description: string;
  units: string;
  resolution: string;
  offset: string;
  id: string;
  created: string;
  modified: string;
  pgnId: string;
}

export interface pgnData {
  id: string;
  pgn: Number;
  label: string;
  proprietaryId: string;
  source: string;
  channel: string;
}

@Component({
  selector: 'app-signal',
  templateUrl: './signal.component.html',
  styleUrls: ['./signal.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      )
    ])
  ]
})
export class SignalComponent implements OnInit {
  matSelectedConfig = '';
  filterInput = '';
  pgnDataSource: PGNModel[] = [];
  dataSource;
  columnsToDisplay = ['checked', 'pgn', 'type', 'label', 'description'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  columnsToDisplayWithExpand1 = ['expand'];
  expandedElement: SpnModel | null;
  tableLoading = true;
  packageConnfigList: PackageConfigModel[] = [];
  functionType = 'Create';
  addDatasetButtonStatus = true;
  datasetInput: any = {};
  pgnInput: pgnData[] = [];
  readMoreOption: ReadMoreOptions = {
    readLessText: 'less',
    readMoreText: 'more',
    styles: {
      color: '#000'
    },
    classes: ['custom-style', 'blog-style']
  };

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private signalApiService: SignalApiService,
    private httpClient: HttpClient,
    public dialog: MatDialog,
    private csvExport: CsvExportService
  ) {}

  ngOnInit(): void {
    get('PACKAGE_CONFIG_LIST_KEY').then(
      async (packageConfigData: PackageConfigModel[]) => {
        if (packageConfigData) {
          console.log('Found cached data');
          this.packageConnfigList = packageConfigData;
        } else {
          await this.httpClient
            .get<PackageConfigModel[]>(`${PACKAGE_CONFIG_URL}?per_page=200`)
            .toPromise()
            .then((data) => {
              this.packageConnfigList = data;
            });
        }
      }
    );
  }

  async ngAfterViewInit() {
    this.tableLoading = true;
    get('PGN_DATA_LIST_KEY').then(async (pgnList: PGNModel[]) => {
      if (pgnList) {
        console.log('Found cached data');
        for (const prop in pgnList) {
          pgnList[prop] = Object.assign({}, pgnList[prop], {
            checked: false
          });
        }
        this.pgnDataSource = pgnList;
        this.dataSource = new MatTableDataSource(pgnList);
        this.dataSource.pageSize = 10;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.tableLoading = false;
      } else {
        this.getAllPgns();
      }
    });
  }

  async getAllPgns() {
    try {
      this.tableLoading = true;
      (await this.signalApiService.getAllPGNS())
        .toPromise()
        .then((data: PGNModel[]) => {
          set('PGN_DATA_LIST_KEY', data).then(() => {
            console.log(
              'PGN list List stored in cache with the key "PGN_DATA_LIST_KEY"'
            );
          });
          this.pgnDataSource = data;
          this.dataSource = new MatTableDataSource(data);
          this.dataSource.pageSize = 10;
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.tableLoading = false;
        });
    } catch (error) {
      console.log('Error on retrieving all PGNs:', error);
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getConfigPgns(event) {
    this.filterInput = '';
    let pgnList: any;
    const selectedConfig: PackageConfigModel = this.packageConnfigList.find(
      (config) => {
        return config.id === event.value.trim();
      }
    );
    if (selectedConfig) {
      if (selectedConfig.jsonConfig.length > 0) {
        selectedConfig.jsonConfig.forEach((item: any) => {
          if (Object.keys(item)[0] == 'dataset') {
            pgnList = item.dataset.j1939.pgns;
            const yFilter = pgnList.map((itemY) => {
              return itemY.pgn;
            });

            const filteredX = this.pgnDataSource.filter((itemX) =>
              yFilter.includes(itemX.pgn)
            );
            this.dataSource.data = filteredX;
          }
        });
      }

      // if(selectedConfig.hasOwnProperty(''))
    }
  }

  resetPgnDataSource() {
    this.matSelectedConfig = '';
    this.dataSource.data = this.pgnDataSource;
  }

  refreshDataset() {
    this.getAllPgns();
  }

  actionSigal(functionType: any, data?: any) {
    if (data == undefined) {
      [(data = '')];
    }
    const input = {
      functionType,
      data
    };
    const dialogRef = this.dialog.open(ActionSignalComponent, {
      data: input,
      height: ACTION_DIALOG_HEIGHT,
      width: ACTION_DIALOG_WIDTH
    });

    dialogRef.afterClosed().subscribe((response) => {
      const result = response.split(',');
      const actionType = result[0];
      const status = result[1];
      const message = result[2];

      if (actionType == 'Create') {
        if (status == 'Success') {
          this.successfulMessage('PGN created successfully!', actionType);
          this.getAllPgns();
        } else {
          this.errorHandler(message, actionType);
        }
      } else if (actionType == 'Clone') {
        if (status == 'Success') {
          this.successfulMessage('PGN cloned successfully!', actionType);
          this.getAllPgns();
        } else {
          this.errorHandler(message, actionType);
        }
      } else if (actionType == 'Update') {
        if (status == 'Success') {
          this.successfulMessage('PGN updated successfully!', actionType);
          this.getAllPgns();
        } else {
          this.errorHandler(message, actionType);
        }
      }
    });
  }

  createDataset() {
    /*  this.dataSource.data.forEach((t) =>
      t.checked == true ? this.pgnInput.push({ pgn: t.pgn, proprietary_id: t.providerId, source: '255', channel: 'CHAN0' }) : null
    ); */

    let type = '';

    try {
      const foundPGN = this.dataSource.filteredData.find((ds) => {
        return ds.pgn === this.pgnInput[0].pgn;
      });

      type = foundPGN.type.trim().toLowerCase();
    } catch (e) {}

    this.datasetInput.j1939Configs = this.pgnInput;

    const input = {
      functionType: 'Create',
      data: this.datasetInput,
      pgnType: type,
      allDataSource: this.dataSource.filteredData
    };

    const dialogRef = this.dialog.open(ActionDatasetComponent, {
      data: input,
      height: CREATE_DATASET_DIALOG_HEIGHT,
      width: CREATE_DATASET_DIALOG_WIDTH
    });

    dialogRef.afterClosed().subscribe((response) => {
      const result = response.split(',');
      const actionType = result[0];
      const status = result[1];
      const message = result[2];

      if (actionType == 'Create') {
        if (status == 'Success') {
          this.successfulMessage('Dataset created successfully!', actionType);
        } else {
          this.errorHandler(message, actionType);
        }
      } else if (actionType == 'Clone') {
        if (status == 'Success') {
          this.successfulMessage('Dataset cloned successfully!', actionType);
        } else {
          this.errorHandler(message, actionType);
        }
      } else if (actionType == 'Update') {
        if (status == 'Success') {
          this.successfulMessage('Dataset updated successfully!', actionType);
        } else {
          this.errorHandler(message, actionType);
        }
      }
    });
  }

  errorHandler(error, actionType) {
    Swal.fire({
      icon: 'error',
      title: actionType + ' PGN Error',
      text: error
    });
  }

  successfulMessage(message, actionType) {
    Swal.fire({
      icon: 'success',
      title: 'Success!',
      text: message
    });
  }

  // Function to update the list of checked assets from the UI table
  updateChecked(event: Event, pgnItem: any) {
    this.addDatasetButtonStatus = true;

    if (event) {
      this.pgnInput.push({
        id: pgnItem.id,
        pgn: pgnItem.pgn,
        label: pgnItem.label,
        proprietaryId: pgnItem.id,
        source: '255',
        channel: 'CHAN0'
      });

      this.dataSource.data.forEach((t) =>
        t.id === pgnItem.id ? (t.checked = true) : null
      );
    } else {
      this.pgnInput.forEach((element, index) => {
        if (element.pgn == pgnItem.pgn) {
          this.pgnInput.splice(index, 1);
        }
      });

      this.dataSource.data.forEach((t) =>
        t.id === pgnItem.id ? (t.checked = false) : null
      );
    }

    this.dataSource.data.forEach((t) =>
      t.checked ? (this.addDatasetButtonStatus = false) : null
    );

    this.dataSource.data = this.dataSource.data.sort((a: any, b: any) => {
      return b.checked - a.checked;
    });
  }

  public exportCSV(data: any): void {
    this.csvExport.exportCSV(data.filteredData);
  }
}
