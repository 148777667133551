import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DatasetModel } from '../_models/DatasetModel';

@Injectable({
  providedIn: 'root'
})
export class DatasetApiService {
  constructor(private http: HttpClient) {}

  async getAllDatasets(params?: any): Promise<Observable<any>> {
    let queryParams = new HttpParams();
    queryParams = params;

    return await this.http
      .get(`${environment.datasetapi}/datasets`, {
        params: queryParams
      })
      .pipe(
        catchError((err) => {
          console.log('error caught in getAllDatasets() service');
          console.error(err);

          //Handle the error here
          return throwError(err); //Rethrow it back to component
        })
      );
  }

  async createdDataset(body: any): Promise<Observable<any>> {
    return await this.http
      .post(`${environment.datasetapi}/datasets`, body)
      .pipe(
        catchError((err) => {
          console.log('error caught in createdDataset() service');
          console.error(err);

          //Handle the error here
          return throwError(err); //Rethrow it back to component
        })
      );
  }
}
