import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatasetApiService } from 'src/app/core/_services/dataset-api.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { StepperOrientation } from '@angular/material/stepper';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GlobalVariables } from 'src/app/core/_common/GlobalVariables';
import { DatadogService } from 'src/app/core/_services/datadog.service';
import * as uuid from 'uuid';
import { SignalApiService } from 'src/app/core/_services/signal-api.service';
import { PGNModel } from 'src/app/core/_models/PGNModel';

export interface PGN_LIST {
  id: string;
  pgn: Number;
  proprietaryId?: string;
  label: string;
  source: Number;
  channel: string;
  type?: string;
}

@Component({
  selector: 'app-action-dataset',
  templateUrl: './action-dataset.component.html',
  styleUrls: ['./action-dataset.component.scss']
})
export class ActionDatasetComponent implements OnInit {
  datasetFormGroup = this._formBuilder.group({
    datasetNameFormControl: ['', Validators.required],
    descriptionFormControl: [''],
    typeFormControl: ['GPS_VEHICLE_DATA', Validators.required],
    sourceIDFormControl: [null],
    includePrecisionDataFormControl: ['false', Validators.required],
    includeStatesFormControl: ['false', Validators.required]
  });
  appConfigFormGroup = this._formBuilder.group({
    gpsFixStateFormControl: ['false', Validators.required],
    gpsAntennaStateFormControl: ['false', Validators.required],
    gpsJammingStateFormControl: ['false', Validators.required],
    panicInputStateFormControl: ['false', Validators.required],
    engineStateFormControl: ['false', Validators.required],
    motionStateFormControl: ['false', Validators.required],
    speedStateFormControl: ['false', Validators.required],
    odometerStateFormControl: ['false', Validators.required],
    batteryVoltageStateFormControl: ['false', Validators.required]
  });
  pgnFormGroup = this._formBuilder.group({
    pgnFormControl: ['', Validators.required],
    proprietaryIdFormControl: [null],
    selectPgnFormControl: [null],
    sourceFormControl: ['255', Validators.required],
    channelFormControl: ['CHAN0', Validators.required]
  });

  futureFormGroup = this._formBuilder.group({
    accelerometerConfigFormControl: [
      { value: '', disabled: true },
      Validators.required
    ],
    analyticsConfigFormControl: [
      { value: '', disabled: true },
      Validators.required
    ],
    j1587ConfigsFormControl: [
      { value: '', disabled: true },
      Validators.required
    ],
    obd2ConfigsFormControl: [{ value: '', disabled: true }, Validators.required]
  });
  submitFormGroup = this._formBuilder.group({
    statusFormControl: ['PENDING', Validators.required]
  });
  stepperOrientation: Observable<StepperOrientation>;

  loading: boolean = false;
  buttonLabel: string = 'Create Dataset';
  typeList: string[] = ['GPS_VEHICLE_DATA', 'FAULT', 'CUSTOM'];
  statusList: string[] = ['ACTIVE', 'INACTIVE', 'PENDING'];
  functionType: string;
  passedPGNType: string;
  allDataSource: any;
  jsonValue;
  jsonAttributeInputValue = [
    {
      name: 'True',
      value: true
    },
    {
      name: 'False',
      value: false
    }
  ];
  channelTypeList: any = [
    'CHAN_UNKNOWN',
    'CHAN0',
    'CHAN1',
    'CHAN2',
    'CHAN3',
    'CHAN4',
    'CHAN5',
    'CHAN6',
    'CHAN7',
    'CHAN8',
    'CHAN_DONGLE_OBDPROS'
  ];
  j1939ConfigList: PGN_LIST[] = [];
  propPgnList: any = [];
  pgnNumberExists: boolean = false;

  constructor(
    private datasetApiService: DatasetApiService,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private _formBuilder: FormBuilder,
    breakpointObserver: BreakpointObserver,
    public dialogRef: MatDialogRef<ActionDatasetComponent>,
    private datadogService: DatadogService,
    private signalApiService: SignalApiService
  ) {
    this.stepperOrientation = breakpointObserver
      .observe('(min-width: 800px)')
      .pipe(map(({ matches }) => (matches ? 'horizontal' : 'vertical')));
  }

  ngOnInit(): void {
    console.log(this.dialogData);
    this.datadogService.log(
      `${GlobalVariables.USER_EMAIL} accessed Create/Clone-Dataset Page`,
      {
        name: 'Create/Clone-Dataset Component',
        id: 10073,
        userName: GlobalVariables.USER_GIVEN_NAME,
        userEmail: GlobalVariables.USER_EMAIL
      },
      'info'
    );
    this.functionType = this.dialogData.functionType;
    this.passedPGNType = this.dialogData.pgnType ? this.dialogData.pgnType : '';
    this.allDataSource = this.dialogData.allDataSource
      ? this.dialogData.allDataSource
      : [];
    this.buttonLabel = this.dialogData.functionType + ' Dataset';

    if (
      this.dialogData.functionType == 'Edit' ||
      this.dialogData.functionType == 'Clone'
    ) {
      this.datasetFormGroup.controls['datasetNameFormControl'].setValue(
        this.dialogData.data.name
      );
      this.datasetFormGroup.controls['descriptionFormControl'].setValue(
        this.dialogData.data.description
      );
      this.datasetFormGroup.controls['typeFormControl'].setValue(
        this.dialogData.data.type
      );
      this.datasetFormGroup.controls['sourceIDFormControl'].setValue(
        this.dialogData.data.sourceId
      );

      if (this.dialogData.data.hasOwnProperty('gpsDataConfig')) {
        if (
          this.dialogData.data.gpsDataConfig != null &&
          this.dialogData.data.gpsDataConfig.hasOwnProperty(
            'includePrecisionData'
          )
        ) {
          this.datasetFormGroup.controls[
            'includePrecisionDataFormControl'
          ].setValue(
            this.dialogData.data.gpsDataConfig.includePrecisionData.toString()
          );
        }
      }

      if (this.dialogData.data.hasOwnProperty('digitalInputConfig')) {
        if (
          this.dialogData.data.digitalInputConfig != null &&
          this.dialogData.data.digitalInputConfig.hasOwnProperty(
            'includeStates'
          )
        ) {
          this.datasetFormGroup.controls['includeStatesFormControl'].setValue(
            this.dialogData.data.digitalInputConfig.includeStates.toString()
          );
        }
      }

      if (this.dialogData.data.hasOwnProperty('appConfig')) {
        if (this.dialogData.data.appConfig.hasOwnProperty('gpsFixState')) {
          if (this.dialogData.data.appConfig.gpsFixState != null) {
            this.appConfigFormGroup.controls['gpsFixStateFormControl'].setValue(
              this.dialogData.data.appConfig.gpsFixState.toString()
            );
          }
        }
        if (this.dialogData.data.appConfig.hasOwnProperty('gpsAntennaState')) {
          if (this.dialogData.data.appConfig.gpsAntennaState != null) {
            this.appConfigFormGroup.controls[
              'gpsAntennaStateFormControl'
            ].setValue(
              this.dialogData.data.appConfig.gpsAntennaState.toString()
            );
          }
        }

        if (this.dialogData.data.appConfig.hasOwnProperty('gpsJammingState')) {
          if (this.dialogData.data.appConfig.gpsJammingState != null) {
            this.appConfigFormGroup.controls[
              'gpsJammingStateFormControl'
            ].setValue(
              this.dialogData.data.appConfig.gpsJammingState.toString()
            );
          }
        }

        if (this.dialogData.data.appConfig.hasOwnProperty('panicInputState')) {
          if (this.dialogData.data.appConfig.panicInputState != null) {
            this.appConfigFormGroup.controls[
              'panicInputStateFormControl'
            ].setValue(
              this.dialogData.data.appConfig.panicInputState.toString()
            );
          }
        }

        if (this.dialogData.data.appConfig.hasOwnProperty('engineState')) {
          if (this.dialogData.data.appConfig.engineState != null) {
            this.appConfigFormGroup.controls['engineStateFormControl'].setValue(
              this.dialogData.data.appConfig.engineState.toString()
            );
          }
        }

        if (this.dialogData.data.appConfig.hasOwnProperty('motionState')) {
          if (this.dialogData.data.appConfig.motionState != null) {
            this.appConfigFormGroup.controls['motionStateFormControl'].setValue(
              this.dialogData.data.appConfig.motionState.toString()
            );
          }
        }

        if (this.dialogData.data.appConfig.hasOwnProperty('speedState')) {
          if (this.dialogData.data.appConfig.speedState != null) {
            this.appConfigFormGroup.controls['speedStateFormControl'].setValue(
              this.dialogData.data.appConfig.speedState.toString()
            );
          }
        }

        if (this.dialogData.data.appConfig.hasOwnProperty('odometerState')) {
          if (this.dialogData.data.appConfig.odometerState != null) {
            this.appConfigFormGroup.controls[
              'odometerStateFormControl'
            ].setValue(this.dialogData.data.appConfig.odometerState.toString());
          }
        }

        if (
          this.dialogData.data.appConfig.hasOwnProperty('batteryVoltageState')
        ) {
          if (this.dialogData.data.appConfig.batteryVoltageState != null) {
            this.appConfigFormGroup.controls[
              'batteryVoltageStateFormControl'
            ].setValue(
              this.dialogData.data.appConfig.batteryVoltageState.toString()
            );
          }
        }
      }

      this.j1939ConfigList = this.dialogData.data.j1939Configs;

      this.submitFormGroup.controls['statusFormControl'].setValue(
        this.dialogData.data.status
      );
    } else {
      if (this.dialogData.data.hasOwnProperty('j1939Configs')) {
        this.j1939ConfigList = this.dialogData.data.j1939Configs;
      }
    }
  }

  async createDataset() {
    this.datadogService.log(
      `${GlobalVariables.USER_EMAIL} clicked "Create/Clone" button in "Create-Dataset" Page`,
      {
        name: 'Create/Clone - Create-Dataset Component',
        id: 10074,
        userName: GlobalVariables.USER_GIVEN_NAME,
        userEmail: GlobalVariables.USER_EMAIL
      },
      'info'
    );

    this.j1939ConfigList = this.j1939ConfigList.map((element) => {
      const newElement = { ...element };

      delete newElement.id;
      delete newElement.label;

      if (this.passedPGNType === 'standard') {
        delete newElement.proprietaryId;
      }

      return newElement;
    });

    let jsonBody = {
      name: this.datasetFormGroup.controls['datasetNameFormControl'].value,
      description:
        this.datasetFormGroup.controls['descriptionFormControl'].value,
      type: this.datasetFormGroup.controls['typeFormControl'].value,
      sourceId: this.datasetFormGroup.controls['sourceIDFormControl'].value,
      gpsDataConfig: {
        includePrecisionData:
          this.datasetFormGroup.controls['includePrecisionDataFormControl']
            .value
      },
      digitalInputConfig: {
        includeStates:
          this.datasetFormGroup.controls['includeStatesFormControl'].value
      },
      appConfig: {
        gpsFixState:
          this.appConfigFormGroup.controls['gpsFixStateFormControl'].value,
        gpsAntennaState:
          this.appConfigFormGroup.controls['gpsAntennaStateFormControl'].value,
        gpsJammingState:
          this.appConfigFormGroup.controls['gpsJammingStateFormControl'].value,
        panicInputState:
          this.appConfigFormGroup.controls['panicInputStateFormControl'].value,
        engineState:
          this.appConfigFormGroup.controls['engineStateFormControl'].value,
        motionState:
          this.appConfigFormGroup.controls['motionStateFormControl'].value,
        speedState:
          this.appConfigFormGroup.controls['speedStateFormControl'].value,
        odometerState:
          this.appConfigFormGroup.controls['odometerStateFormControl'].value,
        batteryVoltageState:
          this.appConfigFormGroup.controls['batteryVoltageStateFormControl']
            .value
      },
      j1939Configs: this.j1939ConfigList,
      accelerometerConfig: {},
      analyticsConfig: {},
      j1587Configs: [
        {
          pid: 0,
          mid: 0,
          channel: 'CHAN0'
        }
      ],
      obd2Configs: [{}],
      status: this.submitFormGroup.controls['statusFormControl'].value
    };

    console.log(jsonBody);

    (await this.datasetApiService.createdDataset(jsonBody)).toPromise().then(
      (response) => {
        this.datadogService.log(
          `${GlobalVariables.USER_EMAIL}  created/cloned dataset`,
          {
            name: 'Dataset Component',
            id: 10075,
            userName: GlobalVariables.USER_GIVEN_NAME,
            userEmail: GlobalVariables.USER_EMAIL
          },
          'info'
        );
        let status = this.functionType + ',Success, Success';
        this.closeDialog(status);
      },
      (error: Response) => {
        if (error.status == 422) {
          let status = `${this.functionType},Error,[CD-422401] - Un processable entity`;
          this.closeDialog(status);
        } else if (error.status == 400) {
          let status = `${this.functionType},Error,[CD-400402] - ${this.datasetFormGroup.controls['datasetNameFormControl'].value} already exists in database`;
          this.closeDialog(status);
        } else {
          let status = `${this.functionType},Error,[CD-100410] - ${error.text}`;
          this.closeDialog(status);
        }
      }
    );
  }

  addJ1939Config() {
    this.pgnNumberExists = false;

    let selectedPgn: any = JSON.parse(
      this.pgnFormGroup.controls['selectPgnFormControl'].value
    );

    let pgnItem = this.j1939ConfigList.find((t) => t.id === selectedPgn.id);

    let pgnType: string = '';

    try {
      pgnType = selectedPgn.type.trim().toLowerCase();
    } catch (e) {}

    if (pgnItem === undefined) {
      let data: PGN_LIST = {
        id: selectedPgn.id,
        pgn: Number(this.pgnFormGroup.controls['pgnFormControl'].value),
        proprietaryId: selectedPgn.id,
        label: selectedPgn.label,
        source: Number(this.pgnFormGroup.controls['sourceFormControl'].value),
        channel: this.pgnFormGroup.controls['channelFormControl'].value
      };

      if (pgnType === 'standard') {
        delete data.proprietaryId;
      }

      this.j1939ConfigList.push(data);
    } else {
      this.pgnNumberExists = true;
    }
  }

  deleteJ1939Config(j1939Config: any) {
    this.j1939ConfigList.forEach((element, index) => {
      if (element == j1939Config) this.j1939ConfigList.splice(index, 1);
    });

    console.log(this.j1939ConfigList);
  }

  async closeDialog(functionType: string) {
    try {
      this.dialogRef.close(functionType); // make sure it only closes if the upper async fn succesfully ran!
    } catch (e) {
      //this.errorMessage = e.response.message;
    }
  }

  updatePgnList(event, id: any, type: string) {
    console.log(event, id, type);
    this.j1939ConfigList.forEach((element, index) => {
      if (element.id === id) {
        if (type == 'source') element.source = event.target.value;
        else element.channel = event.value;
      }
    });
  }

  async onSearchChange(searchValue: string): Promise<void> {
    console.log(searchValue);
    (await this.signalApiService.getPgnByNumber(Number(searchValue)))
      .toPromise()
      .then((data) => {
        console.log(data);
        this.propPgnList = data;
      });
  }

  async getPgn() {
    let pgnValue = this.pgnFormGroup.controls['pgnFormControl'].value;
    (await this.signalApiService.getPgnByNumber(Number(pgnValue)))
      .toPromise()
      .then((data) => {
        console.log(data);
        this.propPgnList = data;
      });
  }

  public showProprietaryId(pgn: PGN_LIST): boolean {
    let type = '';

    try {
      const foundPGN = this.allDataSource.find((ds) => {
        return ds.pgn === pgn.pgn;
      });

      type = foundPGN.type.trim().toLowerCase();
    } catch (e) {}

    return type.trim().toLowerCase() !== 'standard';
  }
}
