import { Injectable } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';
import { environment } from 'src/environments/environment';
import { datadogLogs } from '@datadog/browser-logs';
import { statusType } from '@datadog/browser-logs/bundle/datadog-logs';

@Injectable({
  providedIn: 'root'
})
export class DatadogService {
  constructor() {
    datadogRum.init({
      applicationId: environment.datadog.applicationId,
      clientToken: environment.datadog.clientToken,
      site: environment.datadog.site,
      service: environment.appName,
      env: environment.env,
      version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingOrigins: [/https:\/\/.*\.zonarsystems\.net/]
    });

    datadogLogs.init({
      clientToken: environment.datadog.clientToken,
      site: environment.datadog.site,
      service: environment.appName,
      env: environment.env,
      version: '1.0.0',
      forwardErrorsToLogs: true,
      forwardConsoleLogs: 'all',
      sessionSampleRate: 100
    });
  }

  setUser(id: string, name: string, email: string): void {
    datadogRum.setUser({
      id: id,
      name: name,
      email: email
    });
  }

  startSessionReplayRecording(): void {
    datadogRum.startSessionReplayRecording();
  }

  stopSessionReplayRecording(): void {
    datadogRum.stopSessionReplayRecording();
  }

  log(message: string, attributes, status: statusType = 'info'): void {
    datadogLogs.logger.log(message, attributes, status);
  }
}
