<div class="asset-mapping-trigger-edit-header">
  Signal Trigger Mapping Create
</div>

<div class="asset-mapping-trigger-edit-options" [formGroup]="triggerEditGroup">
  <div class="option">
    <div class="option-header">Trigger Status</div>

    <div class="option-value">
      <mat-form-field>
        <mat-label>Trigger Name</mat-label>
        <input
          matInput
          formControlName="triggerName"
          type="text"
          placeholder="Trigger name"
        />
      </mat-form-field>
    </div>

    <div class="option-value">
      <mat-form-field>
        <mat-label>Logic Field Name</mat-label>
        <input
          matInput
          formControlName="logicFieldName"
          type="text"
          placeholder="Logic Field Name"
        />
      </mat-form-field>
    </div>

    <div class="option-value">
      <mat-form-field>
        <mat-label>Transformer Name</mat-label>
        <input
          matInput
          formControlName="transformerName"
          type="text"
          placeholder="Transformer Name"
        />
      </mat-form-field>
    </div>
  </div>
</div>

<div class="asset-mapping-trigger-edit-buttons">
  <div>
    <button (click)="onSubmit()" mat-button>Create Trigger</button>
  </div>

  <div>
    <button (click)="onClose()" mat-button>Close</button>
  </div>
</div>
