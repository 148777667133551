import { createReducer, on } from '@ngrx/store';
import * as actions from '../store/select-account.actions';

export const accountInitialState: any = [];

const AccountReducer = createReducer(
  accountInitialState,
  // @ts-ignore
  on(actions.addAccount, (state, { account }) => ({
    ...state,
    account: [...account]
  })),
  on(actions.cleanAccount, () => accountInitialState)
);

export const accountReducer = (state, action) => AccountReducer(state, action);
