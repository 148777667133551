import { environment } from '../../../environments/environment';

export const CONFIG_URL = `${environment.iotcoreapi}/configs`;
export const PACKAGE_CONFIG_URL = `${environment.packageapi}`;
export const PUBLISH_CONFIG_URL = '/account/publish-config';
export const ASSET_API_URL = `${environment.serviceapi}/assets`;
export const DEVICE_API_URL = `${environment.serviceapi}/devices`;
export const VALIDATION_API_URL = `${environment.validationapi}`;

export const QUERY_PARAM_PER_PAGE = 'per_page';
export const QUERY_PARAM_PER_PAGE_VALUE = '500';

export const SIGNAL_DIALOG_HEIGHT = '60%';
export const SIGNAL_DIALOG_WIDTH = '50%';

export const CREATE_TRIGGER_DIALOG_HEIGHT = '85%';
export const CREATE_TRIGGER_DIALOG_WIDTH = '70%';

// TODO: Possibly derive these from object attributes
export const DISPLAYED_COLUMNS: string[] = [
  'name',
  'type',
  'datasetId',
  'signalType',
  'created',
  'modified',
  'actions'
];

export const TYPE_LIST: string[] = ['STANDARD', 'BUILT_IN', 'CUSTOM'];
export const OPERATORS: string[] = [
  'OP_EQUAL_TO',
  'OP_NOT_EQUAL_TO',
  'OP_LESS_THAN',
  'OP_LESS_THAN_EQUAL_TO',
  'OP_GREATER_THAN',
  'OP_GREATER_THAN_EQUAL_TO'
];
export const SIGNAL_TYPE_LIST: any[] = [
  {
    name: 'GPS_LOG_EVENT',
    status: true
  },
  {
    name: 'ENGINE_RUN_EVENT',
    status: true
  },
  {
    name: 'PGN_SEGMENT',
    status: true
  },
  {
    name: 'PGN_EVENT',
    status: true
  },
  {
    name: 'BUILTIN_SPN_SEGMENT',
    status: true
  },
  {
    name: 'BUILTIN_SPN_EVENT',
    status: true
  },
  {
    name: 'TRIP_SEGMENT',
    status: true
  },
  {
    name: 'TRIP_EVENT',
    status: true
  },

  {
    name: 'ENGINE_RUN_SEGMENT',
    status: true
  },
  {
    name: 'FAULT_SEGMENT',
    status: true
  },
  {
    name: 'FAULT_EVENT',
    status: true
  },
  {
    name: 'DIGITAL_INPUT_SEGMENT',
    status: true
  },
  {
    name: 'DIGITAL_INPUT_EVENT',
    status: true
  }
];

export const CHANNEL_LIST: any = [
  'CHAN_UNKNOWN',
  'CHAN0',
  'CHAN1',
  'CHAN2',
  'CHAN3',
  'CHAN4',
  'CHAN5',
  'CHAN6',
  'CHAN7',
  'CHAN8',
  'CHAN_DONGLE_OBDPROS'
];

export const EVENT_TYPE: any = [
  {
    eventName: 'GPS_LOG_EVENT',
    eventTypeList: ['EVENT_POINT_LOGGED']
  },
  {
    eventName: 'ENGINE_RUN_EVENT',
    eventTypeList: ['EVENT_ON', 'EVENT_OFF']
  }
];
