import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Component({
  selector: 'zonar-signal-trigger-mapping-edit-modal',
  templateUrl: './signal-trigger-mapping-edit-modal.component.html',
  styleUrls: ['./signal-trigger-mapping-edit-modal.component.scss']
})
export class SignalTriggerMappingEditModalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<SignalTriggerMappingEditModalComponent>,
    private formBuilder: FormBuilder,
    private http: HttpClient
  ) {}

  public triggerEditGroup: FormGroup;

  public statusOptions = ['ACTIVE', 'INACTIVE'];

  ngOnInit(): void {
    this.triggerEditGroup = this.formBuilder.group({
      status: [this.data.status, Validators.required]
    });
  }

  onSubmit(): void {
    const payload = JSON.parse(JSON.stringify(this.data));

    payload.status = this.triggerEditGroup.get('status')?.value;

    delete payload.id;
    delete payload.modified;
    delete payload.created;
    delete payload.assetId;
    delete payload.companyCode;
    delete payload.companyId;
    delete payload.configId;
    delete payload.datasetId;
    delete payload.logicFieldName;
    delete payload.triggerId;
    delete payload.triggerName;

    this.http
      .patch(
        environment.assetTriggerMappingsUpdate + '/' + this.data.id,
        payload
      )
      .subscribe((response) => {
        this.dialogRef.close({ updated: true });
      });
  }

  onClose(): void {
    this.dialogRef.close({ updated: false });
  }
}
