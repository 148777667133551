import { Action, createReducer, on } from '@ngrx/store';
import * as actions from './divisions-assets-devices.actions';

const divisionInitialState: any = [];
const assetsInitialState: any = [];
const devicesInitialState: any = [];

const DivisionsListReducer = createReducer(
  divisionInitialState,
  on(actions.addDivisions, (state, { divisions }) => ({
    ...state,
    divisions: [...divisions]
  })),
  on(actions.cleanDivisions, () => divisionInitialState)
);

const AssetsListReducer = createReducer(
  assetsInitialState,
  on(actions.addAssets, (state, { assets }) => ({
    ...state,
    assets: [...assets]
  })),
  on(actions.cleanAssets, () => assetsInitialState)
);

const DevicesListReducer = createReducer(
  devicesInitialState,
  on(actions.addDevices, (state, { devices }) => ({
    ...state,
    devices: [...devices]
  })),
  on(actions.cleanDevices, () => devicesInitialState)
);

export const divisionsListReducer = (state = [], action: Action) =>
  DivisionsListReducer(state, action);
export const assetsListReducer = (state = [], action: Action) =>
  AssetsListReducer(state, action);
export const devicesListReducer = (state = [], action: Action) =>
  DevicesListReducer(state, action);
