import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DatasetModel } from 'src/app/core/_models/DatasetModel';
import { DatasetApiService } from 'src/app/core/_services/dataset-api.service';
import {
  CONFIG_DIALOG_HEIGHT,
  CONFIG_DIALOG_WIDTH,
  CREATE_DATASET_DIALOG_HEIGHT,
  CREATE_DATASET_DIALOG_WIDTH,
  DISPLAYED_COLUMNS
} from './constants';
import { ViewDatasetConfigComponent } from './view-dataset-config/view-dataset-config.component';
import { ActionDatasetComponent } from './action-dataset/action-dataset.component';
import { clear, get, set } from 'idb-keyval';
import { MatSort } from '@angular/material/sort';
import Swal from 'sweetalert2';
import { DatadogService } from 'src/app/core/_services/datadog.service';
import { GlobalVariables } from 'src/app/core/_common/GlobalVariables';
import { CsvExportService } from '../../csv-export.service';

@Component({
  selector: 'app-dataset',
  templateUrl: './dataset.component.html',
  styleUrls: ['./dataset.component.scss']
})
export class DatasetComponent implements OnInit, AfterViewInit {
  tableLoading: boolean = true;
  displayedColumns: string[] = DISPLAYED_COLUMNS;
  allTriggers: DatasetModel[];
  dataSource;
  allDatasets: DatasetModel[];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private datasetApiService: DatasetApiService,
    public dialog: MatDialog,
    private datadogService: DatadogService,
    private csvExport: CsvExportService
  ) {}

  ngOnInit(): void {
    this.datadogService.log(
      `${GlobalVariables.USER_EMAIL} accessed Dataset Page`,
      {
        name: 'Dataset Component',
        id: 1007,
        userName: GlobalVariables.USER_GIVEN_NAME,
        userEmail: GlobalVariables.USER_EMAIL
      },
      'info'
    );
  }

  ngAfterViewInit() {
    get('DATSET_LIST_KEY').then((datasetModel: DatasetModel[]) => {
      if (datasetModel) {
        console.log('Found cached data');
        this.loadTable(datasetModel);
      } else {
        this.getAllDataset();
      }
    });
  }

  private loadTable(datasetModel: DatasetModel[]): void {
    datasetModel = datasetModel.sort((a: any, b: any) => {
      return a.name - b.name;
    });
    this.dataSource = new MatTableDataSource(datasetModel);
    this.paginator.pageSize = 10;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.tableLoading = false;
  }

  async getAllDataset() {
    this.tableLoading = true;
    (await this.datasetApiService.getAllDatasets()).subscribe(
      (datasetList: DatasetModel[]) => {
        this.loadTable(datasetList);
        set('DATSET_LIST_KEY', datasetList).then(() => {
          console.log(
            'Dataset List stored in cache with the key "DATASET_LIST_KEY"'
          );
        });
        this.tableLoading = false;
      },
      (error) => {
        //Error callback
        //this.userMessage = `There is problem getting list of triggers. Please try later!`;
        //this.userMessageShow = true;
        this.tableLoading = false;

        //throw error;   //You can also throw the error to a global error handler
      }
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  actionDataset(functionType: any, data?: any) {
    this.datadogService.log(
      `${GlobalVariables.USER_EMAIL} clicked "Create/Clone" button in "Dataset" Page`,
      {
        name: 'Create/Clone - Dataset Component',
        id: 10072,
        userName: GlobalVariables.USER_GIVEN_NAME,
        userEmail: GlobalVariables.USER_EMAIL
      },
      'info'
    );
    if (data == undefined) [(data = '')];
    let input = {
      functionType: functionType,
      data: data
    };
    const dialogRef = this.dialog.open(ActionDatasetComponent, {
      data: input,
      height: CREATE_DATASET_DIALOG_HEIGHT,
      width: CREATE_DATASET_DIALOG_WIDTH
    });

    dialogRef.afterClosed().subscribe((response) => {
      var result = response.split(',');
      var actionType = result[0];
      var status = result[1];
      var message = result[2];

      if (actionType == 'Create') {
        if (status == 'Success') {
          this.successfulMessage('Dataset created successfully!', actionType);
          this.getAllDataset();
        } else this.errorHandler(message, actionType);
      } else if (actionType == 'Clone') {
        if (status == 'Success') {
          this.successfulMessage('Dataset cloned successfully!', actionType);
          this.getAllDataset();
        } else this.errorHandler(message, actionType);
      } else if (actionType == 'Update') {
        if (status == 'Success') {
          this.successfulMessage('Dataset updated successfully!', actionType);
          this.getAllDataset();
        } else this.errorHandler(message, actionType);
      }
    });
  }

  viewDatasetConfig(config: any) {
    let data = {
      sourceId: config.sourceId,
      gpsDataConfig: config.gpsDataConfig,
      digitalInputConfig: config.digitalInputConfig,
      accelerometerConfig: config.accelerometerConfig,
      appConfig: config.appConfig,
      analyticsConfig: config.analyticsConfig,
      j1939Configs: config.j1939Configs,
      j1587Configs: config.j1587Configs,
      obd2Configs: config.obd2Configs
    };
    this.dialog.open(ViewDatasetConfigComponent, {
      data: data,
      height: CONFIG_DIALOG_HEIGHT,
      width: CONFIG_DIALOG_WIDTH
    });
  }

  refreshDataset() {
    this.datadogService.log(
      `${GlobalVariables.USER_EMAIL} clicked "Refresh Data" button in "Datset" Page`,
      {
        name: 'Refresh button - Dataset Component',
        id: 10071,
        userName: GlobalVariables.USER_GIVEN_NAME,
        userEmail: GlobalVariables.USER_EMAIL
      },
      'info'
    );
    this.getAllDataset();
  }

  errorHandler(error, actionType) {
    Swal.fire({
      icon: 'error',
      title: actionType + ' Dataset Error',
      text: error
    });
  }

  successfulMessage(message, actionType) {
    Swal.fire({
      icon: 'success',
      title: 'Success!',
      text: message
    });
  }

  public exportCSV(data: any): void {
    this.csvExport.exportCSV(data.filteredData);
  }
}
