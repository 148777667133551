import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'zonar-json-dialog',
  templateUrl: './json-dialog.component.html',
  styleUrls: ['./json-dialog.component.scss']
})
export class JsonDialogComponent implements OnInit {
  configLabel = '';
  configData = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<JsonDialogComponent>
  ) {}

  ngOnInit(): void {
    this.parseConfigData();
  }

  parseConfigData() {
    try {
      if (typeof this.data.config_json === 'string') {
        this.data.config_json = JSON.parse(this.data.config_json);
      }
      const json = this.data.config_json;

      this.configLabel = this.data.config_label;

      if (json != null) {
        this.configData = json;
      } else {
        this.configData = 'Config Not Found';
      }
    } catch (_) {
      console.log('Error occured at parsing json');
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
