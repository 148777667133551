import { createAction, props } from '@ngrx/store';
import { DeviceConfigMapping } from '../../core/_models/DeviceConfigMapping';

// @ts-ignore
export const addDataSource = createAction(
  '[ SET DATASOURCE ]',
  props<{ dataSource: DeviceConfigMapping[] }>()
);

export const cleanDataSource = createAction('[ CLEAN DATASOURCE ]');
