<div class="container mt-5">
  <ng-container *ngIf="error$ | async as error">
    <h1>An error was returned from Auth0</h1>
    <p>
      Something went wrong when trying to authorize your application. Please
      inspect the error below and ensure <code>auth_config.json</code> is
      configured correctly.
    </p>
    <div class="alert alert-danger" role="alert">
      {{ error.error_description }}
    </div>
  </ng-container>
</div>
