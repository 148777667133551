<mat-card>
  <mat-card-header>
    <h1 class="mb-4">IoT Device Management</h1>
  </mat-card-header>
  <mat-divider></mat-divider>
  <mat-card-content>
    <div class="container hero my-5">
      <p>
        A Data Platform DIET's application to play with IoT platform. <br />
        <a href="{{ slackUrl }}">Slack here</a>
      </p>
    </div>
  </mat-card-content>
</mat-card>
