<mat-card-title-group>
  <mat-card-title>Dataset Config</mat-card-title>
  <button mat-icon-button aria-label="close dialog" mat-dialog-close>
    <img src="assets/images/close-icon.png" width="30" height="30" />
  </button>
</mat-card-title-group>

<hr />

<div mat-dialog-content>
  <div class="json-content">
    <b><u>sourceId</u></b> : {{ sourceId }}
    <br />
    <b><u>gpsDataConfig</u></b>
    <pre>
      <code [highlight]="gpsDataConfig | json"></code>
    </pre>
    <b><u>digitalInputConfig</u></b>
    <pre>
      <code [highlight]="digitalInputConfig | json"></code>
    </pre>
    <b><u>accelerometerConfig</u></b>
    <pre>
      <code [highlight]="accelerometerConfig | json"></code>
    </pre>
    <b><u>appConfig</u></b>
    <pre>
      <code [highlight]="appConfig | json"></code>
    </pre>
    <b><u>analyticsConfig</u></b>
    <pre>
      <code [highlight]="analyticsConfig | json"></code>
    </pre>
    <b><u>j1939Configs</u></b>
    <pre>
      <code [highlight]="j1939Configs | json"></code>
    </pre>
    <b><u>j1587Configs</u></b>
    <pre>
      <code [highlight]="j1587Configs | json"></code>
    </pre>
    <b><u>obd2Configs</u></b>
    <pre>
      <code [highlight]="obd2Configs | json"></code>
    </pre>
  </div>
</div>
