import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DeviceSettingsService {
  constructor(private http: HttpClient) {}

  postSchedule(serialNumber, user, action): Observable<any> {
    return this.http.post(
      `${environment.lastRebootAPI}/settings/schedule?serial_number=${serialNumber}&user=${user}&settings=${action}&when=NOW&on_lightup=FALSE`,
      null
    );
  }
}
