import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalVariables } from 'src/app/core/_common/GlobalVariables';
import { DatadogService } from 'src/app/core/_services/datadog.service';

@Component({
  selector: 'app-view-signal-config',
  templateUrl: './view-signal-config.component.html',
  styleUrls: ['./view-signal-config.component.scss']
})
export class ViewSignalConfigComponent implements OnInit {
  signalConfig: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ViewSignalConfigComponent>,
    private datadogService: DatadogService
  ) {}

  ngOnInit(): void {
    this.datadogService.log(
      `${GlobalVariables.USER_EMAIL} viewed Trigger-Signal-Config`,
      {
        name: 'View-Trigger-Signal-Config Component',
        id: 10082,
        userName: GlobalVariables.USER_GIVEN_NAME,
        userEmail: GlobalVariables.USER_EMAIL
      },
      'info'
    );
    this.parseConfigData();
  }

  parseConfigData() {
    this.signalConfig = this.data;
  }
}
