<div class="modal-contents">
  <div (click)="copyData()" class="copy-data">
    <img src="/assets/copy-solid.svg" alt="Copy Transformer Data" />
  </div>

  <pre>
    <code [highlight]="data | json" language="json">
    </code>
</pre>
</div>
