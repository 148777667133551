import { createAction, props } from '@ngrx/store';
import { AccountModel } from '../../../core/_models/AccountModel';

// @ts-ignore
export const addAccount = createAction(
  '[ SET ACCOUNT ]',
  props<{ account: AccountModel[] }>()
);

export const cleanAccount = createAction('[ CLEAN ACCOUNT ]');
