import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject, throwError, timer } from 'rxjs';
import {
  catchError,
  map,
  shareReplay,
  switchMap,
  takeUntil
} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PGNModel } from '../_models/PGNModel';

const REFRESH_INTERVAL = 10000;
const CACHE_SIZE = 1;

export interface PgnResponse {
  type: string;
  value: Array<PGNModel>;
}

@Injectable({
  providedIn: 'root'
})
export class SignalApiService {
  private cache$: Observable<Array<PGNModel>>;
  private reload$ = new Subject<void>();

  constructor(private http: HttpClient) {}

  private requestPgns(params?: any) {
    let queryParams = new HttpParams();
    queryParams = params;

    return this.http
      .get<any>(`${environment.pgnsapi}`, {
        params: queryParams
      })
      .pipe(
        map((response) => response),
        catchError((err) => {
          console.log('error caught in getAllPGNS() service');
          console.error(err);

          return throwError(err); //Rethrow it back to component
        })
      );
  }

  async getAllSPN(params?: any): Promise<Observable<any>> {
    let queryParams = new HttpParams();
    queryParams = params;

    return await this.http
      .get(`${environment.pgnsapi}?per_page=1000`, {
        params: queryParams
      })
      .pipe(
        catchError((err) => {
          console.log('error caught in getAllPGNS() service');
          console.error(err);

          return throwError(err); //Rethrow it back to component
        })
      );
  }

  get allPgns() {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('per_page', '1000');
    if (!this.cache$) {
      const timer$ = timer(0, REFRESH_INTERVAL);

      this.cache$ = timer$.pipe(
        switchMap(() => this.requestPgns(queryParams)),
        takeUntil(this.reload$),
        shareReplay(CACHE_SIZE)
      );
    }

    return this.cache$;
  }

  forceReload() {
    // Calling next will complete the current cache instance
    this.reload$.next();

    // Setting the cache to null will create a new cache the
    // next time 'jokes' is called
    this.cache$ = null;
  }

  async getAllPGNS(params?: any): Promise<Observable<any>> {
    let queryParams = new HttpParams();
    queryParams = params;

    const responses = [];

    const fetchPage = async (page: number): Promise<void> => {
      try {
        const response = await this.http
          .get<HttpResponse<any>>(
            `${environment.pgnsapi}?per_page=1000&page=${page}`,
            {
              params: queryParams,
              observe: 'response'
            }
          )
          .toPromise();

        if (response.body) {
          // @ts-ignore
          responses.push(...response.body);
        }

        const totalPageCount = Number(response.headers.get('x-page-count'));
        console.log(totalPageCount);

        if (totalPageCount > page) {
          return fetchPage(page + 1);
        }
      } catch (err) {
        console.log('error caught in getAllPGNS() service');
        console.error(err);
        throw err;
      }
    };

    await fetchPage(1);

    return of(responses);
  }

  async createPgn(body: any): Promise<Observable<any>> {
    return await this.http.post(`${environment.pgnsapi}`, body).pipe(
      catchError((err) => {
        console.log('error caught in create pgn() service');
        console.error(err);

        return throwError(err); //Rethrow it back to component
      })
    );
  }

  async getPgnByNumber(pgnNumber: Number): Promise<Observable<any>> {
    return await this.http.get(`${environment.pgnsapi}?pgn=${pgnNumber}`).pipe(
      catchError((err) => {
        console.log(
          'Error caught in getting PGN by number in Signal API service'
        );
        console.error(err);
        //Handle the error here
        return throwError(err); //Rethrow it back to component
      })
    );
  }
}
