<mat-card-title-group>
  <mat-card-title>Asset/Device Details</mat-card-title>
  <mat-card-subtitle>
    Asset/Device details for <b>{{ data.asset_name }}</b></mat-card-subtitle
  >
  <button mat-icon-button aria-label="close dialog" mat-dialog-close>
    <img src="assets/images/close-icon.png" width="30" height="30" />
  </button>
</mat-card-title-group>

<hr />

<div class="flex">
  <div>
    <div class="flex entry">
      <div class="bold">Asset ID:</div>
      <div>{{ data.asset_id }}</div>
    </div>
    <div class="flex entry">
      <div class="bold">Asset Name:</div>
      <div>{{ data.asset_name }}</div>
    </div>

    <div class="flex entry">
      <div class="bold">Asset Type:</div>
      <div>{{ data.asset_type }}</div>
    </div>

    <div class="flex entry">
      <div class="bold">VIN Number:</div>
      <div>{{ data.vin_number }}</div>
    </div>

    <div class="flex entry">
      <div class="bold">Make:</div>
      <div>{{ data.make }}</div>
    </div>

    <div class="flex entry">
      <div class="bold">Model:</div>
      <div>{{ data.model }}</div>
    </div>

    <div class="flex entry">
      <div class="bold">Model Year:</div>
      <div>{{ data.modelYear }}</div>
    </div>
  </div>

  <div style="margin-left: 2em">
    <div class="flex entry">
      <div class="bold">Device ID:</div>
      <div>{{ data.id }}</div>
    </div>

    <div class="flex entry">
      <div class="bold">Device Type:</div>
      <div>{{ data.deviceType }}</div>
    </div>

    <div class="flex entry">
      <div class="bold">Manufacturer:</div>
      <div>{{ data.manufacturer }}</div>
    </div>

    <div class="flex entry">
      <div class="bold">IMEI:</div>
      <div>{{ data.imei }}</div>
    </div>

    <div class="flex entry">
      <div class="bold">ICCID:</div>
      <div>{{ data.iccid }}</div>
    </div>

    <div class="flex entry">
      <div class="bold">Firmware:</div>
      <div>{{ data.firmware_version }}</div>
    </div>

    <div class="flex entry">
      <div class="bold">Serial Number:</div>
      <div>{{ data.serial_number }}</div>
    </div>
  </div>
</div>
