<mat-card>
  <mat-card-title>Device Administration</mat-card-title>
  <div id="top-row">
    <form>
      <mat-form-field appearance="standard">
        <mat-label>Device Serial Number</mat-label>
        <input
          matInput
          placeholder="Placeholder"
          [formControl]="serialNumber"
        />
        <mat-icon matSuffix>search</mat-icon>
        <mat-hint>Hint</mat-hint>
      </mat-form-field>
      <button mat-raised-button (click)="getDetails()">Search</button>
    </form>
  </div>

  <app-admin-details
    [title]="'Device Details'"
    [isDeviceLoading]="isDeviceLoading"
    [data]="dataDevice"
  >
  </app-admin-details>

  <app-admin-details
    [title]="'Company Details'"
    [isCompanyLoading]="isCompanyLoading"
    [data]="dataCompany"
  >
  </app-admin-details>

  <app-admin-details
    [title]="'Asset Details'"
    [isAssetLoading]="isAssetLoading"
    [data]="dataAssets"
  >
  </app-admin-details>

  <app-admin-details
    [title]="'IoT Config Details'"
    [isIOTLoading]="isIOTLoading"
    [data]="dataIOT"
  >
  </app-admin-details>
</mat-card>
