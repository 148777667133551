<div class="container" *ngIf="pageloading">
  <app-loading></app-loading>
</div>
<div class="container">
  <div class="row">
    <div class="col-md-6 my-5">
      <mat-card *ngIf="!pageloading">
        <mat-card-content>
          <form [formGroup]="selectAccountForm">
            <mat-form-field class="example-full-width" appearance="fill">
              <mat-label>Customer: </mat-label>
              <input
                type="text"
                placeholder="Select Customer"
                aria-label="Company Name"
                matInput
                formControlName="companyControl"
                name="companyControl"
                [matAutocomplete]="company"
                required
              />
              <mat-autocomplete #company="matAutocomplete">
                <mat-option
                  *ngFor="let company of filteredCompanies | async"
                  [value]="company.companyName"
                  (click)="onCustomerSelect(company)"
                >
                  {{ company.companyName }}
                </mat-option>
              </mat-autocomplete>
              <mat-error
                *ngIf="
                  selectAccountForm.get('companyControl').hasError('required')
                "
                >Please select Customer</mat-error
              >
            </mat-form-field>

            <mat-form-field class="example-full-width" appearance="fill">
              <mat-label>Account Code: </mat-label>
              <input
                type="text"
                placeholder="Select Account Code"
                aria-label="Account Code"
                matInput
                formControlName="accountCodeControl"
                name="accountCodeControl"
                [matAutocomplete]="account"
                [disabled]="selectAccountForm.get('companyControl').valid"
                required
              />
              <mat-autocomplete #account="matAutocomplete">
                <mat-option
                  *ngFor="let accountCode of filteredAccountCodes | async"
                  [value]="accountCode"
                  (click)="onAccountSelect(accountCode)"
                >
                  {{ accountCode }}
                </mat-option>
              </mat-autocomplete>
              <mat-hint *ngIf="multipleAccountCheck"
                >Multiple account codes available for this customer</mat-hint
              >
              <!--   <mat-error
                *ngIf="
                  selectAccountForm
                    .get('accountCodeControl')
                    .hasError('required')
                "
                >Please select Account Code</mat-error> -->
            </mat-form-field>
          </form>

          <mat-list>
            <mat-list-item>
              <b>Company Name: </b>&nbsp;{{ companyName }}
            </mat-list-item>
            <mat-list-item>
              <b>Account Code: </b>&nbsp;{{ accountCode }}
            </mat-list-item>
          </mat-list>
        </mat-card-content>
        <mat-card-actions align="center">
          <button
            mat-raised-button
            [disabled]="!selectAccountForm.valid"
            (click)="navigateApp()"
          >
            Continue
          </button>
        </mat-card-actions>
      </mat-card>
    </div>
    <div class="col-md-6 my-5">
      <mat-card *ngIf="!pageloading">
        <mat-card-header>
          <mat-card-title></mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <span style="text-align: center; font-size: x-large"
            >Whitelist Companies</span
          >
          <mat-list>
            <mat-list-item *ngFor="let customers of iotActiveCompanies">
              <a mat-button (click)="setAccount(customers)"
                >{{ customers.companyName }}
              </a>
              <mat-divider></mat-divider>
            </mat-list-item>
          </mat-list>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
