<mat-card-title-group>
  <mat-card-title>{{ functionType }} PGN</mat-card-title>
  <button mat-icon-button aria-label="close dialog" mat-dialog-close>
    <img src="assets/images/close-icon.png" width="30" height="30" />
  </button>
</mat-card-title-group>

<mat-divider></mat-divider>
<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

<ng-container [ngSwitch]="stepperOrientation | async">
  <div *ngSwitchCase="'horizontal'"></div>
  <div *ngSwitchCase="'vertical'"></div>
</ng-container>
<mat-stepper
  class="example-stepper"
  [orientation]="(stepperOrientation | async)!"
>
  <mat-step [stepControl]="pgnFormGroup" label="PGN details">
    <form [formGroup]="pgnFormGroup">
      <div style="display: table; width: 100%">
        <div style="display: table-row">
          <div style="display: table-cell; vertical-align: top">
            <div>
              <mat-form-field appearance="standard">
                <mat-label>Signal Type</mat-label>
                <mat-select
                  formControlName="signalTypeFormControl"
                  name="signalTypeFormControl"
                >
                  <mat-option
                    *ngFor="let type of signalTypeList"
                    value="{{ type }}"
                    >{{ type }}</mat-option
                  >
                </mat-select>
                <mat-error
                  *ngIf="
                    pgnFormGroup
                      .get('signalTypeFormControl')
                      .hasError('required')
                  "
                  >Signal Type is required</mat-error
                >
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="standard">
                <mat-label>PGN: </mat-label>
                <input
                  matInput
                  placeholder="300"
                  formControlName="pgnFormControl"
                  name="pgnFormControl"
                />
                <mat-error
                  *ngIf="
                    pgnFormGroup.get('pgnFormControl').hasError('required')
                  "
                  >PGN is required</mat-error
                >
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="standard">
                <mat-label>Label: </mat-label>
                <input
                  matInput
                  placeholder="Brake Request"
                  formControlName="labelFormControl"
                  name="labelFormControl"
                />
                <mat-error
                  *ngIf="
                    pgnFormGroup.get('labelFormControl').hasError('required')
                  "
                  >Label is required</mat-error
                >
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="standard">
                <mat-label>Description</mat-label>
                <textarea
                  matInput
                  formControlName="descriptionFormControl"
                  name="descriptionFormControl"
                ></textarea>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="standard">
                <mat-label>Provider ID: </mat-label>
                <input
                  matInput
                  placeholder="3fa85f64-5717-4562-b3fc-2c963f66afa6"
                  formControlName="providerFormControl"
                  name="providerFormControl"
                />
              </mat-form-field>
            </div>
          </div>
          <div style="display: table-cell; vertical-align: top">
            <div>
              <mat-form-field appearance="standard">
                <mat-label>Variable SPN Delimiter: </mat-label>
                <input
                  matInput
                  placeholder=""
                  formControlName="variableSpnDelimiterFormControl"
                  name="variableSpnDelimiterFormControl"
                />
              </mat-form-field>
            </div>

            <div>
              <mat-form-field appearance="standard">
                <mat-label>Variable SPN Start: </mat-label>
                <input
                  matInput
                  placeholder="0"
                  formControlName="variableSpnStartFormControl"
                  name="variableSpnStartFormControl"
                />
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="standard">
                <mat-label>Variable Source: </mat-label>
                <input
                  matInput
                  placeholder="J1939DA AUG2021.xlsx"
                  formControlName="versionSourceFormControl"
                  name="versionSourceFormControl"
                />
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </form>
    <mat-divider></mat-divider>
    <mat-dialog-actions align="end">
      <button id="refresh-button" mat-button matStepperNext>Next</button>
    </mat-dialog-actions>
  </mat-step>

  <mat-step
    [stepControl]="spnFormGroup"
    style="margin-right: 200px"
    label="SPN List"
  >
    <form [formGroup]="spnFormGroup">
      <div style="display: table; width: 100%">
        <div style="display: table-row">
          <div style="display: table-cell; width: 20%; vertical-align: top">
            <div>
              <mat-form-field appearance="standard">
                <mat-label>SPN Type</mat-label>
                <mat-select
                  formControlName="spnTypeFormControl"
                  name="spnTypeFormControl"
                >
                  <mat-option
                    *ngFor="let type of spnTypeList"
                    value="{{ type }}"
                    >{{ type }}</mat-option
                  >
                </mat-select>
                <mat-error
                  *ngIf="
                    spnFormGroup.get('spnTypeFormControl').hasError('required')
                  "
                  >SPN Type is required</mat-error
                >
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="standard">
                <mat-label>Sequence: </mat-label>
                <input
                  matInput
                  placeholder="300"
                  formControlName="sequenceFormControl"
                  name="sequenceFormControl"
                />
                <mat-error
                  *ngIf="
                    spnFormGroup.get('sequenceFormControl').hasError('required')
                  "
                  >Sequence is required</mat-error
                >
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="standard">
                <mat-label>SPN: </mat-label>
                <input
                  matInput
                  placeholder="3930"
                  formControlName="spnFormControl"
                  name="spnFormControl"
                />
                <mat-error
                  *ngIf="
                    spnFormGroup.get('spnFormControl').hasError('required')
                  "
                  >SPN is required</mat-error
                >
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="standard">
                <mat-label>Label: </mat-label>
                <input
                  matInput
                  placeholder="Valve Load Sense Pressure"
                  formControlName="labelFormControl"
                  name="labelFormControl"
                />
                <mat-error
                  *ngIf="
                    spnFormGroup.get('labelFormControl').hasError('required')
                  "
                  >Label is required</mat-error
                >
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="standard">
                <mat-label>Description</mat-label>
                <textarea
                  matInput
                  formControlName="descriptionFormControl"
                  name="descriptionFormControl"
                ></textarea>
              </mat-form-field>
            </div>
          </div>
          <div style="display: table-cell; width: 20%; vertical-align: top">
            <div>
              <mat-form-field appearance="standard">
                <mat-label>Start: </mat-label>
                <input
                  matInput
                  placeholder="0"
                  formControlName="startFormControl"
                  name="startFormControl"
                />
              </mat-form-field>
            </div>

            <div>
              <mat-form-field appearance="standard">
                <mat-label>Length: </mat-label>
                <input
                  matInput
                  placeholder="16"
                  formControlName="lengthFormControl"
                  name="lengthFormControl"
                />
              </mat-form-field>
            </div>

            <div>
              <mat-form-field appearance="standard">
                <mat-label>Units: </mat-label>
                <input
                  matInput
                  placeholder="kPa"
                  formControlName="unitsFormControl"
                  name="unitsFormControl"
                />
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="standard">
                <mat-label>Resolution: </mat-label>
                <input
                  matInput
                  placeholder="5"
                  formControlName="resolutionFormControl"
                  name="resolutionFormControl"
                />
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="standard">
                <mat-label>Offset: </mat-label>
                <input
                  matInput
                  placeholder="0"
                  formControlName="offsetFormControl"
                  name="offsetFormControl"
                />
              </mat-form-field>
            </div>
            <button
              mat-stroked-button
              class="icon-button-small"
              [disabled]="!spnFormGroup.valid"
              color="primary"
              (click)="addSpn()"
            >
              <mat-icon>add</mat-icon>
              Add SPN
            </button>
          </div>
          <div
            style="
              display: table-cell;
              vertical-align: top;
              align-items: center;
              background-color: #f4f6f6;
            "
          >
            <table
              style="
                padding: 20px;
                display: block;
                height: 350px;
                width: 100%;
                border-collapse: collapse;
                overflow-y: scroll;
              "
            >
              <tr style="background-color: white; padding: 10px">
                <th>Sequence</th>
                <th>Spn</th>
                <th>Type</th>
                <th>Label</th>
                <th>Action</th>
              </tr>
              <tr *ngFor="let spn of spnList">
                <td>{{ spn.sequence }}</td>
                <td>{{ spn.spn }}</td>
                <td>{{ spn.type }}</td>
                <td>{{ spn.label }}</td>
                <td>
                  <button
                    mat-icon-button
                    color="accent"
                    aria-label="Delete Spn"
                    (click)="deleteSpn(spn)"
                  >
                    <mat-icon class="mat-icon-color">delete</mat-icon>
                  </button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </form>
    <mat-divider></mat-divider>
    <mat-dialog-actions align="end">
      <button id="refresh-button" mat-button matStepperPrevious>Back</button>
      <button
        mat-stroked-button
        color="primary"
        type="submit"
        [disabled]="!pgnFormGroup.valid"
        (click)="createPgn()"
      >
        {{ buttonLabel }}
      </button>
      <button id="refresh-button" mat-stroked-button mat-dialog-close>
        Cancel
      </button>
    </mat-dialog-actions>
  </mat-step>
</mat-stepper>
