<mat-card-title-group>
  <mat-card-title>View Config</mat-card-title>
  <mat-card-subtitle>
    JSON Config for <b>{{ configLabel }}</b></mat-card-subtitle
  >
  <button mat-icon-button aria-label="close dialog" mat-dialog-close>
    <img src="assets/images/close-icon.png" width="30" height="30" />
  </button>
</mat-card-title-group>

<hr />

<div class="config-display">
  <pre *ngIf="configData.length > 0">
    <code [highlight]="configData | json"></code>
  </pre>
</div>
