<div class="container">
  <mat-tab-group>
    <mat-tab label="Search for Non IOT Whitelisted Companies">
      <div class="container-item">
        <div class="search-container">
          <div>
            <h3>Search by Name or Account Code</h3>

            <div class="user-actions">
              <div>
                <input
                  [(ngModel)]="searchQuery"
                  (ngModelChange)="searchInputChange()"
                />
              </div>

              <div class="add-button">
                <button
                  mat-stroked-button
                  color="primary"
                  [disabled]="checkedResults.length <= 0"
                  (click)="applyAllWhitelistAdditions()"
                >
                  Add all selected to whitelist
                </button>
              </div>
            </div>
          </div>
        </div>

        <div>
          <h2 class="results">
            Search Results:
            <span *ngIf="searchResults.length > 0"
              >{{ searchResults.length }} results</span
            >
          </h2>

          <div class="search-results-container">
            <div *ngFor="let result of searchResults" class="search-result">
              <div
                class="result"
                *ngIf="
                  (result.companyId && result.companyId.length > 0) ||
                  (result.id && result.id.length > 0)
                "
              >
                <div class="action-button">
                  <input
                    type="checkbox"
                    (change)="addOrRemoveCompanyToWhitelist($event, result)"
                  />
                </div>

                <div class="name">
                  {{ result.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="View IOT Whitelisted Companies">
      <div class="container-item" *ngIf="dataSource.length > 0">
        <mat-card-content>
          <span style="text-align: center; font-size: x-large"
            >IOT Whitelist</span
          >
          <div class="app-table-container">
            <zui-table
              [columns]="columns$"
              [columnStyleConfig]="columnStyles"
              [dataSource]="dataSource"
              initialPageSize="10"
            >
            </zui-table>
          </div>
        </mat-card-content>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
