import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'zonar-signal-trigger-mapping-transformer-view',
  templateUrl: './signal-trigger-mapping-transformer-view.component.html',
  styleUrls: ['./signal-trigger-mapping-transformer-view.component.scss']
})
export class SignalTriggerMappingTransformerViewComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {}

  public copyData(): void {
    navigator.clipboard.writeText(JSON.stringify(this.data));
  }
}
