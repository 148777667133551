<!-- <zui-header logoTitle="IoT Device Management" [openDialogOnPageLoad]="true"
    (emitSelectedCompany)="myOwnMethodToGrabCompany($event)"></zui-header> -->

<app-nav-bar></app-nav-bar>
<app-home *ngIf="showHome"></app-home>
<section>
  <router-outlet></router-outlet>

  <div class="news-alerts" *ngIf="displayMessage">
    {{ newsMessage }}
  </div>
</section>
<zui-footer class="mt-auto"></zui-footer>
