import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GlobalVariables } from 'src/app/core/_common/GlobalVariables';
import { DatadogService } from 'src/app/core/_services/datadog.service';

@Component({
  selector: 'app-view-config-dialog',
  templateUrl: './view-config-dialog.component.html',
  styleUrls: ['./view-config-dialog.component.scss']
})
export class ViewConfigDialogComponent implements OnInit {
  base64: any;
  configData: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ViewConfigDialogComponent>,
    private datadogService: DatadogService
  ) {}

  ngOnInit(): void {
    this.datadogService.log(
      `${GlobalVariables.USER_EMAIL} viewed Package-Config`,
      {
        name: 'View-Package-Config Component',
        id: 10062,
        userName: GlobalVariables.USER_GIVEN_NAME,
        userEmail: GlobalVariables.USER_EMAIL
      },
      'info'
    );
    this.parseConfigData();
  }

  parseConfigData() {
    this.configData = this.data.jsonConfig;
    this.base64 = this.data.base64;
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
