import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { TriggerModel } from '../_models/TriggerModel';

@Injectable({
  providedIn: 'root'
})
export class TriggerApiService {
  constructor(private http: HttpClient) {}

  async getAllTriggers(): Promise<Observable<any>> {
    return await this.http.get(`${environment.triggerapi}/triggers`).pipe(
      catchError((err) => {
        console.log('Error caught in getting all Trigger API service');
        console.error(err);
        //Handle the error here
        return throwError(err); //Rethrow it back to component
      })
    );
  }

  getTriggerById(triggerId: string): Observable<TriggerModel> {
    let url = `${environment.triggerapi}/triggers/${triggerId}`;
    return this.http.get<TriggerModel>(url);
  }

  createTrigger(body: any): Observable<any> {
    let url = `${environment.triggerapi}/triggers`;
    return this.http.post(url, body);
  }

  /*   updateTrigger(body: any, triggerID: string): Observable<any> {
    let url = `${environment.triggerapi}/triggers/${triggerID}`;
    return this.http.patch(url, body);

  } */

  async updateTrigger(body: any, triggerID: string): Promise<Observable<any>> {
    return await this.http
      .patch(`${environment.triggerapi}/triggers/${triggerID}`, body)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((err) => {
          console.log('error caught in update trigger() service');
          console.error(err);

          return throwError(err); //Rethrow it back to component
        })
      );
  }
}
