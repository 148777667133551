import { environment } from '../../../environments/environment';
export const PACKAGE_CONFIG_URL = `${environment.packageapi}`;
export const PUBLISH_CONFIG_URL = '/account/publish-config';
export const DEVICE_SETTINGS_URL = '/account/device-settings';
export const ASSET_API_URL = `${environment.serviceapi}/assets`;
export const DEVICE_API_URL = `${environment.serviceapi}/devices`;

export const QUERY_PARAM_PER_PAGE = 'per_page';
export const QUERY_PARAM_PER_PAGE_VALUE = '500';

export const DEVICE_DIALOG_HEIGHT = '600px';
export const DEVICE_DIALOG_WIDTH = '60%';

export const DEVICE_SETTINGS_HEIGHT = '550px';
export const DEVICE_SETTINGS_WIDTH = '80%';

export const PUBLISH_CONFIG_HEIGHT = '600px';
export const PUBLISH_CONFIG_WIDTH = '80%';

export const VIEW_CONFIG_WIDTH = '800px';
export const VIEW_CONFIG_HEIGHT = '600px';

export const CUSTOMER_NAME_KEY = 'CUSTOMER_NAME';
export const CUSTOMER_ACCOUNT_CODE_KEY = 'CUSTOMER_ACCOUNT_CODE';

export const DEVICE_CONFIG_MAPPING_KEY = 'DEVICE_CONFIG_MAPPING';
export const ASSET_CONFIG_MAPPING_KEY = 'ASSET_CONFIG_MAPPING';

export const DEVICE_SETTINGS_COLUMNS: string[] = [
  'checked',
  'account_code',
  'asset_name',
  'serial_number',
  'actions',
  'last_reboot'
];
