<mat-card-title-group>
  <mat-card-title>{{ functionType }} Package Config</mat-card-title>
  <mat-card-subtitle
    >Form to {{ functionType }} Package Config</mat-card-subtitle
  >
  <button mat-icon-button aria-label="close dialog" mat-dialog-close>
    <img src="assets/images/close-icon.png" width="30" height="30" />
  </button>
</mat-card-title-group>
<hr />

<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
<mat-dialog-content>
  <form class="container">
    <div style="display: table">
      <div style="display: table-row">
        <div class="table-cell; align-items: center;">
          <div>
            <mat-form-field appearance="standard">
              <mat-label>Package Name: </mat-label>
              <input
                matInput
                placeholder="package name"
                [formControl]="configNameFormControl"
              />
              <mat-hint>Valid package config name</mat-hint>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field appearance="standard">
              <mat-label>Your email</mat-label>
              <input
                matInput
                [disabled]="true"
                placeholder="pat@example.com"
                [formControl]="emailFormControl"
                required
              />
            </mat-form-field>
          </div>
          <div>
            <mat-form-field appearance="standard">
              <mat-label>Description</mat-label>
              <textarea
                matInput
                [formControl]="descriptionFormControl"
              ></textarea>
            </mat-form-field>
          </div>
          <div>
            <mat-form-field appearance="fill">
              <mat-label>Datasets</mat-label>
              <mat-select
                [formControl]="datasetId"
                multiple
                (selectionChange)="changeDatasetEvent($event)"
              >
                <mat-option
                  *ngFor="let dataset of datasetList"
                  [value]="dataset.id"
                  >{{ dataset.name }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <!-- <mat-hint><a mat-button href="dataset" target="_blank">Create New</a></mat-hint> -->
          </div>

          <div>
            <mat-form-field appearance="fill">
              <mat-label>Triggers</mat-label>
              <mat-select
                [formControl]="triggerId"
                multiple
                (selectionChange)="changeTriggerEvent($event)"
              >
                <mat-option
                  *ngFor="let trigger of triggerList"
                  [value]="trigger.id"
                  >{{ trigger.name }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <!-- <mat-hint><a mat-button href="trigger" target="_blank">Create New</a></mat-hint> -->
          </div>

          <div>
            <mat-form-field appearance="standard">
              <mat-label>Select Status</mat-label>
              <mat-select [formControl]="statusFormControl">
                <mat-option value="READY_FOR_CUSTOMER"
                  >Ready For Customer</mat-option
                >
                <mat-option value="PENDING">Pending</mat-option>
                <mat-option value="DEMO_ONLY">Demo Only</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div style="display: table-cell; align-items: center">
          <mat-form-field appearance="standard">
            <mat-label>Selected Datasets</mat-label>
            <mat-select [formControl]="selectedDatasetFormControl">
              <mat-option
                *ngFor="let dataset of selectedDataset"
                [value]="dataset.id"
                (click)="onSelectedDataset(dataset)"
                >{{ dataset.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <textarea
            matInput
            rows="15"
            cols="150"
            style="font-size: medium; border: 1"
            [disabled]="true"
          >
       {{ datasetConfigView }}
       </textarea
          >
        </div>
        <div style="display: table-cell; align-items: center">
          <mat-form-field appearance="standard">
            <mat-label>Selected Triggers</mat-label>
            <mat-select [formControl]="selectedTriggerFormControl">
              <mat-option
                *ngFor="let trigger of selectedTrigger"
                [value]="trigger.id"
                (click)="onSelectedTrigger(trigger)"
                >{{ trigger.name }}</mat-option
              >
            </mat-select>
          </mat-form-field>
          <textarea
            matInput
            rows="15"
            cols="150"
            style="font-size: medium; border: 1"
            [disabled]="true"
          >
       {{ triggerConfigView }}
       </textarea
          >
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    mat-stroked-button
    (click)="createOrClonePackageConfig()"
    cdkFocusInitial
  >
    {{ buttonLabel }}
  </button>
  <button id="refresh-button" mat-stroked-button mat-dialog-close>
    Cancel
  </button>
</mat-dialog-actions>
