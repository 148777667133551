<mat-toolbar color="primary">
  <div class="navbar-brand logo"></div>
  <ng-container *ngIf="(auth?.isAuthenticated$ | async) === true">
  </ng-container>
  <a href="/" class="header">
    <div id="logo">
      <div>IoT Device Management</div>
    </div>
  </a>

  <span class="menu-spacer"></span>

  <button
    *ngIf="(auth?.isAuthenticated$ | async) === false"
    mat-button
    class="mat-list-button float-end"
    (click)="loginWithRedirect()"
  >
    Login
  </button>
  <button
    *ngIf="auth.user$ | async as user"
    mat-button
    [matMenuTriggerFor]="menu"
    aria-label="Example icon-button with a menu"
    style="color: lightgray"
  >
    {{ companyName }}

    <mat-icon>person</mat-icon>
  </button>

  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="selectAccountClick()">
      <mat-icon>refresh</mat-icon>
      <span>Select Account</span>
    </button>
    <button mat-menu-item routerLink="/profile">
      <mat-icon>person</mat-icon>
      <span>Profile</span>
    </button>
    <!--    <button mat-menu-item routerLink="/admin">
      <mat-icon>view_module</mat-icon>
      <span>Admin</span>
    </button>
    <a mat-menu-item href="https://zonarsystems.atlassian.net/wiki/spaces/DDOS/pages/137657221276/UI+App+User+Guide"
      target="_blank">
      <mat-icon>help</mat-icon>
      <span>User Guide</span>
    </a> -->
    <button mat-menu-item (click)="logout()">
      <mat-icon>arrow_forward_ios</mat-icon>
      <span>Logout</span>
    </button>
  </mat-menu>
</mat-toolbar>

<mat-toolbar *ngIf="(auth.isAuthenticated$ | async) === true" color="warn">
  <ng-container *ngFor="let menuItem of mainMenu">
    <a
      mat-button
      [matMenuTriggerFor]="menuItem.submenu ? subMenu : null"
      [ngClass]="{ 'active-route ': routeIsActive(menuItem.link) }"
      *ngIf="menuItem != undefined"
      [routerLink]="menuItem.link"
    >
      <span>{{ menuItem.menu_name }}</span>
    </a>
  </ng-container>
  <mat-menu #subMenu="matMenu" [overlapTrigger]="false">
    <ng-container *ngFor="let menuItem of mainMenu">
      <ng-container *ngFor="let subItem of menuItem.submenu">
        <a
          mat-menu-item
          [ngClass]="{ 'active-route ': routeIsActive(subItem.link) }"
          [routerLink]="subItem.link"
        >
          <span>{{ subItem.menu_name }}</span></a
        >
      </ng-container>
    </ng-container>
  </mat-menu>
</mat-toolbar>
