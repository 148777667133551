import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { DeviceApiService } from './device-api.service';
import { DeviceModel } from '../_models/DeviceModel';
import { CompanyModel } from '../_models/CompanyModel';
import { map } from 'rxjs/operators';
import { DivisionModel } from '../_models/DivisionModel';

@Injectable({
  providedIn: 'root'
})
export class CompanyApiService {
  deviceList: DeviceModel[] = [];
  companyList: CompanyModel[] = [];

  constructor(
    private http: HttpClient,
    private deviceApiService: DeviceApiService
  ) {}

  getCompanies(companyID?: any): Observable<any> {
    let url = `${environment.serviceapi}/companies`;
    if (companyID) {
      url = `${environment.serviceapi}/companies/${companyID}`;
    }
    return this.http.get<CompanyModel>(url);
  }

  getCompaniesList(): Observable<any> {
    this.deviceApiService.getDevices().subscribe(async (data) => {
      // Removing duplicates from the device list
      this.deviceList = data.filter(
        (thing, index, self) =>
          index === self.findIndex((t) => t.companyId === thing.companyId)
      );
      return this.deviceList;
    });

    return null;
  }

  getCompanyAccountCode(companyID?: any): Observable<any> {
    let url = `${environment.serviceapi}/companies/${companyID}/divisions?status=ACTIVE&page=1&per_page=100`;

    let queryParams = new HttpParams();
    queryParams = queryParams.append('status', 'ACTIVE');
    queryParams = queryParams.append('per_page', '200');

    return this.http
      .get<DivisionModel>(url, { params: queryParams })
      .pipe(map(({ legacyAccountCode }) => legacyAccountCode));
  }
}
