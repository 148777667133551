import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TriggerModel } from 'src/app/core/_models/TriggerModel';
import { TriggerApiService } from 'src/app/core/_services/trigger-api.service';
import { CreateTriggerComponent } from './create-trigger/create-trigger.component';
import {
  CREATE_TRIGGER_DIALOG_HEIGHT,
  CREATE_TRIGGER_DIALOG_WIDTH,
  DISPLAYED_COLUMNS,
  SIGNAL_DIALOG_HEIGHT,
  SIGNAL_DIALOG_WIDTH
} from './constants';
import { DatasetApiService } from 'src/app/core/_services/dataset-api.service';
import { DatasetModel } from 'src/app/core/_models/DatasetModel';
import { ViewSignalConfigComponent } from './view-signal-config/view-signal-config.component';
import { PGNModel } from 'src/app/core/_models/PGNModel';
import { HttpParams } from '@angular/common/http';
import Swal from 'sweetalert2';
import { clear, get, set } from 'idb-keyval';
import { MatSort } from '@angular/material/sort';
import { GlobalVariables } from 'src/app/core/_common/GlobalVariables';
import { DatadogService } from 'src/app/core/_services/datadog.service';
import { CsvExportService } from '../../csv-export.service';

@Component({
  selector: 'app-trigger',
  templateUrl: './trigger.component.html',
  styleUrls: ['./trigger.component.scss']
})
export class TriggerComponent implements OnInit, AfterViewInit {
  tableLoading: boolean = false;
  displayedColumns: string[] = DISPLAYED_COLUMNS;
  allTriggers: TriggerModel[];
  dataSource;
  allDatasets: DatasetModel[];
  allPgns: any[];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private triggerApiService: TriggerApiService,
    private datasetApiService: DatasetApiService,
    public dialog: MatDialog,
    private datadogService: DatadogService,
    private csvExport: CsvExportService
  ) {
    this.getAllTriggers();
    this.getAllDataset();
  }

  ngOnInit(): void {
    this.datadogService.log(
      `${GlobalVariables.USER_EMAIL} accessed Trigger Page`,
      {
        name: 'Trigger Component',
        id: 1008,
        userName: GlobalVariables.USER_GIVEN_NAME,
        userEmail: GlobalVariables.USER_EMAIL
      },
      'info'
    );
  }

  ngAfterViewInit() {
    get('TRIGGER_LIST_KEY').then((triggerModel: TriggerModel[]) => {
      /*
      if (triggerModel) {
        console.log('Found cached data');
        this.loadTable(triggerModel);
      }
      */
    });
    this.getAllTriggers();
  }

  private loadTable(triggerModel: TriggerModel[]): void {
    triggerModel = triggerModel.sort((a: any, b: any) => {
      return a.name - b.name;
    });
    this.dataSource = new MatTableDataSource(triggerModel);
    this.paginator.pageSize = 10;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.tableLoading = false;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  async getAllTriggers() {
    this.tableLoading = true;
    await (
      await this.triggerApiService.getAllTriggers()
    )
      .toPromise()
      .then((triggerList: TriggerModel[]) => {
        this.loadTable(triggerList);
        set('TRIGGER_LIST_KEY', triggerList).then(() => {
          console.log(
            'Trigger List stored in cache with the key "TRIGGER_LIST_KEY"'
          );
        });
        this.tableLoading = false;
      })
      .catch((error) => {
        //this.userMessage = 'There is problem getting list of triggers. Please try later!';
      });
  }

  async getAllDataset() {
    (await this.datasetApiService.getAllDatasets()).subscribe(
      (data: DatasetModel[]) => {
        this.allDatasets = data;
      },
      (error) => {
        //this.userMessage = `There is problem getting list of Dataset. Please try later!<br/>Error: ${error}`;
      }
    );
  }

  viewSignalConfig(signalConfig: any) {
    this.dialog.open(ViewSignalConfigComponent, {
      data: signalConfig,
      height: SIGNAL_DIALOG_HEIGHT,
      width: SIGNAL_DIALOG_WIDTH
    });
  }

  actionTrigger(functionType: any, data?: any) {
    this.datadogService.log(
      `${GlobalVariables.USER_EMAIL} clicked "Create/Clone" button in "Trigger" Page`,
      {
        name: 'Create/Clone - Trigger Component',
        id: 10082,
        userName: GlobalVariables.USER_GIVEN_NAME,
        userEmail: GlobalVariables.USER_EMAIL
      },
      'info'
    );
    if (data == undefined) [(data = {})];
    let input = {
      functionType: functionType,
      dataset: this.allDatasets,
      data: data
    };
    const dialogRef = this.dialog.open(CreateTriggerComponent, {
      data: input,
      height: CREATE_TRIGGER_DIALOG_HEIGHT,
      width: CREATE_TRIGGER_DIALOG_WIDTH
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response.success) {
        this.successfulMessage('Trigger created successfully!', 'Create');
        this.getAllTriggers();
      }
    });
  }

  errorHandler(error, actionType) {
    Swal.fire({
      icon: 'error',
      title: actionType + ' Trigger Error',
      text: error
    });
  }

  successfulMessage(message, actionType) {
    Swal.fire({
      icon: 'success',
      title: actionType + ' Trigger Success!',
      text: message
    });
  }

  reloadTrigger() {
    this.datadogService.log(
      `${GlobalVariables.USER_EMAIL} clicked "Refresh Data" button in "Trigger" Page`,
      {
        name: 'Refresh button - Trigger Component',
        id: 10081,
        userName: GlobalVariables.USER_GIVEN_NAME,
        userEmail: GlobalVariables.USER_EMAIL
      },
      'info'
    );
    this.getAllTriggers();
  }

  public exportCSV(data: any): void {
    this.csvExport.exportCSV(data.filteredData);
  }
}
