<zonar-table-dialog
  [title]="title"
  [subtitle]="subtitle"
  [loader]="publishLoading"
  [columns]="columns"
  [action]="action"
  [source]="dialogData"
  (actionSelected)="actionSelected($event)"
>
</zonar-table-dialog>
