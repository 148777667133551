import { Injectable, OnDestroy } from '@angular/core';
import { DivisionModel } from '../_models/DivisionModel';
import { DeviceProvisioningService } from './device-provisioning.service';
import Swal from 'sweetalert2';
import { bufferCount, concatMap, map } from 'rxjs/operators';
import { forkJoin, from, Subscription } from 'rxjs';
import { get, set } from 'idb-keyval';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.state';
import * as actions from '../../shared/store/divisions-assets-devices.actions';
import { DataSourceMappedService } from './data-source-mapped.service';
import * as ui from '../../shared/store/loader.actions';
import { Element } from '../_models/Element';
import { AssetModel } from '../_models/AssetModel';

@Injectable({
  providedIn: 'root'
})
export class DivisionsAssetsDevicesService implements OnDestroy {
  accountCode = '';
  companyId = '';
  tempArray = [];
  devicesTempArray: string[] = [];
  assetsTempArray = [];
  assetsMergedArray = [];
  assetsTemp = {};
  divisionsTemp = {};
  divisionsArray: DivisionModel[] = [];
  assets = [];
  devices = [];
  tempResultDevices = [];
  finalResultDevices = [];
  tempResultAssets = [];
  finalResultAssets = [];
  iterations = 0;
  arrayLength = 0;
  divisionsCounter = 0;
  element: Element;
  devicesCounter = 0;
  divisionsByAccountCodeSub: Subscription;
  assetsByCompanyAndDivisionSub: Subscription;
  devicesByAssetSub: Subscription;
  accountSub: Subscription;
  chunks;
  page = 0;
  result;
  auxArray = [];

  constructor(
    private deviceProvisioningService: DeviceProvisioningService,
    private store: Store<AppState>,
    private dataSourceMappedService: DataSourceMappedService
  ) {
    this.getAccountCompany();
  }

  ngOnDestroy() {
    this.divisionsByAccountCodeSub?.unsubscribe();
    this.assetsByCompanyAndDivisionSub?.unsubscribe();
    this.devicesByAssetSub?.unsubscribe();
    this.accountSub?.unsubscribe();
  }

  /**
   * getAccountCompany()
   * retrieve the Account and the Company
   */
  getAccountCompany() {
    this.accountSub = this.store.select('account').subscribe((res) => {
      if (res.account?.length > 0) {
        this.accountCode = res.account[0]['account'];
        this.companyId = res.account[0]['company'];
      }
    });
  }

  /**
   * getDeviceList()
   * triggers divisionsByAccountCode()
   */
  getDeviceList() {
    this.divisionsByAccountCode();
    this.store.dispatch(ui.isLoading());
  }

  /**
   * divisionsByAccountCode()
   * we make the first API call using Device Provisioning Service
   * Account Code is needed as parameter
   * getDivisionsByAccountCode is going to return an array of divisions
   * once divisions is returned this.divisionsArray is being populated
   * depending on the totalCount iterations is calculated then
   * we make as much n-api calls as much number of iterations.
   * currently initial items per page is set as 1000 records
   * if we have more than 1000 records (1 iteration by default) we call
   * this.isThereAreMoreDivisions() otherwise we just pass this.divisionsArray
   * to this.assetsByCompanyAndDivision()
   * Finally this.divisionsArray is emitted
   */
  divisionsByAccountCode() {
    this.deviceProvisioningService
      .getDivisionsByAccountCode(this.accountCode, 1)
      .subscribe(
        (divisions: any) => {
          if (divisions.body.length !== 0) {
            divisions.body.forEach((item) => {
              this.divisionsArray.push(item);
            });
          }
          this.iterations = divisions.headers.get('X-Page-Count');
          if (this.iterations > 1) {
            this.isThereAreMoreDivisions();
          } else {
            this.assetsByCompanyAndDivision(this.divisionsArray);
          }
        },
        () => {
          this.errorHandler(
            'There is a problem in Divisions By Account Function',
            'Error retrieving the Divisions'
          );
        },
        () => {
          this.store.dispatch(
            actions.addDivisions({ divisions: this.divisionsArray })
          );
          this.divisionsArray = [];
        }
      );
  }

  /**
   * isThereAreMoreDivisions()
   * we return more divisions in case we have more than 1000 records
   * once we get it we pass the new array to this.assetsByCompanyAndDivision
   */
  isThereAreMoreDivisions() {
    for (let i = 2; i <= this.iterations; i++) {
      this.divisionsByAccountCodeSub = this.deviceProvisioningService
        .getDivisionsByAccountCode(this.accountCode, i)
        .subscribe(
          (moreDivisions: any) => {
            if (moreDivisions.body.length !== 0) {
              moreDivisions.body.forEach((item) => {
                this.divisionsArray.push(item);
              });
            }
            this.assetsByCompanyAndDivision(this.divisionsArray);
          },
          () => {
            this.errorHandler(
              'There is a problem in is ThereAre More Divisions Function',
              'Error retrieving the Divisions'
            );
          },
          () => {
            this.store.dispatch(
              actions.addDivisions({ divisions: this.divisionsArray })
            );
            this.divisionsArray = [];
          }
        );
    }
  }

  /**
   * assetsByCompanyAndDivision()
   * @param divisions
   * once we get the divisions array we loop through it to get the division.id
   * then we make the second API call using getAssetsByCompanyAndDivision passing
   * the company id and the division id then assets array is going to be returned
   * if we have more than 1000 pages then it triggers getSingleAssetsByCompanyAndDivision()
   * otherwise it triggers getMultipleAssetsByCompanyAndDivision()
   * finally Assets are stored in the Cache
   */

  assetsByCompanyAndDivision(divisions: DivisionModel[]) {
    let auxDivisionTempArray = [];
    let auxDivisionTempArray2 = [];
    for (let i = 0; i < divisions.length; i++) {
      this.tempArray.push(divisions[i].id);
    }
    const arrayOfData = [];
    for (let i = 0; i < this.tempArray.length; i++) {
      arrayOfData.push(
        this.deviceProvisioningService.getAssetsByCompanyAndDivision(
          this.companyId,
          this.tempArray[i],
          1
        )
      );
    }
    this.assetsByCompanyAndDivisionSub = forkJoin(arrayOfData).subscribe(
      (assets: any) => {
        this.tempArray.forEach((i, index) => {
          this.divisionsTemp = {
            divisionsId: i,
            id: index
          };
          auxDivisionTempArray2.push(this.divisionsTemp);
        });

        assets.forEach((item, index) => {
          this.assetsTemp = {
            id: index,
            companyId: this.companyId,
            page: parseInt(item.headers.get('X-Page-Count'))
          };
          auxDivisionTempArray.push(this.assetsTemp);
        });
        this.assetsMergedArray = auxDivisionTempArray.map((item) => {
          const matchedObject = auxDivisionTempArray2.find(
            (obj) => obj.id === item.id
          );
          return { ...item, ...matchedObject };
        });
        this.result = this.assetsMergedArray.some((item) => {
          return item.page > 1;
        });
      },
      () => {
        this.errorHandler(
          'There is a problem in Assets By Company And Division function.',
          'Error retrieving the Assets'
        );
      },
      () => {
        this.auxArray = this.assetsMergedArray;
        if (this.result) {
          this.getMultipleAssetsByCompanyAndDivision();
          this.assetsMergedArray = [];
          auxDivisionTempArray = [];
          auxDivisionTempArray2 = [];
        } else {
          this.getSingleAssetsByCompanyAndDivision(this.tempArray);
          this.assetsMergedArray = [];
          auxDivisionTempArray = [];
          auxDivisionTempArray2 = [];
        }
      }
    );
  }

  /**
   * getSingleAssetsByCompanyAndDivision()
   * @param divisions
   * it is triggered if we have only one-page
   * Assets are stored in the Cache Memory
   */
  getSingleAssetsByCompanyAndDivision(divisions: DivisionModel[]) {
    this.tempArray = divisions;
    const arrayOfData = [];
    for (let i = 0; i < this.tempArray.length; i++) {
      arrayOfData.push(
        this.deviceProvisioningService.getAssetsByCompanyAndDivision(
          this.companyId,
          this.tempArray[i],
          1
        )
      );
    }
    this.assetsByCompanyAndDivisionSub = forkJoin(arrayOfData)
      .pipe(
        // @ts-ignore
        map((results) => results.reduce((all, itm) => all.concat(itm.body), []))
      )
      .subscribe(
        (assets: any) => {
          if (assets.length > 0) {
            this.assets = assets;
          }
        },
        () => {
          this.errorHandler(
            'There is a problem in Get Single Assets By Company And Division function.',
            'Error retrieving the Assets'
          );
        },
        () => {
          set('Assets', this.assets).then(() => {});
          this.devicesByAsset(this.assets);
          this.tempArray = [];
        }
      );
  }

  /**
   * getMultipleAssetsByCompanyAndDivision()
   * method call it we have multiple pages with more than 1000 divisions
   */
  getMultipleAssetsByCompanyAndDivision() {
    this.element = this.auxArray[this.divisionsCounter];
    this.retrievingData(this.element, this.page);
  }

  /**
   * retrievingData()
   * @param element
   * @param page
   * returns the Assets from all the pages
   */
  retrievingData(element: Element, page: number) {
    this.deviceProvisioningService
      .getAssetsByCompanyAndDivision(
        element.companyId,
        element.divisionsId,
        page
      )
      .subscribe(
        (res: any) => {
          if (res.body.length > 0) {
            this.assetsTempArray.push(...res.body);
            this.finalResultAssets = [...this.assetsTempArray];
          }
        },
        () => {
          this.errorHandler(
            'There is a problem in Retrieving Data function.',
            'Error retrieving the Assets'
          );
        },
        () => {
          if (page < element.page) {
            page++;
            this.retrievingData(element, page);
          } else {
            this.divisionsCounter++;
            this.page = 1;
            if (this.divisionsCounter < this.auxArray.length) {
              this.element = this.auxArray[this.divisionsCounter];
              this.retrievingData(this.element, this.page);
            } else {
              set('Assets', this.finalResultAssets).then(() => {});
              this.devicesByAsset(this.finalResultAssets);
              this.tempResultAssets = [];
              this.assetsTempArray = [];
              this.finalResultAssets = [];
              this.auxArray = [];
            }
          }
        }
      );
  }

  /**
   * devicesByAsset()
   * @param assets
   * assets array is needed to make the third API call
   * to retrieve the devices once they are returned we skip the null and NA values
   * then we push them to devicesTempArray then we assigned to this.devices
   * if devicesTempArray is bigger than 1000 we call this.sendDataInChunks
   * otherwise we call this.getDevices()
   * this.devices is going to be emitted to the component
   * this.devicesTempArray is going to be emptied and then we loop through the assets array
   * to get the asset.id and push it to devicesTempArray
   * if devicesTempArray is bigger than 1000 we call this.sendDataInChunks
   * otherwise we call this.getDevices()
   * Finally devices is emitted
   */
  devicesByAsset(assets: AssetModel[]) {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < assets.length; i++) {
      if (assets[i].id !== undefined || this.assets[i].id !== null) {
        this.devicesTempArray['tag'] = 'assets';
        this.devicesTempArray.push(assets[i].id);
      }
    }
    if (this.devicesTempArray.length >= 1000) {
      this.sendDataInChunks(this.devicesTempArray, 500);
    } else {
      this.getDevices(this.devicesTempArray);
    }
  }

  /**
   * getDevices()
   * @param data
   * getDevices make the API call using this.devicesByAssetSub
   * once devices are returned we skip the null and NA values
   * then we push them to devicesTempArray then we assigned to this.devices
   * finally Devices are emitted
   */
  getDevices(data: string[]) {
    const arrayOfData = [];
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        arrayOfData.push(
          this.deviceProvisioningService.getDevicesByAsset(data[i])
        );
      }
    }
    this.devicesByAssetSub = from(arrayOfData)
      .pipe(
        bufferCount(1000),
        concatMap((buffer) => forkJoin(buffer))
      )
      .subscribe(
        (devices: any) => {
          if (devices.length !== 0) {
            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < devices.length; i++) {
              if (devices[i].body.length !== 0) {
                if (
                  devices[i].body[0].mfrSerialNum !== null &&
                  devices[i].body[0].mfrSerialNum !== 'NA'
                ) {
                  this.devices.push(devices[i].body[0]);
                }
              }
            }
          }
        },
        () => {
          this.errorHandler(
            'There is a problem in Get Devices function.',
            'Error retrieving the Devices'
          );
        },
        () => {
          this.tempResultDevices.push(this.devices);
          if (this.arrayLength > this.devicesCounter) {
            this.retrieveData('assets', this.devicesCounter + 1);
          } else {
            this.finalResultDevices = [].concat(...this.tempResultDevices);
            set('Devices', this.finalResultDevices).then(() => {});
            this.dataSourceMappedService.getAllDataMapped();
            this.tempResultDevices = [];
            this.finalResultDevices = [];
          }
        }
      );

    this.devices = [];
    this.devicesTempArray = [];
  }

  /**
   * sendDataInChunks()
   * @param data
   * @param chunkSize
   * sendDataInChunks is going to split the data into chunks of 500 records
   * using this.splitArrayIntoChunks
   */
  sendDataInChunks(data, chunkSize: number): void {
    this.chunks = this.splitArrayIntoChunks(data, chunkSize);
    this.arrayLength = this.chunks.length - 1;
    if (data['tag'] === 'assets') {
      for (let i = 0; i < this.chunks.length; i++) {
        set(`${data['tag']}-${i}`, this.chunks[i]).then(() => {});
      }
    }
    this.retrieveData(data['tag'], 0);
  }

  /**
   * retrieveData()
   * @param tag
   * @param counter
   * retrieveData is going to retrieve the data from the cache
   */
  retrieveData(tag: string, counter: number) {
    this.devicesCounter = counter;
    if (tag === 'assets') {
      get(`${tag}-${this.devicesCounter}`).then((data) => {
        this.getDevices(data);
      });
    }
  }

  /**
   * splitArrayIntoChunks()
   * @param array
   * @param chunkSize
   * splitArrayIntoChunks is going to split the array into chunks
   */
  splitArrayIntoChunks(array: [], chunkSize: number) {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  }

  /**
   * errorHandler()
   * @param error
   * @param title
   * errorHandler is going to display the error message to the user
   */
  errorHandler(error: string, title: string) {
    Swal.fire({
      icon: 'error',
      title,
      text: error
    });
  }
}
