import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-device-tracker',
  templateUrl: './device-tracker.component.html',
  styleUrls: ['./device-tracker.component.scss']
})
export class DeviceTrackerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
