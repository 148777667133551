export class GlobalVariables {
  public static siteTitle: string =
    "A Data Platform DIET's application to play with IoT platform";

  public static CUSTOMER_ID: string = '';
  public static CUSTOMER_NAME: string = '';
  public static CUSTOMER_ACCOUNT_CODE: string = '';

  public static CONFIG_FILE_URL: string = 'assets/config-prod-jul19.json';

  public static USER_EMAIL: string = '';

  public static USER_ID = '';

  public static USER_GIVEN_NAME = '';

  public static PACKAGE_CONFIG_MSG = '';
}
