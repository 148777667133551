import { Component, DoCheck, Inject, OnInit } from '@angular/core';
import { faPowerOff, faUser } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.state';
import { cleanAccount } from '../../pages/select-account/store/select-account.actions';
import { DataSourceMappedService } from '../../core/_services/data-source-mapped.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit, DoCheck {
  isCollapsed = true;
  faUser = faUser;
  faPowerOff = faPowerOff;
  companyName: string;
  mainMenu = [
    {
      menu_id: 1,
      menu_name: 'Config Mapping',
      link: '/config-mapping',
      status: 'active'
    },
    {
      menu_id: 2,
      menu_name: 'Publish Config',
      link: '/publish-config',
      status: 'active'
    },
    {
      menu_id: 3,
      menu_name: 'Device Provisioning',
      link: '/device-provisioning',
      status: 'active'
    },
    {
      menu_id: 4,
      menu_name: 'Device Settings',
      link: '/device-settings',
      status: 'active'
    },
    {
      menu_id: 5,
      menu_name: 'Master Signals',
      link: '/signal',
      status: 'active'
    },
    {
      menu_id: 6,
      menu_name: 'Configuration',
      link: null,
      status: 'active',
      submenu: [
        {
          menu_id: 61,
          menu_name: 'Package Config',
          link: '/package-config',
          status: 'active'
        },
        {
          menu_id: 62,
          menu_name: 'Datasets',
          link: '/dataset',
          status: 'active'
        },
        {
          menu_id: 63,
          menu_name: 'Triggers',
          link: '/trigger',
          status: 'active'
        },
        {
          menu_id: 64,
          menu_name: 'Signal Trigger Mappings',
          link: '/signal-trigger-mappings',
          status: 'active'
        }
      ]
    },
    {
      menu_id: 7,
      menu_name: 'Whitelist',
      link: '/whitelist',
      status: 'active'
    }
  ];
  private activeRoute: string;

  constructor(
    public auth: AuthService,
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private store: Store<AppState>,
    private dataSourceMappedService: DataSourceMappedService
  ) {}

  ngOnInit() {
    this.router.events.subscribe((routerValue) => {
      if (routerValue instanceof NavigationEnd) {
        this.activeRoute = this.router.url.trim();
      }
    });
  }

  ngDoCheck() {
    this.store.select('account').subscribe((res) => {
      if (res.account?.length > 0) {
        this.companyName =
          res.account[0]['customer'] + ' [ ' + res.account[0]['account'] + ' ]';
      }
    });
  }

  selectAccountClick() {
    this.router.navigate([
      '/account/select-account',
      { previousUrl: this.router.url }
    ]);
  }

  loginWithRedirect() {
    this.auth.loginWithRedirect();
  }

  logout() {
    localStorage.clear();
    this.store.dispatch(cleanAccount());
    this.dataSourceMappedService.cleanContainersAndCache();
    this.dataSourceMappedService.cleanCompanyAccount();
    this.dataSourceMappedService.cleanPackageConfigListCache();
    this.auth.logout({ returnTo: this.document.location.origin });
  }

  public routeIsActive(route: string): boolean {
    if (
      route === null &&
      (this.activeRoute === '/dataset' ||
        this.activeRoute === '/trigger' ||
        this.activeRoute === '/package-config')
    ) {
      return true;
    } else {
      return this.activeRoute === route;
    }
  }

  userGuide() {
    this.document.location.href =
      'https://zonarsystems.atlassian.net/wiki/spaces/DDOS/pages/137657221276/UI+App+User+Guide';
  }
}
