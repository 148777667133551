import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, DatePipe, JsonPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HIGHLIGHT_OPTIONS, HighlightModule } from 'ngx-highlightjs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ErrorComponent } from './pages/error/error.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeroComponent } from './components/hero/hero.component';
import { HomeContentComponent } from './components/home-content/home-content.component';
import { LoadingComponent } from './components/loading/loading.component';
import { ExternalApiComponent } from './pages/external-api/external-api.component';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule
} from '@angular/common/http';
import {
  AuthHttpInterceptor,
  AuthModule,
  AuthService
} from '@auth0/auth0-angular';
import { environment as env, environment } from '../environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef
} from '@angular/material/dialog';
import { AssetMappingComponent } from './pages/asset-mapping/asset-mapping.component';
import { ConfigDialogComponent } from './pages/asset-mapping/config-dialog/config-dialog.component';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { SelectAccountComponent } from './pages/select-account/select-account.component';
import { PublishconfigComponent } from './pages/publishconfig/publishconfig.component';
import { DeviceDialogComponent } from './pages/publishconfig/device-dialog/device-dialog.component';
import { PublishConfigDialogComponent } from './pages/publishconfig/publish-config-dialog/publish-config-dialog.component';
import { ZonarUiAuthModule, ZonarUiPermissionsModule } from '@zonar-ui/auth';
import { permissions } from '../environments/shared';
import { FooterModule, HeaderModule, ZonarUICoreModule } from '@zonar-ui/core';
import { DeviceProvisioningComponent } from './pages/device-provisioning/device-provisioning.component';
import { PackageConfigComponent } from './pages/package-config/package-config.component';
import { ViewDeviceDialogComponent } from './pages/asset-mapping/view-device-dialog/view-device-dialog.component';
import { CreatePackageComponent } from './pages/package-config/create-package/create-package.component';
import { ViewConfigDialogComponent } from './pages/package-config/view-config-dialog/view-config-dialog.component';
import { AdminComponent } from './pages/admin/admin.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { MessageComponent } from './components/message/message.component';
import { AngularMaterialModule } from './angular-material/angular-material.module';
import { SharedModule } from './shared/shared.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { DeviceSettingsComponent } from './pages/device-settings/device-settings.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ActionDatasetComponent } from './pages/dataset/action-dataset/action-dataset.component';
import { DatasetComponent } from './pages/dataset/dataset.component';
import { ViewDatasetConfigComponent } from './pages/dataset/view-dataset-config/view-dataset-config.component';
import { CreateTriggerComponent } from './pages/trigger/create-trigger/create-trigger.component';
import { TriggerComponent } from './pages/trigger/trigger.component';
import { ViewSignalConfigComponent } from './pages/trigger/view-signal-config/view-signal-config.component';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { AuthPipePipe } from './auth-pipe.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ScheduleModalComponent } from './pages/device-settings/schedule-modal/schedule-modal.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalResultComponent } from './pages/device-settings/modal-result/modal-result.component';
import { WhitelistingComponent } from './pages/whitelisting/whitelisting.component';
import { WhitelistDialogComponent } from './whitelist-dialog/whitelist-dialog.component';
import { SignalComponent } from './pages/signal/signal.component';
import { MatButtonModule } from '@angular/material/button';
import { NgForOf, NgIf } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { DeviceTrackerComponent } from './pages/device-tracker/device-tracker.component';
import { ZonarUIMaterialModule } from '@zonar-ui/material';
import { CustomFirmwarePipe } from './pipes/custom-firmware.pipe';
import { StoreModule } from '@ngrx/store';
import { appReducers } from './store/app.state';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { metaReducers } from './store/store.reducers';
import { ActionSignalComponent } from './pages/signal/action-signal/action-signal.component';
import { NgxsReadMoreModule } from '@minni/read-more';
import { SignalTriggerMappingComponent } from './signal-trigger-mapping/signal-trigger-mapping.component';
import { NullToNAPipe } from './null-to-na.pipe';
import { ZonarUITableModule } from '@zonar-ui/table';
import {
  I18nService,
  TranslateModule,
  TranslateService,
  ZonarUiI18nModule
} from '@zonar-ui/i18n';
import { ZonarUiSearchableDropdownModule } from '@zonar-ui/searchable-dropdown';
import { SignalTriggerMappingEditModalComponent } from './signal-trigger-mapping-edit-modal/signal-trigger-mapping-edit-modal.component';
import { SignalTriggerMappingCreateModalComponent } from './signal-trigger-mapping-create-modal/signal-trigger-mapping-create-modal.component';
import { SignalTriggerMappingTransformerViewComponent } from './singal-trigger-mapping-transformer-view/signal-trigger-mapping-transformer-view.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProfileComponent,
    NavBarComponent,
    FooterComponent,
    HeroComponent,
    HomeContentComponent,
    LoadingComponent,
    ExternalApiComponent,
    ErrorComponent,
    AssetMappingComponent,
    ConfigDialogComponent,
    NavMenuComponent,
    SelectAccountComponent,
    PublishconfigComponent,
    DeviceDialogComponent,
    PublishConfigDialogComponent,
    DeviceProvisioningComponent,
    PackageConfigComponent,
    ViewDeviceDialogComponent,
    CreatePackageComponent,
    ViewConfigDialogComponent,
    AdminComponent,
    ConfirmDialogComponent,
    MessageComponent,
    DeviceSettingsComponent,
    TriggerComponent,
    CreateTriggerComponent,
    ViewSignalConfigComponent,
    DatasetComponent,
    ViewDatasetConfigComponent,
    ActionDatasetComponent,
    UnauthorizedComponent,
    AuthPipePipe,
    ScheduleModalComponent,
    ModalResultComponent,
    WhitelistingComponent,
    WhitelistDialogComponent,
    SignalComponent,
    DeviceTrackerComponent,
    CustomFirmwarePipe,
    ActionSignalComponent,
    SignalTriggerMappingComponent,
    NullToNAPipe,
    SignalTriggerMappingEditModalComponent,
    SignalTriggerMappingCreateModalComponent,
    SignalTriggerMappingTransformerViewComponent
  ],
  imports: [
    ZonarUICoreModule,
    ZonarUiAuthModule,
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HighlightModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    AuthModule.forRoot({
      clientId: environment.auth.clientId,
      domain: environment.auth.domain,
      audience: environment.auth.audience,
      redirect_uri: environment.auth.redirectUri,
      scope: 'read:current_user',
      useRefreshTokens: true,
      max_age: 36000,
      cacheLocation: 'localstorage',
      httpInterceptor: {
        ...env.httpInterceptor
      }
    }),
    MatDialogModule,
    FooterModule,
    HeaderModule,
    AngularMaterialModule,
    SharedModule,
    NgxSkeletonLoaderModule,
    MatRadioModule,
    MatPaginatorModule,
    MatSortModule,
    MatStepperModule,
    MatTooltipModule,
    HighlightModule,
    MatTableModule,
    NgForOf,
    MatButtonModule,
    NgIf,
    MatIconModule,
    NgxsReadMoreModule,
    StoreModule.forRoot(appReducers, { metaReducers }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production
    }),
    ZonarUITableModule,
    ZonarUiI18nModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en'
    }),
    ZonarUIMaterialModule,
    ZonarUiAuthModule,
    ZonarUiPermissionsModule,
    AuthModule,
    ZonarUiPermissionsModule,
    ZonarUiSearchableDropdownModule,
    NgxMatSelectSearchModule
  ],
  providers: [
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        fullLibraryLoader: () => import('highlight.js')
      }
    },
    TranslateService,
    I18nService,
    AuthService,

    { provide: 'logoutReturnToUrl', useValue: window.location.origin },
    { provide: 'applicationId', useValue: environment.auth.applicationId },
    { provide: 'environment', useValue: environment.authEnv },
    { provide: 'defaultPermPrefix', useValue: permissions.defaultPermPrefix },
    { provide: 'routePermissions', useValue: permissions.routes },
    { provide: 'redirectUrl', useValue: permissions.defaultRedirectUrl },
    { provide: 'region', useValue: 'US' },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    {
      provide: 'supportedLanguages',
      useValue: environment.i18n.supportedLanguages
    },
    { provide: 'defaultLanguage', useValue: environment.i18n.defaultLanguage },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true
    },
    {
      provide: Window,
      useValue: window
    },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          json: () => import('highlight.js/lib/languages/json')
        }
      }
    },
    {
      provide: MAT_DIALOG_DATA,
      useValue: {}
    },
    {
      provide: MatDialogRef,
      useValue: {}
    },
    { provide: JsonPipe },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  translationsNotLoaded = true;
  constructor(
    public translateService: TranslateService,
    private i18nService: I18nService
  ) {
    if (this.translationsNotLoaded) {
      this.translateService
        .use(this.i18nService.getLanguageInUse())
        .subscribe(() => {
          this.translationsNotLoaded = false;
        });
    }
  }
}
