import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PackageConfigModel } from 'src/app/core/_models/PackageConfigModel';
import { GlobalVariables } from 'src/app/core/_common/GlobalVariables';
import { PackageConfigApiService } from '../../../core/_services/package-config-api.service';
import { DatasetModel } from 'src/app/core/_models/DatasetModel';
import { TriggerModel } from 'src/app/core/_models/TriggerModel';
import { DatadogService } from 'src/app/core/_services/datadog.service';

@Component({
  selector: 'app-create-package',
  templateUrl: './create-package.component.html',
  styleUrls: ['./create-package.component.scss']
})
export class CreatePackageComponent {
  functionType: string;
  packageConfigId: string;
  selectedDataset: any = [];
  selectedTrigger: any = [];
  configNameFormControl = new FormControl('');
  emailFormControl = new FormControl(
    { value: '', disabled: true },
    Validators.required
  );
  descriptionFormControl = new FormControl('');
  statusFormControl = new FormControl('READY_FOR_CUSTOMER');
  selectedDatasetFormControl = new FormControl('');
  selectedTriggerFormControl = new FormControl('');

  datasetId = new FormControl('');
  datasetList: DatasetModel[] = [];

  triggerId = new FormControl('');
  triggerList: TriggerModel[] = [];
  loading: boolean = false;
  datasetConfigView: any;
  triggerConfigView: any;
  buttonLabel: string = 'Create Package Config';

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private packageConfigApiService: PackageConfigApiService,
    public dialogRef: MatDialogRef<CreatePackageComponent>,
    private datadogService: DatadogService
  ) {}

  ngOnInit(): void {
    this.datadogService.log(
      `${GlobalVariables.USER_EMAIL} accessed Create-Package-Config Page`,
      {
        name: 'Create-Package-Config Component',
        id: 10063,
        userName: GlobalVariables.USER_GIVEN_NAME,
        userEmail: GlobalVariables.USER_EMAIL
      },
      'info'
    );

    this.functionType = this.dialogData.functionType;
    this.datasetList = this.dialogData.datasetList;
    this.triggerList = this.dialogData.triggerList;
    this.emailFormControl.setValue(GlobalVariables.USER_EMAIL);
    this.buttonLabel = this.functionType + ' Package Config';

    if (
      this.dialogData.functionType == 'Clone' ||
      this.dialogData.functionType == 'Edit'
    ) {
      if (this.dialogData.functionType == 'Edit') {
        this.packageConfigId = this.dialogData.data.id;
      }
      this.datasetId.setValue(this.dialogData.data.datasetId);
      this.triggerId.setValue(this.dialogData.data.triggerId);
      this.configNameFormControl.setValue(this.dialogData.data.name);
      this.descriptionFormControl.setValue(this.dialogData.data.description);
      this.statusFormControl.setValue(this.dialogData.data.status);
      let datasetEvent = { value: this.dialogData.data.datasetId };
      this.changeDatasetEvent(datasetEvent);
      let triggerEvent = { value: this.dialogData.data.triggerId };
      this.changeTriggerEvent(triggerEvent);
    } else {
      this.datasetList = this.dialogData.datasetList;
    }
  }

  async createOrClonePackageConfig() {
    this.datadogService.log(
      `${GlobalVariables.USER_EMAIL} clicked "Create/Clone" button in "Create-Package-Config" Page`,
      {
        name: 'Create/Clone - Create-Package-Config Component',
        id: 10064,
        userName: GlobalVariables.USER_GIVEN_NAME,
        userEmail: GlobalVariables.USER_EMAIL
      },
      'info'
    );
    try {
      this.loading = true;
      if (this.functionType == 'Clone' || this.functionType == 'Create') {
        var configBody = {
          name: this.configNameFormControl.value,
          userEmail: this.emailFormControl.value,
          description: this.descriptionFormControl.value,
          datasetId: this.datasetId.value,
          triggerId: this.triggerId.value,
          status: this.statusFormControl.value
        };
        (await this.packageConfigApiService.createPackageConfig(configBody))
          .toPromise()
          .then(async (response: PackageConfigModel) => {
            await this.packageConfigApiService
              .generateConfig(response.id)
              .toPromise();
            this.loading = false;
            this.datadogService.log(
              `${GlobalVariables.USER_EMAIL}  created/cloned package config`,
              {
                name: 'Create-Package-Config Component',
                id: 10065,
                userName: GlobalVariables.USER_GIVEN_NAME,
                userEmail: GlobalVariables.USER_EMAIL
              },
              'info'
            );
            let status = this.functionType + ',Success, Success';
            this.closeDialog(status);
          })
          .catch((err) => {
            let status =
              this.functionType + ',Error, [CPC-422201] - ' + err.statusText;
            this.closeDialog(status);
          });
      } else {
        var configBody = {
          name: this.configNameFormControl.value,
          userEmail: this.emailFormControl.value,
          description: this.descriptionFormControl.value,
          datasetId: this.datasetId.value,
          triggerId: this.triggerId.value,
          status: this.statusFormControl.value
        };
        (
          await this.packageConfigApiService.editPackageConfig(
            configBody,
            this.packageConfigId
          )
        )
          .toPromise()
          .then((data) => {
            this.loading = false;
            let status = this.functionType + ',Success, Success';
            this.closeDialog(status);
          })
          .catch((err) => {
            let status =
              this.functionType + ',Error, [UPC-422202] - ' + err.statusText;
            this.closeDialog(status);
          });
      }
    } catch (error) {
      console.log('Package Config creation error :', error);
      this.loading = false;
    }
  }

  async closeDialog(functionType: string) {
    try {
      this.dialogRef.close(functionType); // make sure it only closes if the upper async fn succesfully ran!
    } catch (e) {
      //this.errorMessage = e.response.message;
    }
  }

  changeDatasetEvent(event) {
    var selectedDatasetID = [];
    selectedDatasetID = event.value;

    this.datasetConfigView = '';
    this.selectedDataset = [];

    selectedDatasetID.forEach((datasetId) => {
      this.dialogData.datasetList.forEach((dataset) => {
        if (dataset.id == datasetId) {
          let data = {
            sourceId: dataset.sourceId,
            gpsDataConfig: dataset.gpsDataConfig,
            digitalInputConfig: dataset.digitalInputConfig,
            accelerometerConfig: dataset.accelerometerConfig,
            appConfig: dataset.appConfig,
            analyticsConfig: dataset.analyticsConfig,
            j1939Configs: dataset.j1939Configs,
            j1587Configs: dataset.j1587Configs,
            obd2Configs: dataset.obd2Configs
          };

          var datasetConfig = JSON.stringify(data, null, 2);
          let jsonData = {
            id: dataset.id,
            name: dataset.name,
            config: datasetConfig
          };
          this.selectedDataset.push(jsonData);
        }
      });
    });
    if (this.selectedDataset.length != 0) {
      this.selectedDatasetFormControl.setValue(this.selectedDataset[0].id);
      this.datasetConfigView = this.selectedDataset[0].config;
    }
  }

  changeTriggerEvent(event) {
    var selectedTriggerID = [];
    selectedTriggerID = event.value;

    this.triggerConfigView = '';
    this.selectedTrigger = [];

    selectedTriggerID.forEach((triggerId) => {
      this.dialogData.triggerList.forEach((trigger) => {
        if (trigger.id == triggerId) {
          let data = trigger.signalConfig;

          var triggerConfig = JSON.stringify(data, null, 2);
          let jsonData = {
            id: trigger.id,
            name: trigger.name,
            config: triggerConfig
          };
          this.selectedTrigger.push(jsonData);
        }
      });
    });
    if (this.selectedTrigger.length != 0) {
      this.selectedTriggerFormControl.setValue(this.selectedTrigger[0].id);
      this.triggerConfigView = this.selectedTrigger[0].config;
    }
  }

  onSelectedDataset(dataset: any) {
    this.datasetConfigView = dataset.config;
  }

  onSelectedTrigger(trigger: any) {
    this.triggerConfigView = trigger.config;
  }
}
