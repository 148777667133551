import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { of } from 'rxjs';
import { AssetModel } from '../_models/AssetModel';

@Injectable({
  providedIn: 'root'
})
export class AssetApiService {
  assetList: AssetModel[];
  constructor(private http: HttpClient) {}

  getAssets(): Observable<any> {
    return this.http.get(`${environment.serviceapi}/assets`);
  }

  getAssetByAttribute(companyId: string): Observable<any> {
    let treeObs: Observable<any>;

    let queryParams = new HttpParams();

    queryParams = queryParams.append('companyId', companyId);
    queryParams = queryParams.append('status', 'ACTIVE');
    queryParams = queryParams.append('per_page', '1000');
    queryParams = queryParams.append('page', '1');

    treeObs = new Observable((obs) => {
      this.http
        .get<AssetModel[]>(`${environment.entityAssets}`, {
          params: queryParams,
          observe: 'response'
        })
        .subscribe(async (resp) => {
          this.assetList = resp.body;
          let page_count = resp.headers.get('X-Page-Count');
          if (+page_count > 1) {
            for (let i = 2; i <= +page_count; i++) {
              queryParams = queryParams.set('page', i);
              await this.http
                .get<AssetModel[]>(`${environment.entityAssets}`, {
                  params: queryParams
                })
                .toPromise()
                .then((data) => {
                  this.assetList = this.assetList.concat(data);
                });
            }
          }
          obs.next(this.assetList);
          obs.complete();
        });
    });

    return treeObs;
  }

  getAssetDetailsById(assetId: string) {
    return this.http.get<AssetModel>(
      `${environment.serviceapi}/assets/${assetId}`
    );
  }
}
