import { createAction, props } from '@ngrx/store';
import { DivisionModel } from '../../core/_models/DivisionModel';
import { DeviceModel } from '../../core/_models/DeviceModel';
import { AssetModel } from '../../core/_models/AssetModel';

export const addDivisions = createAction(
  '[ SET DIVISIONS ]',
  props<{ divisions: DivisionModel[] }>()
);

export const cleanDivisions = createAction('[ CLEAN DIVISIONS ]');

export const addAssets = createAction(
  '[ SET ASSETS ]',
  props<{ assets: AssetModel[] }>()
);

export const cleanAssets = createAction('[ CLEAN ASSETS ]');

export const addDevices = createAction(
  '[ SET DEVICES ]',
  props<{ devices: DeviceModel[] }>()
);

export const cleanDevices = createAction('[ CLEAN DEVICES ]');
