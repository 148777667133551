import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DeviceConfigMapping } from '../../core/_models/DeviceConfigMapping';
import { PublishConfigDialogComponent } from './publish-config-dialog/publish-config-dialog.component';
import { PackageConfigModel } from '../../core/_models/PackageConfigModel';
import { Router } from '@angular/router';
import {
  DEVICE_CONFIG_MAPPING_KEY,
  DEVICE_DIALOG_HEIGHT,
  DEVICE_DIALOG_WIDTH,
  DISPLAYED_COLUMNS,
  DISPLAYED_COLUMNS_SELECTED
} from './constants';
import { clear, get } from 'idb-keyval';
import { ViewDeviceDialogComponent } from '../asset-mapping/view-device-dialog/view-device-dialog.component';
import { MatSort } from '@angular/material/sort';
import { GlobalVariables } from 'src/app/core/_common/GlobalVariables';
import { DatadogService } from 'src/app/core/_services/datadog.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.state';
import { DivisionsAssetsDevicesService } from '../../core/_services/divisions-assets-devices.service';
import { DataSourceMappedService } from '../../core/_services/data-source-mapped.service';
import { Subscription } from 'rxjs';
import { JsonDialogComponent } from '../../shared/json-dialog/json-dialog.component';
import { MatSelectChange } from '@angular/material/select';
import { Papa } from 'ngx-papaparse';

@Component({
  selector: 'app-publishconfig',
  templateUrl: './publishconfig.component.html',
  styleUrls: ['./publishconfig.component.scss']
})
export class PublishconfigComponent implements OnInit, OnDestroy {
  pageLoading = true;
  refreshLoader = false;
  publishButtonStatus = true;
  tableLoading = false;
  companyName = '';
  accountCode = '';
  companyId = '';
  dataSource: any;
  dataSourceSelected: any = [];
  displayedColumns: string[] = DISPLAYED_COLUMNS;
  displayedColumnsSelected: string[] = DISPLAYED_COLUMNS_SELECTED;
  packageList: PackageConfigModel[];
  loaderSub: Subscription;
  dataSourceSub: Subscription;
  selectedDeviceConfigValues = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private datadogService: DatadogService,
    private store: Store<AppState>,
    private divisionsAssetsDevicesService: DivisionsAssetsDevicesService,
    private dataSourceMappedService: DataSourceMappedService,
    private papa: Papa
  ) {}

  ngOnInit(): void {
    this.dataDogLogs();
    this.validatingDataInCache();
  }

  ngOnDestroy() {
    this.loaderSub?.unsubscribe();
    this.dataSourceSub?.unsubscribe();
  }

  /**
   * dataDogLogs()
   * check and share the logs in DD
   */
  dataDogLogs() {
    this.datadogService.log(
      `${GlobalVariables.USER_EMAIL} accessed Publish-Config Page`,
      {
        name: 'Publish-Config Component',
        id: 1003,
        userName: GlobalVariables.USER_GIVEN_NAME,
        userEmail: GlobalVariables.USER_EMAIL
      },
      'info'
    );
  }

  /**
   * validatingDataInCache()
   * check if there is data stored in the cache
   * and populate the table otherwise it retrieves the
   * data source using getDataSource() method
   */
  validatingDataInCache() {
    this.store.select('account').subscribe((res) => {
      if (res.account?.length === 0 || res.account === undefined) {
        this.router.navigate([
          '/account/select-account',
          { previousUrl: this.router.url }
        ]);
      } else {
        this.companyName = res.account[0]['customer'];
        this.accountCode = res.account[0]['account'];
        get(DEVICE_CONFIG_MAPPING_KEY).then((dataSource: any[]) => {
          if (dataSource !== undefined) {
            this.loadTable(dataSource);
          } else {
            this.getDataSource();
          }
        });
        this.pageLoading = false;
      }
    });
  }

  /**
   * getDataSource()
   * retrieve the data source from the store
   * then the response is set in setLoadTable()
   */
  getDataSource() {
    this.loaderSub = this.store
      .select('ui')
      .subscribe((ui) => (this.tableLoading = ui.isLoading));
    this.dataSourceSub = this.store
      .select('dataSource')
      .subscribe((response) => {
        if (response['dataSource']?.length > 0) {
          this.setLoadTable(response['dataSource']);
        }
      });
  }

  /**
   * setLoadTable()
   * @param data
   * pass the data source to loadTable()
   */
  async setLoadTable(data: DeviceConfigMapping[]) {
    this.loadTable(data);
  }

  /**
   * loadTable()
   * @param deviceConfigMapping
   * Sort and Filter the Data Source then it populates the table
   * stop the loaders
   * set the pagination functionality
   */
  loadTable(deviceConfigMapping: DeviceConfigMapping[]): void {
    let arrayForSort = [...deviceConfigMapping];
    arrayForSort = arrayForSort.sort((a: any, b: any) => {
      return a.asset_name - b.asset_name;
    });
    arrayForSort = arrayForSort.filter(
      (obj, index, self) =>
        obj.serial_number !== undefined &&
        obj.serial_number !== null &&
        index === self.findIndex((o) => o.serial_number === obj.serial_number)
    );
    this.dataSource = new MatTableDataSource(arrayForSort);
    this.paginator.pageSize = 10;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.loaderSub = this.store
      .select('ui')
      .subscribe((ui) => (this.tableLoading = ui.isLoading));
    this.refreshLoader = false;
    this.publishButtonStatus = true;
  }

  /**
   * applyFilter()
   * @param event
   * filter the data given an input
   */
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /**
   * viewConfigDialog()
   * @param device
   * Shows View Config Modal
   */
  viewConfigDialog(device: any) {
    this.dialog.open(JsonDialogComponent, {
      data: device,
      height: DEVICE_DIALOG_HEIGHT,
      width: DEVICE_DIALOG_WIDTH,
      panelClass: 'device-list'
    });
  }

  /**
   * updateChecked()
   * @param event
   * @param deviceId
   * logic implemented to update when the user
   * selects or unselects an item
   * it sorts the items
   */
  updateChecked(event: Event, deviceId: any) {
    this.publishButtonStatus = true;
    if (event) {
      this.dataSource.data.forEach((t) =>
        t.device_id === deviceId ? (t.checked = true) : null
      );
    } else {
      this.dataSource.data.forEach((t) =>
        t.device_id === deviceId ? (t.checked = false) : null
      );
    }

    this.dataSource.data.forEach((t) =>
      t.checked ? (this.publishButtonStatus = false) : null
    );

    this.dataSourceSelected = this.dataSource.data.filter((d) => {
      return d.checked;
    });
  }

  /**
   * updateConfig()
   * @param matSelectChange
   * @param device
   * @param packageConfigId
   * Retrieves the Package Config List then the data is mapped
   * @param index
   */

  updateConfig(
    matSelectChange: MatSelectChange,
    device: any,
    packageConfigId: string,
    index: number
  ) {
    this.selectedDeviceConfigValues[index] = matSelectChange.value;
    this.packageList = JSON.parse(localStorage.getItem('PACKAGE_CONFIG_LIST'));
    const configDetails = this.packageList.find(
      (t) => t.id === packageConfigId
    );
    const deviceToProcess = this.dataSourceSelected.find((d) => {
      return d.id === device.id;
    });

    if (deviceToProcess && configDetails) {
      deviceToProcess.selected_config_label = configDetails.name;
      deviceToProcess.selected_config = configDetails.base64;
      deviceToProcess.selected_config_json = configDetails.jsonConfig;
      deviceToProcess.trigger_id = configDetails.triggerId;
      deviceToProcess.packageConfigId = configDetails.id;
    }
  }

  public globalConfigSelect(
    matSelectChange: MatSelectChange,
    packageConfigId: string
  ): void {
    const tempArray = new Array(this.dataSourceSelected.length).fill('');

    for (let i = 0; i < tempArray.length; i++) {
      tempArray[i] = matSelectChange.value;
    }

    this.selectedDeviceConfigValues = tempArray;

    this.packageList = JSON.parse(localStorage.getItem('PACKAGE_CONFIG_LIST'));
    const configDetails = this.packageList.find(
      (t) => t.id === packageConfigId
    );

    this.dataSourceSelected.forEach((ds) => {
      ds.selected_config_label = configDetails.name;
      ds.selected_config = configDetails.base64;
      ds.selected_config_json = configDetails.jsonConfig;
      ds.trigger_id = configDetails.triggerId;
      ds.packageConfigId = configDetails.id;
    });
  }

  /**
   * viewDeviceDialog()
   * @param device
   * shows View Device Modal
   */
  viewDeviceDialog(device: any) {
    this.dialog.open(ViewDeviceDialogComponent, {
      data: device,
      height: DEVICE_DIALOG_HEIGHT,
      width: DEVICE_DIALOG_WIDTH
    });
  }

  /**
   * publishConfig()
   * filter the data source then it
   * shows Publish Config Modal
   */
  publishConfig() {
    const publishItems = this.dataSourceSelected;
    const dialogRef = this.dialog.open(PublishConfigDialogComponent, {
      data: publishItems,
      width: DEVICE_DIALOG_WIDTH
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.event === 'refresh') {
        this.refreshData();
      }
    });
  }

  /**
   * refreshData()
   * clean all the containers and cache
   * triggers the Data Dog service
   * uses getDeviceList() to make a new API call from scratch
   * uses refreshingData() to retrieve the new data generated from the BE
   */
  refreshData() {
    this.refreshLoader = true;
    this.dataSourceMappedService.cleanContainersAndCache();
    this.divisionsAssetsDevicesService.getDeviceList();
    this.dataSourceMappedService.cleanPackageConfigListCache();
    this.datadogService.log(
      `${GlobalVariables.USER_EMAIL} clicked "Refresh Data" button in "Publish-Config" Page`,
      {
        name: 'Refresh button - Publish-Config Component',
        id: 10031,
        userName: GlobalVariables.USER_GIVEN_NAME,
        userEmail: GlobalVariables.USER_EMAIL
      },
      'info'
    );
    clear().then(() => {
      this.dataSourceSelected = [];
      this.dataSource.data = [];
      this.validatingDataInCache();
    });
  }

  public csvFile: any;
  public uploadCSV(event: any): void {
    const csvData = event.target.files[0];
    const options = {
      complete: (results, file) => {
        const allEntries = results.data;

        if (typeof allEntries === 'object' && allEntries.length > 0) {
          allEntries.forEach((entry) => {
            if (!isNaN(entry[0]) && !isNaN(parseFloat(entry[0]))) {
              const serialNumber = entry[0];

              this.dataSource.filteredData.forEach((ds) => {
                if (
                  ds['serial_number'].trim() === String(serialNumber).trim()
                ) {
                  ds.checked = true;
                  this.dataSourceSelected.push(ds);
                }
              });
            }
          });
        }
      }
    };

    this.papa.parse(csvData, options);
    this.csvFile = '';
  }
}
