<div class="container" *ngIf="pageLoading">
  <app-loading></app-loading>
</div>

<mat-card *ngIf="!pageLoading">
  <div id="table-title">Device List</div>

  <div id="info" class="submenu-margin">
    <div class="info-block"><b>Customer Name: </b>&nbsp;{{ companyName }}</div>
    <div class="info-block"><b>Account Code: </b>&nbsp;{{ accountCode }}</div>
    <div id="publish-button">
      <label for="file-upload" class="mat-raised-button custom-file-upload"
        >Upload CSV</label
      >

      <input
        id="file-upload"
        type="file"
        (change)="uploadCSV($event)"
        accept=".csv"
        [(ngModel)]="csvFile"
      />

      <button
        id="publish-mat-button"
        mat-raised-button
        matTooltip="Publish config to IoT Core"
        (click)="publishConfig()"
        [disabled]="this.dataSourceSelected.length <= 0"
      >
        Publish Config
      </button>
      <button
        mat-raised-button
        (click)="refreshData()"
        matTooltip="Refresh Asset/Device List"
        id="refresh-button"
      >
        <mat-icon [class.rotate]="refreshLoader">refresh</mat-icon>
      </button>
    </div>
  </div>

  <mat-card *ngIf="dataSourceSelected.length > 0" class="selected-devices-card">
    <div class="selected-devices-top">
      <div class="selected-devices-title">Selected Devices</div>

      <div class="multi-select-container">
        <div class="multi-devices-title">Bulk Update Config Value</div>

        <mat-form-field
          class="a-left-selector"
          id="select-form-field"
          appearance="fill"
        >
          <mat-label>Select Config</mat-label>
          <mat-select
            #selectElement
            (selectionChange)="globalConfigSelect($event, selectElement.value)"
          >
            <mat-option>None</mat-option>
            <mat-option
              [ngStyle]="{ height: '6em' }"
              *ngFor="let c of dataSource.filteredData[0].configlist"
              [value]="c.id"
            >
              <b>{{ c.name }}</b>
              <br />
              ({{ c.created | date }})
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <table mat-table [dataSource]="dataSourceSelected" matSort>
      <ng-container matColumnDef="remove">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox
            [(ngModel)]="element.checked"
            (ngModelChange)="updateChecked($event, element.id)"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- Account Code Column -->
      <ng-container matColumnDef="account_code">
        <th mat-header-cell *matHeaderCellDef>Account Code</th>
        <td mat-cell *matCellDef="let element">{{ element.account_code }}</td>
      </ng-container>

      <!-- Asset Name Column -->
      <ng-container matColumnDef="asset_name">
        <th mat-header-cell *matHeaderCellDef>Asset Name</th>
        <td mat-cell *matCellDef="let element">{{ element.asset_name }}</td>
      </ng-container>

      <!-- Serial Number Column -->
      <ng-container matColumnDef="serial_number">
        <th mat-header-cell *matHeaderCellDef>Serial Number</th>
        <td mat-cell *matCellDef="let element">
          <a
            class="a-left"
            href="javascript:void(0);"
            matTooltip="Click to view Asset/Device details"
            (click)="viewDeviceDialog(element)"
            >{{ element.serial_number }}
          </a>
        </td>
      </ng-container>

      <!-- VIN Number Column -->
      <ng-container matColumnDef="vin_number">
        <th mat-header-cell *matHeaderCellDef>VIN Number</th>
        <td mat-cell *matCellDef="let element">{{ element.vin_number }}</td>
      </ng-container>

      <!-- Firmware Version Column -->
      <ng-container matColumnDef="firmware_version">
        <th mat-header-cell *matHeaderCellDef>Firmware Version</th>
        <td mat-cell *matCellDef="let element">
          {{ element.firmware_version }}
        </td>
      </ng-container>

      <!-- Current Config Column -->
      <ng-container matColumnDef="config_label">
        <th mat-header-cell *matHeaderCellDef>Current Config</th>
        <td mat-cell *matCellDef="let element">
          <span
            *ngIf="
              element.config_label === 'CONFIG NOT PUBLISHED' &&
              element.last_published === null
            "
            >CONFIG NOT PUBLISHED</span
          >
          <a
            class="a-left"
            matTooltip="Click to view Current Config"
            *ngIf="element.config_label !== 'CONFIG NOT PUBLISHED'"
            (click)="viewConfigDialog(element)"
            >{{ element.config_label }}</a
          >
          <br />
          <mat-hint *ngIf="element.last_published !== null">
            Modified:
            {{ element.last_published | date: 'MM/dd/yyyy HH:mm:ss' }}
          </mat-hint>
        </td>
      </ng-container>
      <ng-container matColumnDef="new_configs">
        <th mat-header-cell *matHeaderCellDef>Select New Config</th>
        <td mat-cell *matCellDef="let element; let i = index">
          <mat-form-field
            class="a-left-selector"
            id="select-form-field"
            appearance="fill"
          >
            <mat-label>Select Config</mat-label>
            <mat-select
              [id]="i"
              [(value)]="selectedDeviceConfigValues[i]"
              #selectElement
              (selectionChange)="
                updateConfig($event, element, selectElement.value, i)
              "
            >
              <mat-option>None</mat-option>
              <mat-option
                [ngStyle]="{ height: '6em' }"
                *ngFor="let c of element.configlist"
                [value]="c.id"
              >
                <b>{{ c.name }}</b>
                <br />
                ({{ c.created | date }})
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsSelected"></tr>
      <tr
        class="single-row"
        mat-row
        *matRowDef="let row; columns: displayedColumnsSelected"
      ></tr>
    </table>
  </mat-card>

  <mat-card id="selections">
    <mat-card-content>
      <div id="top-row">
        <div class="top-row-item">
          <input
            id="search-input"
            matInput
            (keyup)="applyFilter($event)"
            placeholder="Ex. 18000161"
            #input
          />
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <div class="mat-elevation-z8 scrollmenu">
    <mat-progress-bar
      mode="indeterminate"
      *ngIf="tableLoading"
    ></mat-progress-bar>
    <table mat-table [dataSource]="dataSource" matSort>
      <!-- Checkbox Column -->
      <ng-container matColumnDef="checked">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox
            [(ngModel)]="element.checked"
            (ngModelChange)="updateChecked($event, element.id)"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- Account Code Column -->
      <ng-container matColumnDef="account_code">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Account Code</th>
        <td mat-cell *matCellDef="let element">{{ element.account_code }}</td>
      </ng-container>

      <!-- Asset Name Column -->
      <ng-container matColumnDef="asset_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Asset Name</th>
        <td mat-cell *matCellDef="let element">{{ element.asset_name }}</td>
      </ng-container>

      <!-- Serial Number Column -->
      <ng-container matColumnDef="serial_number">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Serial Number</th>
        <td mat-cell *matCellDef="let element">
          <a
            class="a-left"
            href="javascript:void(0);"
            matTooltip="Click to view Asset/Device details"
            (click)="viewDeviceDialog(element)"
            >{{ element.serial_number }}
          </a>
        </td>
      </ng-container>

      <!-- VIN Number Column -->
      <ng-container matColumnDef="vin_number">
        <th mat-header-cell *matHeaderCellDef>VIN Number</th>
        <td mat-cell *matCellDef="let element">{{ element.vin_number }}</td>
      </ng-container>

      <!-- Firmware Version Column -->
      <ng-container matColumnDef="firmware_version">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Firmware Version
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.firmware_version }}
        </td>
      </ng-container>

      <!-- Current Config Column -->
      <ng-container matColumnDef="config_label">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          Current Config
        </th>
        <td mat-cell *matCellDef="let element">
          <span
            *ngIf="
              element.config_label === 'CONFIG NOT PUBLISHED' &&
              element.last_published === null
            "
            >CONFIG NOT PUBLISHED</span
          >
          <a
            class="a-left"
            matTooltip="Click to view Current Config"
            *ngIf="element.config_label !== 'CONFIG NOT PUBLISHED'"
            (click)="viewConfigDialog(element)"
            >{{ element.config_label }}</a
          >
          <br />
          <mat-hint *ngIf="element.last_published !== null">
            Modified:
            {{ element.last_published | date: 'MM/dd/yyyy HH:mm:ss' }}
          </mat-hint>
        </td>
      </ng-container>
      <!--      <ng-container matColumnDef="new_configs">-->
      <!--        <th mat-header-cell *matHeaderCellDef>Select New Config</th>-->
      <!--        <td mat-cell *matCellDef="let element; let i = index">-->
      <!--          <mat-form-field-->
      <!--            class="a-left-selector"-->
      <!--            id="select-form-field"-->
      <!--            appearance="fill"-->
      <!--          >-->
      <!--            <mat-label>Select Config</mat-label>-->
      <!--            <mat-select-->
      <!--              [id]="i"-->
      <!--              [(value)]="keyword[i]"-->
      <!--              #selectElement-->
      <!--              (selectionChange)="-->
      <!--                updateConfig($event, element, selectElement.value, i)-->
      <!--              "-->
      <!--            >-->
      <!--              <mat-option>None</mat-option>-->
      <!--              <mat-option-->
      <!--                [ngStyle]="{ height: '6em' }"-->
      <!--                *ngFor="let c of element.configlist"-->
      <!--                [value]="c.id"-->
      <!--              >-->
      <!--                <b>{{ c.name }}</b>-->
      <!--                <br />-->
      <!--                ({{ c.created | date }})-->
      <!--              </mat-option>-->
      <!--            </mat-select>-->
      <!--          </mat-form-field>-->
      <!--        </td>-->
      <!--      </ng-container>-->

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        class="single-row"
        mat-row
        *matRowDef="let row; columns: displayedColumns"
      ></tr>
    </table>
    <mat-paginator
      [pageSizeOptions]="[5, 10, 20]"
      showFirstLastButtons
      aria-label="Select page of periodic elements"
    >
    </mat-paginator>
  </div>
</mat-card>
