<mat-card>
  <div id="table-title">Package Config</div>

  <div id="refresh-container">
    <button
      mat-raised-button
      id="refresh-button"
      matTooltip="Add New Package Config"
      (click)="actionPackage('Create')"
    >
      <mat-icon>add</mat-icon>
      Add New
    </button>
    <button
      mat-raised-button
      id="refresh-button"
      (click)="reloadPackageConfigTable()"
      matTooltip="Refresh Config List"
    >
      <mat-icon>refresh</mat-icon>
    </button>

    <button
      class="export-button"
      mat-raised-button
      (click)="exportCSV(dataSource)"
    >
      Export CSV
    </button>
  </div>

  <mat-card id="selections">
    <mat-card-content>
      <div id="top-row">
        <div class="top-row-item">
          <input
            id="search-input"
            matInput
            (keyup)="applyFilter($event)"
            placeholder="Ex. Base package"
            #input
          />
        </div>

        <div class="top-row-item">
          <!--  <mat-select
            placeholder="Additional Selection"
            panelClass="my-select-panel-class"
          >
          </mat-select> -->
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <div class="mat-elevation-z8">
    <mat-progress-bar
      mode="indeterminate"
      *ngIf="tableLoading"
    ></mat-progress-bar>
    <table mat-table [dataSource]="dataSource" matSort>
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let element">
          <a
            class="a-left"
            mat-button
            href="javascript:void(0);"
            (click)="viewConfig(element.jsonConfig, element.base64)"
          >
            {{ element.name }}
          </a>
        </td>
      </ng-container>

      <!-- Dataset ID Column -->
      <ng-container matColumnDef="datasetId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Dataset ID</th>
        <td mat-cell *matCellDef="let element">
          <mat-list role="list">
            <mat-list-item
              role="listitem"
              *ngFor="let item of element.datasetId"
              >{{ item }}
            </mat-list-item>
          </mat-list>
        </td>
      </ng-container>

      <!-- Dataset Name Column -->
      <ng-container matColumnDef="datasetName" style="justify-content: left">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Dataset Name</th>
        <td mat-cell *matCellDef="let element">
          <mat-list role="list">
            <mat-list-item
              role="listitem"
              *ngFor="let dataset of element.datasetNames"
            >
              <mat-icon matListIcon style="transform: scale(0.5)"
                >fiber_manual_record</mat-icon
              >
              {{ dataset }}
            </mat-list-item>
          </mat-list>
        </td>
      </ng-container>

      <!-- Trigger ID Column -->
      <ng-container matColumnDef="triggerId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Trigger ID</th>
        <td mat-cell *matCellDef="let element">
          <mat-list role="list">
            <mat-list-item
              role="listitem"
              *ngFor="let item of element.triggerId"
              >{{ item }}
            </mat-list-item>
          </mat-list>
        </td>
      </ng-container>

      <!-- Trigger Name Column -->
      <ng-container matColumnDef="triggerName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Trigger Name</th>
        <td mat-cell *matCellDef="let element">
          <mat-list role="list">
            <mat-list-item
              role="listitem"
              *ngFor="let trigger of element.triggerNames"
            >
              <mat-icon matListIcon style="transform: scale(0.5)"
                >fiber_manual_record</mat-icon
              >
              {{ trigger }}
            </mat-list-item>
          </mat-list>
        </td>
      </ng-container>

      <!-- UID Column -->
      <ng-container matColumnDef="uid">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>UID</th>
        <td mat-cell *matCellDef="let element">
          {{ getRootUid(element.jsonConfig) }}
        </td>
      </ng-container>

      <!-- Creation Date Column -->
      <ng-container matColumnDef="creation_date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Creation Date</th>
        <td mat-cell *matCellDef="let element">
          {{ element.created | date: 'MM/dd/yyyy HH:mm:ss' }}
        </td>
      </ng-container>

      <!-- Created By Column -->
      <ng-container matColumnDef="created_by">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Created By</th>
        <td mat-cell *matCellDef="let element">{{ element.userEmail }}</td>
      </ng-container>

      <!-- Notes Column Column -->
      <ng-container matColumnDef="notes">
        <th mat-header-cell *matHeaderCellDef>Notes</th>
        <td mat-cell *matCellDef="let element">{{ element.description }}</td>
      </ng-container>

      <!-- Status Column Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let element">{{ element.status }}</td>
      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            color="accent"
            aria-label="Clone Package Config"
            matTooltip="Clone Package Config"
            (click)="actionPackage('Clone', element)"
          >
            <mat-icon style="color: #49555e">file_copy</mat-icon>
          </button>
          <!--           <button
            mat-icon-button
            color="accent"
            aria-label="Edit Package"
            (click)="cloneOrEditPackage(element.id, 'EDIT')"
          >
            <mat-icon>edit</mat-icon>
          </button> -->
          <!--  <button mat-icon-button color="accent" (click)="deletePackage()"
                                aria-label="Delete button with a menu icon">
                                <mat-icon>delete_forever</mat-icon>
                            </button> -->
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      [pageSizeOptions]="[5, 10, 20]"
      showFirstLastButtons
      aria-label="Select page of periodic elements"
    >
    </mat-paginator>
  </div>
</mat-card>
