<div class="container parent">
  <mat-card-title-group>
    <mat-card-title>{{ title }}</mat-card-title>
    <mat-card-subtitle>{{ subtitle }}</mat-card-subtitle>
    <button mat-icon-button aria-label="close dialog" mat-dialog-close>
      <img src="assets/images/close-icon.png" width="30" height="30" alt="" />
    </button>
  </mat-card-title-group>
  <hr />

  <mat-progress-bar mode="indeterminate" *ngIf="loader"> </mat-progress-bar>

  <div class="container">
    <div class="row">
      <div class="col">
        <table mat-table [dataSource]="dataSource" matSort>
          <ng-container [matColumnDef]="col" *ngFor="let col of columns">
            <th mat-header-cell *matHeaderCellDef>
              <strong class="title">{{ col | title }}</strong>
            </th>
            <td mat-cell *matCellDef="let element">
              <ng-container [ngSwitch]="col">
                <ng-container *ngSwitchCase="'config_label'">
                  <a
                    mat-button
                    (click)="
                      openJSONDialog(element.config_label, element.config_json)
                    "
                  >
                    {{ element.config_label }} </a
                  ><br />
                  <b>Version: </b>{{ element.config_vers }}
                </ng-container>
                <ng-container *ngSwitchCase="'selected_config_label'">
                  <a
                    mat-button
                    (click)="
                      openJSONDialog(
                        element.selected_config_label,
                        element.selected_config_json
                      )
                    "
                    >{{ element.selected_config_label }}</a
                  >
                </ng-container>
                <ng-container *ngSwitchDefault>
                  {{ element[col] }}
                </ng-container>
              </ng-container>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="columns"></tr>
          <tr mat-row *matRowDef="let row; columns: columns"></tr>
        </table>
      </div>
    </div>

    <div class="row mt-4" *ngIf="totalSize > 5">
      <div class="col">
        <mat-paginator
          [pageSize]="pageSize"
          [pageSizeOptions]="[5, 10, 20]"
          [showFirstLastButtons]="true"
          [length]="totalSize"
          [pageIndex]="currentPage"
          (page)="handlePage($event)"
        >
        </mat-paginator>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="btn-holder child">
    <button mat-stroked-button class="status-button" (click)="onAction()">
      {{ action }}
    </button>
    <button mat-stroked-button class="status-button" (click)="onClose()">
      Close
    </button>
  </div>
</div>
