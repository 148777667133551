<mat-card-title-group>
  <mat-card-title>View Config</mat-card-title>
  <button mat-icon-button aria-label="close dialog" mat-dialog-close>
    <img src="assets/images/close-icon.png" width="30" height="30" />
  </button>
</mat-card-title-group>
<hr />
<div mat-dialog-content>
  <b><u>Base64 Content</u></b>
</div>

<div>{{ base64 }}</div>
<br /><br />
<div mat-dialog-content>
  <b><u>JSON Content</u></b>
</div>

<div class="json-content">
  <pre *ngIf="configData.length > 0">
    <code [highlight]="configData | json"></code>
  </pre>
</div>
