<mat-card>
  <div id="table-title">Triggers</div>

  <div id="refresh-container">
    <button
      mat-raised-button
      id="refresh-button"
      (click)="actionTrigger('Create')"
      matTooltip="Add New Trigger"
    >
      <mat-icon>add</mat-icon>
      Add New
    </button>
    <button
      mat-raised-button
      id="refresh-button"
      matTooltip="Refresh Trigger List"
      (click)="reloadTrigger()"
    >
      <mat-icon>refresh</mat-icon>
    </button>

    <button
      class="export-button"
      mat-raised-button
      (click)="exportCSV(dataSource)"
    >
      Export CSV
    </button>
  </div>

  <!--     <div id="tabs">
        <div class="tab" [ngClass]="{'dark-underline': true}">
          All packages
        </div>
        <div class="tab"  [ngClass]="{'dark-underline': false}">
          Other packages
        </div>
      </div> -->

  <mat-card id="selections">
    <mat-card-content>
      <div id="top-row">
        <div class="top-row-item">
          <input
            id="search-input"
            matInput
            (keyup)="applyFilter($event)"
            placeholder="Ex. BASE DATA GPS"
            #input
          />
        </div>

        <div class="top-row-item">
          <!--  <mat-select
              placeholder="Additional Selection"
              panelClass="my-select-panel-class"
            >
            </mat-select> -->
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <div class="mat-elevation-z8">
    <mat-progress-bar
      mode="indeterminate"
      *ngIf="tableLoading"
    ></mat-progress-bar>
    <table mat-table [dataSource]="dataSource" matSort>
      <!-- Name Code Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let element">
          <a
            class="a-left"
            mat-button
            (click)="viewSignalConfig(element.signalConfig)"
            >{{ element.name }}
          </a>
        </td>
      </ng-container>

      <!-- Type Column Column -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
        <td mat-cell *matCellDef="let element">{{ element.type }}</td>
      </ng-container>

      <!-- Dataset Column Column -->
      <ng-container matColumnDef="datasetId">
        <th mat-header-cell *matHeaderCellDef>Dataset ID</th>
        <td mat-cell *matCellDef="let element">{{ element.datasetId }}</td>
      </ng-container>

      <!-- Signnal Column Column -->
      <ng-container matColumnDef="signalType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Signnal Type</th>
        <td mat-cell *matCellDef="let element">{{ element.signalType }}</td>
      </ng-container>

      <!-- Creation Date Column -->
      <ng-container matColumnDef="created">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Creation Date</th>
        <td mat-cell *matCellDef="let element">
          {{ element.created | date: 'MM/dd/yyyy HH:mm:ss' }}
        </td>
      </ng-container>

      <!-- Modified Date Column -->
      <ng-container matColumnDef="modified">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Modified Date</th>
        <td mat-cell *matCellDef="let element">
          {{ element.modified | date: 'MM/dd/yyyy HH:mm:ss' }}
        </td>
      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            color="accent"
            aria-label="Clone Trigger"
            matTooltip="Clone Trigger"
            (click)="actionTrigger('Clone', element)"
          >
            <mat-icon style="color: #49555e">file_copy</mat-icon>
          </button>
          <!--  <button
            mat-icon-button
            color="accent"
            aria-label="Edit Package"
            (click)="actionTrigger('Edit', element)"
          >
            <mat-icon>edit</mat-icon>
          </button> -->
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      [pageSizeOptions]="[5, 10, 20]"
      showFirstLastButtons
      aria-label="Select page of periodic elements"
    >
    </mat-paginator>
  </div>
</mat-card>
