interface RadioAction {
  name: string;
  action: string;
  disabled: boolean;
}

export const radioActionList: RadioAction[] = [
  {
    name: 'Provision',
    action: 'ADDED',
    disabled: true
  },
  {
    name: 'De-Provision',
    action: 'REMOVED',
    disabled: true
  }
];
