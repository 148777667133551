import { createReducer, on } from '@ngrx/store';
import { isLoading, stopLoading } from './loader.actions';

export interface State {
  isLoading: boolean;
}

const initState: State = {
  isLoading: false
};

// @ts-ignore
const LoaderReducer = createReducer(
  initState,
  on(isLoading, (state) => ({ state, isLoading: true })),
  on(stopLoading, (state) => ({ state, isLoading: false }))
);

// @ts-ignore
export function loaderReducer(state, action) {
  return LoaderReducer(state, action);
}
