import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'title'
})
export class TitlePipe implements PipeTransform {
  transform(value: string): string {
    if (value === 'id') {
      value = 'device_ID';
    }

    if (value === 'config_label') {
      value = 'current_Config';
    }

    if (value === 'selected_config_label') {
      value = 'new_Config';
    }

    return value.replace(/(?:_| |\b)(\w)/g, function ($1) {
      return $1.toUpperCase().replace('_', ' ');
    });
  }
}
