import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-schedule-modal',
  templateUrl: './schedule-modal.component.html',
  styleUrls: ['./schedule-modal.component.scss']
})
export class ScheduleModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ScheduleModalComponent>
  ) {}

  onClose(): void {
    this.dialogRef.close({ event: 'close' });
  }

  onSchedule() {
    this.dialogRef.close({ event: 'submit', data: this.data });
  }
}
