import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customFirmware'
})
export class CustomFirmwarePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value !== undefined && value !== null) {
      const index = value.indexOf(' ');
      if (index > 0) {
        const firmware = value.substring(0, index);
        const timeStamp = value.substring(index);
        return `${firmware}<br/>${timeStamp}`;
      }
    }
  }
}
