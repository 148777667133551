import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SignalTriggerMappingEditModalComponent } from '../signal-trigger-mapping-edit-modal/signal-trigger-mapping-edit-modal.component';
import { SignalTriggerMappingTransformerViewComponent } from '../singal-trigger-mapping-transformer-view/signal-trigger-mapping-transformer-view.component';
import { SignalTriggerMappingCreateModalComponent } from '../signal-trigger-mapping-create-modal/signal-trigger-mapping-create-modal.component';
import { CsvExportService } from '../csv-export.service';

export interface SignalTriggerMappingResponse {
  assetId: string;
  companyId: string;
  companyCode: string;
  triggerId: string;
  triggerName: string;
  datasetId: string;
  configId: string;
  logicFieldName: string;
  parsingLogic: ParsingLogic;
  status: string;
  id: string;
  created: string;
  modified: string;
}

export interface SignalTriggerMappingCreate {
  triggerName: string;
  logicFieldName: string;
  parsingLogic: ParsingLogic;
}

export interface ParsingLogic {
  parameters: Parameter[];
  transformer: Transformer;
  extra: Extra;
}

export interface Parameter {
  spn: number;
  pgn: number;
  pgnId: string;
  spnId: string;
}

export interface Transformer {
  name: string;
  arguments: Arguments;
}

export interface Arguments {}
export interface Extra {}

@Component({
  selector: 'zonar-asset-mapping-trigger',
  templateUrl: './signal-trigger-mapping.component.html',
  styleUrls: ['./signal-trigger-mapping.component.scss']
})
export class SignalTriggerMappingComponent implements OnInit {
  triggerNameSearchField = '';
  logicFieldNameSearchField = '';

  public searchResults: BehaviorSubject<SignalTriggerMappingResponse[]> =
    new BehaviorSubject<SignalTriggerMappingResponse[]>(
      [] as SignalTriggerMappingResponse[]
    );

  public displayedColumns: string[] = [
    'edit',
    'triggerName',
    'logicFieldName',
    'status',
    'id',
    'created',
    'modified',
    'spn',
    'transformer'
  ];

  constructor(
    private httpClient: HttpClient,
    private editDialogRef: MatDialogRef<SignalTriggerMappingEditModalComponent>,
    private transformerDialogRef: MatDialogRef<SignalTriggerMappingTransformerViewComponent>,
    private createDialogRef: MatDialogRef<SignalTriggerMappingCreateModalComponent>,
    private dialog: MatDialog,
    private csvExport: CsvExportService
  ) {}

  ngOnInit() {
    this.fetchAndUpdateTriggers();
  }

  private fetchAndUpdateTriggers(): void {
    this.httpClient
      .get<SignalTriggerMappingResponse[]>(
        environment.assetTriggerMappingsSearch
      )
      .subscribe((searchResult: SignalTriggerMappingResponse[]) => {
        this.searchResults.next(
          searchResult.sort(
            (a, b) =>
              new Date(b.created).getTime() - new Date(a.created).getTime()
          )
        );
      });
  }

  public executeSearch(): void {
    let params = new HttpParams();

    if (this.triggerNameSearchField) {
      params = params.set('triggerName', this.triggerNameSearchField);
    }

    if (this.logicFieldNameSearchField) {
      params = params.set('logicFieldName', this.logicFieldNameSearchField);
    }

    this.httpClient
      .get<SignalTriggerMappingResponse[]>(
        environment.assetTriggerMappingsSearch,
        { params }
      )
      .subscribe((searchResult: SignalTriggerMappingResponse[]) => {
        this.searchResults.next(
          searchResult.sort(
            (a, b) =>
              new Date(b.created).getTime() - new Date(a.created).getTime()
          )
        );
      });
  }

  public editCellClick(element: any): void {
    this.editDialogRef = this.dialog.open(
      SignalTriggerMappingEditModalComponent,
      {
        data: element,
        width: '60%'
      }
    );

    this.editDialogRef.afterClosed().subscribe((result) => {
      if (result === undefined || !result.updated) {
        //   No updates made
      } else {
        this.fetchAndUpdateTriggers();
      }
    });
  }

  public transformerCellClick(element: any): void {
    this.transformerDialogRef = this.dialog.open(
      SignalTriggerMappingTransformerViewComponent,
      {
        data: element,
        width: '60%'
      }
    );
  }

  public createTriggerClick(): void {
    this.createDialogRef = this.dialog.open(
      SignalTriggerMappingCreateModalComponent,
      {
        width: '60%'
      }
    );

    this.createDialogRef.afterClosed().subscribe((result) => {
      if (result === undefined || !result.updated) {
        //   No updates made
      } else {
        this.fetchAndUpdateTriggers();
      }
    });
  }

  public exportCSV(data: any): void {
    console.log(data);
    this.csvExport.exportCSV(data);
  }
}
