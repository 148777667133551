import { Injectable } from '@angular/core';
import {
  Router,
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DatasetApiService } from '../_services/dataset-api.service';

@Injectable({
  providedIn: 'root'
})
export class DatasetResolver implements Resolve<any> {
  constructor(private datasetApiService: DatasetApiService) {}

  async resolve(route: ActivatedRouteSnapshot): Promise<Observable<any>> {
    return (await this.datasetApiService.getAllDatasets()).pipe(
      catchError(() => {
        return EMPTY;
      })
    );
  }
}
