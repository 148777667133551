<!-- <div class="container mt-5" *ngIf="auth.user$ | async as user">
  <div
    class="row align-items-center profile-header mb-5 text-center text-md-left"
  >
    <div class="col-md-2">
      <img
        [src]="user.picture"
        class="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
      />
    </div>
    <div class="col-md">
      <h2>{{ user.name }}</h2>
      <p class="lead text-muted">{{ user.email }}</p>
    </div>
  </div>

  <div class="row" *ngIf="profileJson">
    <pre
      class="rounded"><code class="json" [highlight]="profileJson"></code></pre>
  </div>
</div> -->

<mat-card class="example-card" *ngIf="auth.user$ | async as user">
  <mat-card-title-group>
    <mat-card-title
      >{{ user.family_name }} {{ user.given_name }}</mat-card-title
    >
    <mat-card-subtitle>{{ user.email }}</mat-card-subtitle>
    <img
      mat-card-lg-image
      [src]="user.picture"
      class="rounded-circle img-fluid profile-picture"
    />
  </mat-card-title-group>
  <mat-card-content>
    <div class="row" *ngIf="profileJson">
      <pre
        class="rounded"
      ><code class="json" [highlight]="profileJson"></code></pre>
    </div>
  </mat-card-content>
</mat-card>
