import { Component, OnDestroy, OnInit } from '@angular/core';
import { IoTActiveCompanyModel } from '../../core/_models/IoTActiveCompanyModel';
import { BehaviorSubject, forkJoin, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { WhitelistDialogComponent } from '../../whitelist-dialog/whitelist-dialog.component';
import { ZonarUITableModule } from '@zonar-ui/table';
import { ColumnDefinitionsService } from '../../core/_services/column-definitions.service';
import { WhiteListService } from '../../core/_services/white-list.service';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-whitelisting',
  templateUrl: './whitelisting.component.html',
  styleUrls: ['./whitelisting.component.scss']
})
export class WhitelistingComponent implements OnInit, OnDestroy {
  iotActiveCompanies: IoTActiveCompanyModel[] = [];
  searchQuery: string;
  loader: boolean;
  searchResults = [];
  checkedResults = [];
  searchTimeout;
  dataSource = [];
  updateWhiteListSub: Subscription;
  addWhiteListSub: Subscription;
  public columnStyles = {
    companyName: { 'justify-content': 'start' }
  };

  public columns$ = new BehaviorSubject(null);
  private columns: ZonarUITableModule[];
  constructor(
    public dialog: MatDialog,
    private columnDefinitions: ColumnDefinitionsService,
    public whiteListService: WhiteListService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.updateActiveIOTCompanies();
    this.route.queryParamMap.subscribe((params) => {
      this.columns = this.columnDefinitions.getColumnDefinitions();
      this.columns$.next(this.columns);
    });
  }

  ngOnDestroy(): void {
    this.updateWhiteListSub?.unsubscribe();
    this.addWhiteListSub?.unsubscribe();
  }

  updateActiveIOTCompanies() {
    this.updateWhiteListSub = this.whiteListService
      .activeIOTCompaniesUpdate()
      .subscribe(
        (activeCompanies: IoTActiveCompanyModel[]) => {
          activeCompanies.sort((a, b) =>
            a.companyName.localeCompare(b.companyName)
          );
          this.iotActiveCompanies = activeCompanies;
        },
        (err) => {
          this.messageHandler(
            'error',
            'Fetching error',
            'There is some error fetching list of companies.'
          );
        },
        () => {
          this.dataSource = this.iotActiveCompanies;
        }
      );
  }

  public addOrRemoveCompanyToWhitelist(event: any, result: any): void {
    if (event.target.checked) {
      this.checkedResults.push(result);
    } else {
      this.checkedResults = this.checkedResults.filter((checkedResult) => {
        return result.id !== checkedResult.id;
      });
    }
  }

  public applyAllWhitelistAdditions(): void {
    const dialogRef = this.dialog.open(WhitelistDialogComponent, {
      height: '60%',
      width: '50%'
    });

    dialogRef.afterClosed().subscribe((userSelection) => {
      if (userSelection) {
        this.checkedResults.forEach((result) => {
          this.addToWhitelist(result);
          this.searchResults = this.searchResults.filter((searchResult) => {
            return searchResult.id !== result.id;
          });
          result.companyName = result.name;
          this.iotActiveCompanies.push(result);
          this.iotActiveCompanies = this.iotActiveCompanies.sort((a, b) =>
            a.companyName.localeCompare(b.companyName)
          );
        });

        this.checkedResults = [];
      }
    });
  }

  public addToWhitelist(result): void {
    this.addWhiteListSub = this.whiteListService
      .addToWhitelist(result)
      .subscribe((res) => {
        this.updateActiveIOTCompanies();
      });
  }

  public searchInputChange(): void {
    clearTimeout(this.searchTimeout);
    this.checkedResults = [];

    this.searchTimeout = setTimeout(() => {
      if (this.searchQuery.trim().length > 0) {
        this.executeSearch();
      }
    }, 500);
  }

  public executeSearch() {
    this.checkedResults = [];
    this.searchResults = [];

    const query = this.searchQuery.trim().toLowerCase();
    const nameSearch = this.whiteListService.nameSearch(query);
    const accountCodeSearch = this.whiteListService.accountCodeSearch(query);

    forkJoin([nameSearch, accountCodeSearch]).subscribe((results: any) => {
      const iotCompanyNames = this.iotActiveCompanies
        ? this.iotActiveCompanies.map((company) => {
            return company.companyName.trim().toLowerCase();
          })
        : [];

      this.searchResults = results[0]
        .concat(results[1])
        .filter((filterResult) => {
          return (
            filterResult.status === 'ACTIVE' &&
            iotCompanyNames.indexOf(filterResult.name.toLowerCase().trim()) ===
              -1
          );
        })
        .filter((obj, index, self) => {
          return (
            index ===
            self.findIndex(
              (o) =>
                o.name.trim().toLowerCase() === obj.name.trim().toLowerCase()
            )
          );
        })
        .sort((a, b) => a.name.localeCompare(b.name));

      console.log(this.searchResults);
    });
  }

  messageHandler(icon: SweetAlertIcon, title: string, message: string) {
    Swal.fire({
      icon,
      title,
      text: message,
      showConfirmButton: false,
      timer: 1500
    });
  }
}
