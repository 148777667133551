import { environment } from '../../../environments/environment';

export const CONFIG_URL = `${environment.iotcoreapi}/configs`;
export const PACKAGE_CONFIG_URL = `${environment.packageapi}`;
export const PUBLISH_CONFIG_URL = '/account/publish-config';
export const ASSET_API_URL = `${environment.serviceapi}/assets`;
export const DEVICE_API_URL = `${environment.serviceapi}/devices`;
export const VALIDATION_API_URL = `${environment.validationapi}`;

export const QUERY_PARAM_PER_PAGE = 'per_page';
export const QUERY_PARAM_PER_PAGE_VALUE = '500';

export const DEVICE_DIALOG_HEIGHT = '600px';
export const DEVICE_DIALOG_WIDTH = '800px';

export const PUBLISH_CONFIG_HEIGHT = '600px';
export const PUBLISH_CONFIG_WIDTH = '600px';

export const VIEW_CONFIG_HEIGHT = '500px';
export const VIEW_CONFIG_WIDTH = '500px';

export const CUSTOMER_NAME_KEY = 'CUSTOMER_NAME';
export const CUSTOMER_ACCOUNT_CODE_KEY = 'CUSTOMER_ACCOUNT_CODE';

// TODO: Possibly derive these from object attributes
export const DISPLAYED_COLUMNS: string[] = [
  'asset_name',
  'serial_number',
  'vin_number',
  'firmware_version',
  'last_heartbeat_time',
  'last_event_time',
  'last_config_send_time',
  'last_config_ack_time',
  'config_label',
  'config_status'
];
