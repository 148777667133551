import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalVariables } from 'src/app/core/_common/GlobalVariables';
import { DatasetModel } from 'src/app/core/_models/DatasetModel';
import { PGNModel } from 'src/app/core/_models/PGNModel';
import { DatadogService } from 'src/app/core/_services/datadog.service';
import { TriggerApiService } from 'src/app/core/_services/trigger-api.service';
import {
  TYPE_LIST,
  OPERATORS,
  SIGNAL_TYPE_LIST,
  CHANNEL_LIST,
  EVENT_TYPE
} from './../constants';

@Component({
  selector: 'app-create-trigger',
  templateUrl: './create-trigger.component.html',
  styleUrls: ['./create-trigger.component.scss']
})
export class CreateTriggerComponent implements OnInit {
  loading = false;
  triggerForm: FormGroup;
  datasetList: any = [];
  typeList = TYPE_LIST;
  signalTypeList = SIGNAL_TYPE_LIST;
  operators = OPERATORS;
  datasetConfigView: any;
  eventType = EVENT_TYPE;
  eventTypeList = ['EVENT_ON', 'EVENT_OFF'];
  eventTypeGPSLogEvent = ['EVENT_POINT_LOGGED'];
  eventTypeListTripEvent = ['EVENT_START', 'EVENT_END'];
  eventTypeListFaultEvent = ['EVENT_ACTIVE', 'EVENT_INACTIVE', 'EVENT_CLEARED'];
  eventTypeListDigitalInputEvent = [
    'EVENT_INPUT1',
    'EVENT_INPUT2',
    'EVENT_INPUT3',
    'EVENT_INPUT4',
    'EVENT_INPUT5',
    'EVENT_INPUT_IGN',
    'EVENT_INPUT_PANIC'
  ];
  activeStateType = ['ACTIVE', 'INACTIVE'];
  segmentTypeList = ['SEG_START', 'SEG_END'];
  segmentTypeListEngineRunSegment = ['SEG_ON', 'SEG_OFF'];
  segmentTypeListFaultSegment = ['SEG_ACTIVE'];
  segmentTypeListDigitalInput = [
    'SEG_INPUT1',
    'SEG_INPUT2',
    'SEG_INPUT3',
    'SEG_INPUT4',
    'SEG_INPUT5',
    'SEG_INPUT_IGN',
    'SEG_INPUT_PANIC'
  ];
  channelTypeList = CHANNEL_LIST;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private triggerApiService: TriggerApiService,
    public dialogRef: MatDialogRef<CreateTriggerComponent>,
    private datadogService: DatadogService
  ) {}

  createTriggerForm() {
    this.triggerForm = new FormGroup({
      triggerNameFormControl: new FormControl(),
      descriptionFormControl: new FormControl(),
      typeFormControl: new FormControl(),
      datasetFormControl: new FormControl(),
      signalTypeFormControl: new FormControl(),
      rearmTimeFormControl: new FormControl(),
      eventTypeFormControl: new FormControl(),
      segmentTypeFormControl: new FormControl(),
      sampleTimeFormControl: new FormControl(),
      operatorFormControl: new FormControl(),
      pgnFormControl: new FormControl(),
      spnFormControl: new FormControl(),
      sourceFormControl: new FormControl(),
      channelFormControl: new FormControl(),
      bitLengthFormControl: new FormControl(),
      comparedValueFormControl: new FormControl(),
      offsetFormControl: new FormControl(),
      activeStateTypeFormControl: new FormControl()
    });
  }

  ngOnInit(): void {
    this.datadogService.log(
      `${GlobalVariables.USER_EMAIL} accessed Create-Trigger Page`,
      {
        name: 'Create-Trigger Component',
        id: 10083,
        userName: GlobalVariables.USER_GIVEN_NAME,
        userEmail: GlobalVariables.USER_EMAIL
      },
      'info'
    );

    this.datasetList = this.dialogData.dataset;
    this.createTriggerForm();
  }

  async onSubmit() {
    this.datadogService.log(
      `${GlobalVariables.USER_EMAIL} clicked "Create/Clone" button in "Create-Trigger" Page`,
      {
        name: 'Create/Clone - Create-Trigger Component',
        id: 10084,
        userName: GlobalVariables.USER_GIVEN_NAME,
        userEmail: GlobalVariables.USER_EMAIL
      },
      'info'
    );

    console.log('new create');

    const jsonBody: any = this.signalTypeBodyProcess();

    this.triggerApiService
      .createTrigger(jsonBody)
      .toPromise()
      .then((response) => {
        this.datadogService.log(
          `${GlobalVariables.USER_EMAIL}  created/cloned trigger`,
          {
            name: 'Trigger Component',
            id: 10085,
            userName: GlobalVariables.USER_GIVEN_NAME,
            userEmail: GlobalVariables.USER_EMAIL
          },
          'info'
        );
        this.closeDialog(true);
      })
      .catch((err) => {
        console.log('Err: ', err);
      });
  }

  async closeDialog(success = false) {
    this.dialogRef.close({ success: success });
  }

  onDatasetSelect(dataset: any) {
    const data = {
      sourceId: dataset.sourceId,
      gpsDataConfig: dataset.gpsDataConfig,
      digitalInputConfig: dataset.digitalInputConfig,
      accelerometerConfig: dataset.accelerometerConfig,
      appConfig: dataset.appConfig,
      analyticsConfig: dataset.analyticsConfig,
      j1939Configs: dataset.j1939Configs,
      j1587Configs: dataset.j1587Configs,
      obd2Configs: dataset.obd2Configs
    };

    this.datasetConfigView = JSON.stringify(data, null, 2);
  }

  signalTypeBodyProcess(useBlank = false): any {
    let jsonBody: any = {
      name: this.triggerForm.controls.triggerNameFormControl.value,
      description:
        this.triggerForm.controls.descriptionFormControl.value == null
          ? ''
          : this.triggerForm.controls.descriptionFormControl.value,
      type: this.triggerForm.controls.typeFormControl.value,
      datasetId: this.triggerForm.controls.datasetFormControl.value,
      signalType: this.triggerForm.controls.signalTypeFormControl.value,
      signalConfig: {
        dtcConfig: {
          source: parseInt(
            this.triggerForm.controls.sourceFormControl.value,
            10
          ),
          allowAnyFault: true
        },
        activeStateType:
          this.triggerForm.controls.activeStateTypeFormControl.value,
        segmentType: this.triggerForm.controls.segmentTypeFormControl.value,
        spn: parseInt(this.triggerForm.controls.spnFormControl.value, 10),
        eventType: this.triggerForm.controls.eventTypeFormControl.value,
        pgn: parseInt(this.triggerForm.controls.pgnFormControl.value, 10),
        source: parseInt(this.triggerForm.controls.sourceFormControl.value, 10),
        channel: this.triggerForm.controls.channelFormControl.value,
        offset: this.triggerForm.controls.offsetFormControl.value,
        length: parseInt(
          this.triggerForm.controls.bitLengthFormControl.value,
          10
        ),
        samplingConfig: {
          rearmTime: parseInt(
            this.triggerForm.controls.rearmTimeFormControl.value,
            10
          ),
          sampleTime: parseInt(
            this.triggerForm.controls.sampleTimeFormControl.value,
            10
          )
        },
        operationConfig: {
          operator: this.triggerForm.controls.operatorFormControl.value,
          comparedValue:
            this.triggerForm.controls.comparedValueFormControl.value
        }
      }
    };

    if (useBlank) {
      jsonBody = {
        name: '',
        description: '',
        type: '',
        datasetId: '',
        signalType: '',
        signalConfig: {
          dtcConfig: {
            source: '',
            allowAnyFault: ''
          },
          activeStateType: '',
          segmentType: '',
          spn: '',
          eventType: '',
          pgn: '',
          source: '',
          channel: '',
          offset: '',
          length: '',
          samplingConfig: {
            rearmTime: '',
            sampleTime: ''
          },
          operationConfig: {
            operator: '',
            comparedValue: ''
          }
        }
      };
    }

    switch (this.triggerForm.controls.signalTypeFormControl.value) {
      case 'GPS_LOG_EVENT':
        delete jsonBody.signalConfig.dtcConfig;
        delete jsonBody.signalConfig.activeStateType;
        delete jsonBody.signalConfig.spn;
        delete jsonBody.signalConfig.segmentType;
        delete jsonBody.signalConfig.samplingConfig.sampleTime;
        delete jsonBody.signalConfig.channel;
        delete jsonBody.signalConfig.length;
        delete jsonBody.signalConfig.offset;
        delete jsonBody.signalConfig.operationConfig;
        delete jsonBody.signalConfig.pgn;
        delete jsonBody.signalConfig.source;
        break;

      case 'ENGINE_RUN_EVENT':
        delete jsonBody.signalConfig.dtcConfig;
        delete jsonBody.signalConfig.activeStateType;
        delete jsonBody.signalConfig.spn;
        delete jsonBody.signalConfig.segmentType;
        delete jsonBody.signalConfig.samplingConfig.sampleTime;
        delete jsonBody.signalConfig.channel;
        delete jsonBody.signalConfig.length;
        delete jsonBody.signalConfig.offset;
        delete jsonBody.signalConfig.operationConfig;
        delete jsonBody.signalConfig.pgn;
        delete jsonBody.signalConfig.source;
        break;

      case 'PGN_SEGMENT':
        delete jsonBody.signalConfig.dtcConfig;
        delete jsonBody.signalConfig.activeStateType;
        delete jsonBody.signalConfig.spn;
        delete jsonBody.signalConfig.segmentType;
        delete jsonBody.signalConfig.eventType;
        break;

      case 'PGN_EVENT':
        delete jsonBody.signalConfig.dtcConfig;
        delete jsonBody.signalConfig.activeStateType;
        delete jsonBody.signalConfig.spn;
        delete jsonBody.signalConfig.segmentType;
        delete jsonBody.signalConfig.samplingConfig.sampleTime;
        delete jsonBody.signalConfig.eventType;
        break;

      case 'BUILTIN_SPN_SEGMENT':
        delete jsonBody.signalConfig.dtcConfig;
        delete jsonBody.signalConfig.activeStateType;
        delete jsonBody.signalConfig.eventType;
        delete jsonBody.signalConfig.segmentType;
        delete jsonBody.signalConfig.pgn;
        break;

      case 'BUILTIN_SPN_EVENT':
        delete jsonBody.signalConfig.dtcConfig;
        delete jsonBody.signalConfig.activeStateType;
        delete jsonBody.signalConfig.segmentType;
        delete jsonBody.signalConfig.samplingConfig.sampleTime;
        delete jsonBody.signalConfig.eventType;
        delete jsonBody.signalConfig.pgn;
        break;

      case 'TRIP_SEGMENT':
        delete jsonBody.signalConfig.dtcConfig;
        delete jsonBody.signalConfig.activeStateType;
        delete jsonBody.signalConfig.channel;
        delete jsonBody.signalConfig.eventType;
        delete jsonBody.signalConfig.length;
        delete jsonBody.signalConfig.offset;
        delete jsonBody.signalConfig.operationConfig;
        delete jsonBody.signalConfig.pgn;
        delete jsonBody.signalConfig.source;
        delete jsonBody.signalConfig.spn;
        break;

      case 'TRIP_EVENT':
        delete jsonBody.signalConfig.dtcConfig;
        delete jsonBody.signalConfig.activeStateType;
        delete jsonBody.signalConfig.samplingConfig.sampleTime;
        delete jsonBody.signalConfig.segmentType;
        delete jsonBody.signalConfig.channel;
        delete jsonBody.signalConfig.length;
        delete jsonBody.signalConfig.offset;
        delete jsonBody.signalConfig.pgn;
        delete jsonBody.signalConfig.source;
        delete jsonBody.signalConfig.spn;
        delete jsonBody.signalConfig.operationConfig;
        break;

      case 'ENGINE_RUN_SEGMENT':
        delete jsonBody.signalConfig.dtcConfig;
        delete jsonBody.signalConfig.activeStateType;
        delete jsonBody.signalConfig.channel;
        delete jsonBody.signalConfig.eventType;
        delete jsonBody.signalConfig.length;
        delete jsonBody.signalConfig.offset;
        delete jsonBody.signalConfig.operationConfig;
        delete jsonBody.signalConfig.pgn;
        delete jsonBody.signalConfig.source;
        delete jsonBody.signalConfig.spn;
        break;

      case 'FAULT_SEGMENT':
        delete jsonBody.signalConfig.eventType;
        delete jsonBody.signalConfig.activeStateType;
        delete jsonBody.signalConfig.length;
        delete jsonBody.signalConfig.offset;
        delete jsonBody.signalConfig.operationConfig;
        delete jsonBody.signalConfig.pgn;
        delete jsonBody.signalConfig.source;
        delete jsonBody.signalConfig.spn;
        break;

      case 'FAULT_EVENT':
        delete jsonBody.signalConfig.length;
        delete jsonBody.signalConfig.activeStateType;
        delete jsonBody.signalConfig.segmentType;
        delete jsonBody.signalConfig.offset;
        delete jsonBody.signalConfig.operationConfig;
        delete jsonBody.signalConfig.pgn;
        delete jsonBody.signalConfig.source;
        delete jsonBody.signalConfig.spn;
        break;

      case 'DIGITAL_INPUT_SEGMENT':
        delete jsonBody.signalConfig.dtcConfig;
        delete jsonBody.signalConfig.channel;
        delete jsonBody.signalConfig.eventType;
        delete jsonBody.signalConfig.length;
        delete jsonBody.signalConfig.offset;
        delete jsonBody.signalConfig.operationConfig;
        delete jsonBody.signalConfig.pgn;
        delete jsonBody.signalConfig.source;
        delete jsonBody.signalConfig.spn;
        break;

      case 'DIGITAL_INPUT_EVENT':
        delete jsonBody.signalConfig.dtcConfig;
        delete jsonBody.signalConfig.samplingConfig.sampleTime;
        delete jsonBody.signalConfig.segmentType;
        delete jsonBody.signalConfig.channel;
        delete jsonBody.signalConfig.length;
        delete jsonBody.signalConfig.offset;
        delete jsonBody.signalConfig.operationConfig;
        delete jsonBody.signalConfig.pgn;
        delete jsonBody.signalConfig.source;
        delete jsonBody.signalConfig.spn;
        break;

      default:
        break;
    }

    return jsonBody;
  }

  public dummyJsonBody: any;
  signalTypeChangeAction(event: any) {
    this.dummyJsonBody = this.signalTypeBodyProcess(true);
  }

  public displayField(field: string): boolean {
    try {
      const splitFields = field.split('.');

      if (splitFields.length === 1) {
        return this.dummyJsonBody['signalConfig'][splitFields[0]] !== undefined;
      }

      return (
        this.dummyJsonBody['signalConfig'][splitFields[0]][splitFields[1]] !==
        undefined
      );
    } catch (e) {
      return false;
    }
  }

  public operatorLabel(operatorValue: string): string {
    switch (operatorValue) {
      case 'OP_EQUAL_TO':
        return 'Equal To';
      case 'OP_NOT_EQUAL_TO':
        return 'Not Equal To';
      case 'OP_LESS_THAN':
        return 'Less Than';
      case 'OP_LESS_THAN_EQUAL_TO':
        return 'Less Than Equal To';
      case 'OP_GREATER_THAN':
        return 'Greater Than';
      case 'OP_GREATER_THAN_EQUAL_TO':
        return 'Greater Than Equal To';
      default:
        return operatorValue;
    }
  }

  public get activeEventTypeList(): string[] {
    switch (this.triggerForm.controls.signalTypeFormControl.value) {
      case 'GPS_LOG_EVENT':
        return this.eventTypeGPSLogEvent;

      case 'TRIP_EVENT':
        return this.eventTypeListTripEvent;

      case 'FAULT_EVENT':
        return this.eventTypeListFaultEvent;

      case 'DIGITAL_INPUT_EVENT':
        return this.eventTypeListDigitalInputEvent;

      default:
        return this.eventTypeList;
    }
  }

  public get activeSegmentTypeList(): string[] {
    switch (this.triggerForm.controls.signalTypeFormControl.value) {
      case 'DIGITAL_INPUT_SEGMENT':
        return this.segmentTypeListDigitalInput;

      case 'ENGINE_RUN_SEGMENT':
        return this.segmentTypeListEngineRunSegment;

      case 'FAULT_SEGMENT':
        return this.segmentTypeListFaultSegment;

      default:
        return this.segmentTypeList;
    }
  }

  public disableCreate(): boolean {
    if (
      !this.triggerForm.controls.datasetFormControl ||
      !this.triggerForm.controls.signalTypeFormControl.value
    ) {
      return true;
    }

    return false;
  }
}
