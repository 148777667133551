import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DeviceConfigMapping } from 'src/app/core/_models/DeviceConfigMapping';

@Component({
  selector: 'app-view-device-dialog',
  templateUrl: './view-device-dialog.component.html',
  styleUrls: ['./view-device-dialog.component.scss']
})
export class ViewDeviceDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DeviceConfigMapping,
    public dialogRef: MatDialogRef<ViewDeviceDialogComponent>
  ) {}

  ngOnInit(): void {}

  onClose(): void {
    this.dialogRef.close();
  }
}
