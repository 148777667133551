import { Injectable } from '@angular/core';

import { Parser } from '@json2csv/plainjs';

@Injectable({
  providedIn: 'root'
})
export class CsvExportService {
  constructor() {}

  public exportCSV(passedData: any): void {
    try {
      let data = JSON.parse(JSON.stringify(passedData));
      data = this.replaceNullsWithNA(data);

      const parser = new Parser();
      const csv = parser.parse(data);

      const blob = new Blob([csv], { type: 'text/csv' });

      const link = document.createElement('a');
      link.download = 'data.csv';
      link.href = window.URL.createObjectURL(blob);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      console.error(err);
    }
  }

  private replaceNullsWithNA(arr): any {
    arr.forEach((obj) => {
      for (const key in obj) {
        if (obj[key] === null) {
          obj[key] = 'N/A';
        }
      }
    });

    return arr;
  }
}
