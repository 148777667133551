import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { PackageConfigModel } from '../_models/PackageConfigModel';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { forkJoin, Subscription } from 'rxjs';
import { IoTDeviceModel } from '../_models/IoTDeviceModel';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.state';
import { AssetModel } from '../_models/AssetModel';
import { DeviceConfigMapping } from '../_models/DeviceConfigMapping';
import { DeviceAssociationModel } from '../_models/DeviceAssociationModel';
import {
  addDataSource,
  cleanDataSource
} from '../../shared/store/data-source.actions';
import { set, getMany } from 'idb-keyval';
import { DEVICE_CONFIG_MAPPING_KEY } from '../../pages/publishconfig/constants';
import { DeviceDetailsModel, DeviceModel } from '../_models/DeviceModel';
import * as actions from '../../shared/store/divisions-assets-devices.actions';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import * as ui from '../../shared/store/loader.actions';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DataSourceMappedService implements OnDestroy {
  configList: any[] = [];
  assetsList: AssetModel[] = [];
  deviceList: DeviceModel[] = [];
  packageList: PackageConfigModel[] = [];
  deviceCoreDetailList: DeviceDetailsModel[] = [];
  deviceAssociationList: DeviceAssociationModel[] = [];
  newEntryTempArray: DeviceConfigMapping[] = [];
  lastRebootPhoneHomeData: any = [];
  newEntry: any = [];
  accountCode = '';
  companyId = '';
  joined$;

  pages = 1;
  assetResponse: AssetModel;
  accountSub: Subscription;
  assetsSub: Subscription;
  devicesSub: Subscription;
  getMoreDeviceCoreDetailsSub: Subscription;

  constructor(
    private httpClient: HttpClient,
    private datepipe: DatePipe,
    private store: Store<AppState>
  ) {}

  ngOnDestroy() {
    this.accountSub?.unsubscribe();
    this.assetsSub?.unsubscribe();
    this.devicesSub?.unsubscribe();
    this.getMoreDeviceCoreDetailsSub?.unsubscribe();
  }

  getAllDataMapped() {
    getMany(['Assets', 'Devices']).then(([assets, devices]) => {
      if (assets) {
        this.assetsList = assets;
      }
      if (devices) {
        this.deviceList = devices;
        this.mappingData(this.deviceList);
      }
    });

    this.getAccountDetails();
    this.getLastRebootPhoneHome();

    this.joined$ = forkJoin([
      this.getDeviceAssociation(),
      this.getAllPackageConfigs(),
      this.getDeviceCoreDetails(this.pages)
    ]);

    this.joined$.subscribe(
      ([deviceAssociation, packageList, deviceCoreDetails]) => {
        if (deviceAssociation.length > 0) {
          this.deviceAssociationList = deviceAssociation;
        }

        if (packageList.length > 0) {
          this.generatingConfigList(packageList);
          this.packageList = packageList;
          localStorage.setItem(
            'PACKAGE_CONFIG_LIST',
            JSON.stringify(this.packageList)
          );

          this.pages = deviceCoreDetails.headers.get('X-Page-Count');
          if (deviceCoreDetails.body.length > 0 && this.pages > 1) {
            this.getMoreDeviceCoreDetails();
          } else {
            this.deviceCoreDetailList = deviceCoreDetails.body;
            this.mappingNewEntryDeviceCoreDetailList(this.deviceCoreDetailList);
          }
        }

        if (this.newEntryTempArray.length > 0) {
          if (this.lastRebootPhoneHomeData.length > 0) {
            this.mappingNewEntryLastRebootPhoneHomeData(this.newEntryTempArray);
          } else {
            this.dispatchDataSource(this.newEntryTempArray);
          }
        } else {
          this.messageHandler(
            'info',
            'No data found.',
            'No devices provisioned were found for this account.'
          );

          this.store.dispatch(ui.stopLoading());
        }
      }
    );
  }

  /**
   * getAccountDetails()
   * retrieve the account code and company id from the store
   */
  getAccountDetails() {
    this.accountSub = this.store.select('account').subscribe((res) => {
      if (res.account?.length > 0) {
        this.accountCode = res.account[0]['account'];
        this.companyId = res.account[0]['company'];
      }
    });
  }

  /**
   * getLastRebootPhoneHome()
   * retrieve last reboot and phone home data from the API and map them using this.mappingNewEntryLastRebootPhoneHomeData()
   */
  getLastRebootPhoneHome() {
    return this.httpClient
      .get(
        `${environment.lastRebootAPI}/settings/device-mapping-data?companyId=${this.companyId}&accountCode=${this.accountCode}&fields=lastReboot,lastPhoneHome`
      )
      .subscribe((data: any) => {
        if (data.length > 0) {
          this.lastRebootPhoneHomeData = data;
        }
      });
  }

  /**
   * getDeviceCoreDetails()
   * retrieve device core details from the API and map them using this.mappingNewEntryDeviceCoreDetailList()
   */
  getDeviceCoreDetails(page: number) {
    return this.httpClient.get<IoTDeviceModel[]>(
      `${environment.iotcoreapi}/devices?fieldMask=lastHeartbeatTime,lastEventTime,lastConfigSendTime,lastConfigAckTime&page=${page}&per_page=1000`,
      { observe: 'response' }
    );
  }

  /**
   * getMoreDeviceCoreDetails()
   * retrieve more device core details from the API
   */

  getMoreDeviceCoreDetails() {
    const arrayOfData = [];
    for (let i = 1; i <= this.pages; i++) {
      arrayOfData.push(this.getDeviceCoreDetails(i));
    }
    this.getMoreDeviceCoreDetailsSub = forkJoin(arrayOfData)
      .pipe(
        // @ts-ignore
        map((results) => results.reduce((all, itm) => all.concat(itm.body), []))
      )
      .subscribe(
        (data: any) => {
          if (data.length > 0) {
            this.deviceCoreDetailList = data;
            this.mappingNewEntryDeviceCoreDetailList(this.deviceCoreDetailList);
          }
        },
        (error) => {
          this.messageHandler(
            'error',
            'Error',
            'There is an error retrieving More Core Details'
          );
        }
      );
  }

  /**
   * getDeviceAssociation()
   * retrieve device association from the API and map them using this.mappingNewEntryDeviceAssociationList()
   */
  getDeviceAssociation() {
    return this.httpClient.get<DeviceAssociationModel[]>(
      environment.entityMapping + `&accountCode=${this.accountCode}`
    );
  }

  /**
   * getAllPackageConfigs()
   * retrieve all package configs from the API and map them using this.generatingConfigList() and this.dispatchDataSource()
   */
  getAllPackageConfigs() {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('status', 'READY_FOR_CUSTOMER');
    queryParams = queryParams.append('per_page', '500');
    const url = `${environment.packageapi}`;
    return this.httpClient.get<PackageConfigModel[]>(url, {
      params: queryParams
    });
  }

  /**
   * generatingConfigList()
   * @param packagesList
   * return this.configList
   */
  generatingConfigList(packagesList: PackageConfigModel) {
    packagesList.forEach((t) => {
      const newDate = this.datepipe.transform(t.created, 'MM/dd/yyyy hh:mm:ss');
      this.configList.push({
        id: t.id,
        name: t.name,
        created: newDate,
        jsonConfig: t.jsonConfig
      });
    });
    this.configList.sort(
      (a, b) => this.configList[a.created] - this.configList[b.created]
    );
    return this.configList;
  }

  /**
   * mappingData()
   * @param deviceList
   * map the data using this.newEntry and this.newEntryTempArray
   */
  mappingData(deviceList: DeviceModel[]) {
    this.newEntryTempArray = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < deviceList.length; i++) {
      this.newEntry = {
        id: null,
        device_id: null,
        deviceType: null,
        manufacturer: null,
        model: null,
        partNumber: null,
        imei: null,
        iccid: null,
        engineSerialNumber: null,
        asset_id: null,
        asset_name: null,
        asset_type: null,
        division_id: null,
        vin_number: null,
        firmware_version: null,
        last_published: null,
        company_id: null,
        account_code: null,
        status: null,
        serial_number: null,
        registry_id: null,
        project_id: null,
        config_label: null,
        config_vers: null,
        config_binary: null,
        last_heartbeat_time: null,
        last_event_time: null,
        last_config_send_time: null,
        last_config_ack_time: null,
        last_reboot: null,
        last_phone_home: null,
        config_device_ack_time: null,
        cloud_update_time: null,
        config_json: null,
        config: null,
        selected_config_label: null,
        selected_config: null,
        selected_config_json: null,
        checked: false,
        published: null,
        validated: null,
        trigger_id: null,
        packageConfigId: null,
        make: null,
        modelYear: null,
        configlist: []
      };

      const newDeviceResponse = deviceList[i];
      this.newEntry.id = newDeviceResponse.id;
      this.newEntry.device_id = newDeviceResponse.id;
      this.newEntry.deviceType = newDeviceResponse.deviceType;
      this.newEntry.manufacturer = newDeviceResponse.manufacturer;
      this.newEntry.partNumber = newDeviceResponse.partNumber;
      this.newEntry.serial_number = newDeviceResponse.mfrSerialNum;
      this.newEntry.imei = newDeviceResponse.typeInfo.imei;
      this.newEntry.iccid = newDeviceResponse.typeInfo.iccid;
      this.newEntry.firmware_version = newDeviceResponse.typeInfo.firmware;
      this.newEntry.engineSerialNumber =
        newDeviceResponse.typeInfo.vehicleInfo.engineSerialNumber;
      this.newEntry.configlist = this.configList;
      this.newEntry.account_code = this.accountCode;
      if (
        newDeviceResponse.iotRegistryInfo.state === 'PENDING' ||
        newDeviceResponse.iotRegistryInfo.state === 'NEW'
      ) {
        this.newEntry.config_label = 'CONFIG NOT PUBLISHED';
      }

      if (newDeviceResponse.assetId) {
        this.assetResponse = this.assetsList.find(
          (asset) => asset.id === newDeviceResponse.assetId
        );

        if (this.assetResponse) {
          this.newEntry.asset_id = this.assetResponse.id;
          this.newEntry.asset_name = this.assetResponse.name;
          this.newEntry.vin_number = this.assetResponse.typeInfo.oemVin;
          this.newEntry.model = this.assetResponse.model;
          this.newEntry.asset_type = this.assetResponse.assetType;
          this.newEntry.company_id = this.assetResponse.companyId;
          this.newEntry.make = this.assetResponse.make;
          this.newEntry.modelYear = this.assetResponse.modelYear;
          if (this.assetResponse.divisions.length !== 0) {
            this.newEntry.division_id = this.assetResponse.divisions[0];
          }
        }
      }
      if (this.newEntry.config_label !== null) {
        this.newEntryTempArray.push(this.newEntry);
      }
    }
  }

  /**
   * mappingNewEntryDeviceCoreDetailList()
   * @param deviceCoreDetails
   * map the data using deviceCoreDetails and this.newEntryTempArray
   */
  mappingNewEntryDeviceCoreDetailList(deviceCoreDetails) {
    if (deviceCoreDetails.length > 0) {
      deviceCoreDetails.filter((element: any) => {
        return this.newEntryTempArray.some((item) => {
          if (String(`ID-${item.id}`) === String(element.id)) {
            item.last_heartbeat_time = element.lastHeartbeatTime;
            item.last_event_time = element.lastEventTime;
            item.last_config_send_time = element.lastConfigSendTime;
            item.last_config_ack_time = element.lastConfigAckTime;
          }
        });
      });
    }

    if (this.deviceAssociationList.length > 0) {
      this.mappingNewEntryDeviceAssociationList(this.deviceAssociationList);
    } else if (this.lastRebootPhoneHomeData.length > 0) {
      this.mappingNewEntryLastRebootPhoneHomeData(this.newEntryTempArray);
    } else {
      this.dispatchDataSource(this.newEntryTempArray);
    }
    this.newEntryTempArray = [];
    this.deviceAssociationList = [];
  }

  /**
   * mappingNewEntryDeviceAssociationList()
   * @param deviceAssociationList
   * map the data using deviceAssociationList and this.newEntryTempArray
   */
  mappingNewEntryDeviceAssociationList(
    deviceAssociationList: DeviceAssociationModel[]
  ) {
    deviceAssociationList.filter((element: any) => {
      return this.newEntryTempArray.some((item) => {
        if (String(`ID-${item.id}`) === String(element.id)) {
          item.last_heartbeat_time = element.lastHeartbeatTime;
          item.last_event_time = element.lastEventTime;
          item.last_config_send_time = element.lastConfigSendTime;
          item.last_config_ack_time = element.lastConfigAckTime;
        }
      });
    });
    this.mappingStatus(deviceAssociationList, this.newEntryTempArray);
    this.newEntryTempArray = [];
  }

  /**
   * mappingStatus
   * @param deviceAssociationList
   * @param newEntryTempArray
   * Mapping new Entry vs Device Association List
   * to get status, packageConfigId
   */
  mappingStatus(
    deviceAssociationList: DeviceAssociationModel[],
    newEntryTempArray: DeviceConfigMapping[]
  ) {
    if (deviceAssociationList.length > 0) {
      deviceAssociationList.filter((element: any) => {
        return newEntryTempArray.some((item) => {
          if (String(item.serial_number) === String(element.serialNumber)) {
            item.status = element.status;
            item.packageConfigId = element.packageConfigId;
          }
        });
      });
    }
    this.mappingPackageConfig(newEntryTempArray);
  }

  /**
   * mappingPackageConfig
   * @param newEntryTempArray
   * Mapping new Entry vs package List
   * to get config_label, config_json, last_published
   */
  mappingPackageConfig(newEntryTempArray: DeviceConfigMapping[]) {
    let temporalData = [];
    temporalData = newEntryTempArray;
    this.packageList.filter((element: any) => {
      return temporalData.some((item) => {
        if (String(item.packageConfigId) === String(element.id)) {
          item.config_label = element.name;
          item.config_json = element.jsonConfig;
          item.last_published = element.modified;
        }
      });
    });
    this.mappingNewEntryLastRebootPhoneHomeData(temporalData);
    temporalData = [];
    this.packageList = [];
  }

  /**
   * mappingNewEntryLastRebootPhoneHomeData()
   * @param newEntryTempArray
   * Mapping new Entry vs last reboot phone home data
   */
  mappingNewEntryLastRebootPhoneHomeData(
    newEntryTempArray: DeviceConfigMapping[]
  ) {
    this.lastRebootPhoneHomeData.filter((element: any) => {
      return newEntryTempArray.some((item) => {
        if (String(item.device_id) === String(element.deviceId)) {
          item.last_reboot = element.lastReboot;
          item.last_phone_home = element.lastPhoneHome;
        }
      });
    });
    if (newEntryTempArray.length > 0) {
      this.dispatchDataSource(newEntryTempArray);
    }
  }

  /**
   * dispatchDataSource()
   * @param data
   * dispatch the data to the dataSource
   * clean the containers
   */
  dispatchDataSource(data) {
    set(DEVICE_CONFIG_MAPPING_KEY, data).then(() => {});
    this.store.dispatch(addDataSource({ dataSource: data }));
    this.store.dispatch(ui.stopLoading());
    this.configList = [];
    this.assetsList = [];
    this.deviceList = [];
    this.deviceCoreDetailList = [];
    this.lastRebootPhoneHomeData = [];
  }

  /**
   * cleanContainersAndCache()
   * clean containers and cache
   */
  cleanContainersAndCache() {
    set(DEVICE_CONFIG_MAPPING_KEY, undefined).then(() => {});
    set('Assets', undefined).then(() => {});
    set('Devices', undefined).then(() => {});
    this.store.dispatch(actions.cleanDivisions());
    this.store.dispatch(actions.cleanAssets());
    this.store.dispatch(actions.cleanDevices());
    this.store.dispatch(cleanDataSource());
  }

  /**
   * cleanPackageConfigListCache()
   * clean package config list cache
   */
  cleanPackageConfigListCache() {
    set('PACKAGE_CONFIG_LIST', undefined).then(() => {});
  }

  /**
   * cleanCompanyAccount()
   * clean company and account
   */
  cleanCompanyAccount() {
    set('CUSTOMER_NAME', undefined).then(() => {});
    set('CUSTOMER_ACCOUNT_CODE', undefined).then(() => {});
  }

  /**
   * messageHandler()
   * @param icon
   * @param title
   * @param message
   * show a message using sweet alert
   */
  messageHandler(icon: SweetAlertIcon, title: string, message: string) {
    Swal.fire({
      icon,
      title,
      text: message
    });
  }
}
