import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DeviceDetailsModel, DeviceModel } from '../_models/DeviceModel';

@Injectable({
  providedIn: 'root'
})
export class DeviceApiService {
  constructor(private http: HttpClient) {}

  getDevices(params?: any): Observable<any> {
    let queryParams = new HttpParams();

    if (params) {
      queryParams = queryParams.append('deviceType', 'TCU');
      queryParams = queryParams.append('status', 'ACTIVE');
      queryParams = queryParams.append('iotState', 'PENDING');
      queryParams = queryParams.append('per_page', '200');
    }

    return this.http.get<DeviceModel>(`${environment.serviceapi}/devices`, {
      params: queryParams
    });
  }

  getDevicesByAssetId(assetId: string): Observable<any> {
    let queryParams = new HttpParams();

    queryParams = queryParams.append('deviceType', 'TCU');
    queryParams = queryParams.append('status', 'ACTIVE');
    queryParams = queryParams.append('iotState', 'PENDING');
    queryParams = queryParams.append('per_page', '200');
    queryParams = queryParams.append('assetId', assetId);

    return this.http.get(`${environment.serviceapi}/devices`, {
      params: queryParams
    });
  }

  getDeviceByParams(params?: any): Observable<any> {
    let queryParams = new HttpParams();
    queryParams = params;
    return this.http.get<DeviceModel>(`${environment.serviceapi}/devices`, {
      params: queryParams
    });
  }

  getDeviceDetails(serialNumber) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('serialNumber', serialNumber);
    queryParams = queryParams.append('page', '1');
    queryParams = queryParams.append('per_page', '100');
    return this.http.get<DeviceDetailsModel[]>(
      `${environment.entityDevicesAssociations}/device-associations`,
      {
        params: queryParams
      }
    );
  }
}
