import { Component, OnInit } from '@angular/core';
import { SIGNAL_TYPE_LIST, SPN_TYPE_LIST } from './../constants';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { SignalApiService } from 'src/app/core/_services/signal-api.service';
import { Observable } from 'rxjs';
import { StepperOrientation } from '@angular/cdk/stepper';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { SPNModel } from 'src/app/core/_models/SPNModel';

@Component({
  selector: 'app-action-signal',
  templateUrl: './action-signal.component.html',
  styleUrls: ['./action-signal.component.scss']
})
export class ActionSignalComponent implements OnInit {
  stepperOrientation: Observable<StepperOrientation>;
  loading: boolean = false;
  functionType: string = 'Create';
  buttonLabel: string = 'Create PGN';
  signalTypeList = SIGNAL_TYPE_LIST;
  spnTypeList = SPN_TYPE_LIST;
  spnList: any[] = [];

  pgnFormGroup = this._formBuilder.group({
    signalTypeFormControl: ['PROPRIETARY', Validators.required],
    pgnFormControl: ['', Validators.required],
    labelFormControl: ['', Validators.required],
    descriptionFormControl: ['', Validators.required],
    providerFormControl: [''],
    variableSpnDelimiterFormControl: [''],
    variableSpnStartFormControl: [''],
    versionSourceFormControl: ['']
  });
  spnFormGroup = this._formBuilder.group({
    sequenceFormControl: ['1', Validators.required],
    spnFormControl: ['', Validators.required],
    spnTypeFormControl: ['FIXED', Validators.required],
    startFormControl: [''],
    lengthFormControl: [''],
    labelFormControl: ['', Validators.required],
    descriptionFormControl: ['', Validators.required],
    unitsFormControl: [''],
    resolutionFormControl: [''],
    offsetFormControl: ['']
  });

  constructor(
    public dialogRef: MatDialogRef<ActionSignalComponent>,
    private signalApiService: SignalApiService,
    private _formBuilder: FormBuilder,
    breakpointObserver: BreakpointObserver
  ) {
    this.stepperOrientation = breakpointObserver
      .observe('(min-width: 800px)')
      .pipe(map(({ matches }) => (matches ? 'horizontal' : 'vertical')));
  }

  ngOnInit(): void {}

  async closeDialog(functionType: string) {
    try {
      this.dialogRef.close(functionType); // make sure it only closes if the upper async fn succesfully ran!
    } catch (e) {
      //this.errorMessage = e.response.message;
    }
  }

  //Function to create new PGN
  async createPgn() {
    try {
      let jsonBody = {
        type: this.pgnFormGroup.controls['signalTypeFormControl'].value,
        pgn: Number(this.pgnFormGroup.controls['pgnFormControl'].value),
        label: this.pgnFormGroup.controls['labelFormControl'].value,
        description:
          this.pgnFormGroup.controls['descriptionFormControl'].value == null
            ? null
            : this.pgnFormGroup.controls['descriptionFormControl'].value,
        providerId:
          this.pgnFormGroup.controls['providerFormControl'].value == null
            ? null
            : this.pgnFormGroup.controls['providerFormControl'].value,
        variableSpnDelimiter:
          this.pgnFormGroup.controls['variableSpnDelimiterFormControl'].value ==
          null
            ? null
            : this.pgnFormGroup.controls['variableSpnDelimiterFormControl']
                .value,
        variableSpnStart:
          this.pgnFormGroup.controls['variableSpnStartFormControl'].value ==
          null
            ? 0
            : Number(
                this.pgnFormGroup.controls['variableSpnStartFormControl'].value
              ),
        versionSource:
          this.pgnFormGroup.controls['versionSourceFormControl'].value == null
            ? null
            : this.pgnFormGroup.controls['versionSourceFormControl'].value,
        spns: this.spnList
      };

      console.log('Json Body: ', jsonBody);

      /* (await this.signalApiService.createPgn(jsonBody)).toPromise().then(data => {
        console.log("Data: ", data);
      }) */

      (await this.signalApiService.createPgn(jsonBody))
        .toPromise()
        .then((response) => {
          let status = this.functionType + ',Success, Success';
          console.log('Response: ', response);
          this.closeDialog(status);
        })
        .catch((err) => {
          console.log('Err: ', err);
          let status =
            this.functionType + ',Error, [CPGN-822101] - ' + err.statusText;
          this.closeDialog(status);
        });
    } catch (error) {
      console.log('Error creating PGN: ', error);
    }
  }

  addSpn() {
    try {
      let spnData = {
        sequence: Number(
          this.spnFormGroup.controls['sequenceFormControl'].value
        ),
        spn: Number(this.spnFormGroup.controls['spnFormControl'].value),
        type: this.spnFormGroup.controls['spnTypeFormControl'].value,
        start:
          this.spnFormGroup.controls['startFormControl'].value == null
            ? 0
            : Number(this.spnFormGroup.controls['startFormControl'].value),
        length:
          this.spnFormGroup.controls['lengthFormControl'].value == null
            ? 0
            : Number(this.spnFormGroup.controls['lengthFormControl'].value),
        label: this.spnFormGroup.controls['labelFormControl'].value,
        description: this.spnFormGroup.controls['descriptionFormControl'].value,
        units: this.spnFormGroup.controls['unitsFormControl'].value,
        resolution: this.spnFormGroup.controls['resolutionFormControl'].value,
        offset: this.spnFormGroup.controls['offsetFormControl'].value
      };
      this.spnList.push(spnData);

      // Clearing & resetting to default values
      this.spnFormGroup.controls['sequenceFormControl'].setValue(
        String(
          Number(this.spnFormGroup.controls['sequenceFormControl'].value) + 1
        )
      );
      this.spnFormGroup.controls['spnFormControl'].setValue('');
      this.spnFormGroup.controls['spnTypeFormControl'].setValue('FIXED');
      this.spnFormGroup.controls['startFormControl'].setValue('');
      this.spnFormGroup.controls['lengthFormControl'].setValue('');
      this.spnFormGroup.controls['labelFormControl'].setValue('');
      this.spnFormGroup.controls['descriptionFormControl'].setValue('');
      this.spnFormGroup.controls['unitsFormControl'].setValue('');
      this.spnFormGroup.controls['resolutionFormControl'].setValue('');
      this.spnFormGroup.controls['offsetFormControl'].setValue('');
    } catch (error) {
      console.log('Error - Add SPN to the list: ', error);
    }
  }

  deleteSpn(spn: any) {
    try {
      this.spnList.forEach((element, index) => {
        if (element == spn) this.spnList.splice(index, 1);
      });
    } catch (error) {
      console.log('Error - Delete SPN from the list: ', error);
    }
  }
}
