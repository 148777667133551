import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nullToNA'
})
export class NullToNAPipe implements PipeTransform {
  transform(value: any): any {
    return value === null ? 'N/A' : value;
  }
}
