import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-details',
  templateUrl: './admin-details.component.html',
  styleUrls: ['./admin-details.component.scss']
})
export class AdminDetailsComponent {
  @Input() title;
  @Input() isDeviceLoading;
  @Input() isAssetLoading;
  @Input() isCompanyLoading;
  @Input() isIOTLoading;
  @Input() data;
}
