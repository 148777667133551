<div class="container parent">
  <mat-card-title-group>
    <mat-card-title>Results</mat-card-title>
    <button mat-icon-button aria-label="close dialog" mat-dialog-close>
      <img src="assets/images/close-icon.png" width="30" height="30" alt="" />
    </button>
  </mat-card-title-group>
  <hr />
  <div class="row">
    <div class="col-md-3">
      <span><b>Asset Name</b></span>
    </div>
    <div class="col-md-3">
      <span><b>Serial Number</b></span>
    </div>
    <div class="col-md-3">
      <span><b>Action</b> </span>
    </div>
    <div class="col-md-3">
      <span><b>Response</b></span>
    </div>
  </div>
  <div class="row mt-3">
    <ng-container *ngFor="let item of data">
      <div class="col-md-3">
        <span>{{ item.assetName }}</span>
      </div>
      <div class="col-md-3">
        <span>{{ item.serialNumber }}</span>
      </div>
      <div class="col-md-3">
        <span>{{ item.action }}</span>
      </div>
      <div class="col-md-3">
        <span>{{ item.response }}</span>
      </div>
    </ng-container>
  </div>
  <div mat-dialog-actions class="btn-holder child">
    <button mat-stroked-button class="status-button" (click)="onClose()">
      Close
    </button>
  </div>
</div>
