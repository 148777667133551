export const displayedColumnsDeviceProvisioning: string[] = [
  'asset_name',
  'serial_number',
  'device_id',
  'deviceType',
  'vin_number',
  'registry_type',
  'firmware_version',
  'iot_status'
];
