import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminDetailsComponent } from './admin-details/admin-details.component';
import { AngularMaterialModule } from '../angular-material/angular-material.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { TitlePipe } from '../pipes/title.pipe';
import { TableDialogComponent } from './table-dialog/table-dialog.component';
import { HighlightModule } from 'ngx-highlightjs';
import { JsonDialogComponent } from './json-dialog/json-dialog.component';

@NgModule({
  declarations: [
    AdminDetailsComponent,
    TitlePipe,
    TableDialogComponent,
    JsonDialogComponent
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    NgxSkeletonLoaderModule,
    HighlightModule
  ],
  exports: [
    AdminDetailsComponent,
    TitlePipe,
    TableDialogComponent,
    JsonDialogComponent
  ]
})
export class SharedModule {}
