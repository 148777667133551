<mat-card class="asset-mapping-trigger-container">
  <div class="asset-mapping-trigger-title">Signal Trigger Mappings</div>

  <div class="asset-mapping-trigger-description">
    Allows support resources to map ingested data fields to designated fields
    for the end consumer.
  </div>

  <div class="asset-mapping-trigger-search">
    <div class="search-options">
      <div class="single-option">
        <div class="single-option-label">Trigger Name</div>

        <div>
          <input
            matInput
            [(ngModel)]="triggerNameSearchField"
            type="text"
            placeholder="Trigger name"
          />
        </div>
      </div>

      <div class="single-option">
        <div class="single-option-label">Logic Field Name</div>

        <div>
          <input
            matInput
            [(ngModel)]="logicFieldNameSearchField"
            type="text"
            placeholder="Logic Field Name"
          />
        </div>
      </div>
    </div>

    <div class="trigger-actions">
      <button mat-raised-button (click)="executeSearch()">
        Search for Triggers
      </button>

      <button mat-raised-button (click)="createTriggerClick()">
        Create Trigger
      </button>

      <button
        class="export-button"
        mat-raised-button
        (click)="exportCSV(searchResults.value)"
      >
        Export CSV
      </button>
    </div>
  </div>

  <div class="asset-mapping-trigger-search-results">
    <div class="results" *ngIf="searchResults | async as allResults">
      <table mat-table [dataSource]="allResults" matSort>
        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef>Edit</th>

          <td mat-cell *matCellDef="let element">
            <div
              class="asset-mapping-edit-cell"
              (click)="editCellClick(element)"
            >
              <div>
                <img src="/assets/pen-solid.svg" alt="Edit trigger" />
              </div>

              <div>Edit</div>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="triggerName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Trigger Name
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.triggerName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="logicFieldName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Logic Field Name
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.logicFieldName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
          <td mat-cell *matCellDef="let element">
            {{ element.status }}
          </td>
        </ng-container>

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
          <td mat-cell *matCellDef="let element">
            {{ element.id }}
          </td>
        </ng-container>

        <ng-container matColumnDef="created">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Created</th>
          <td mat-cell *matCellDef="let element">
            {{ element.created | date }}
          </td>
        </ng-container>

        <ng-container matColumnDef="modified">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Modified</th>
          <td mat-cell *matCellDef="let element">
            {{ element.modified | date }}
          </td>
        </ng-container>

        <ng-container matColumnDef="spn">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Spn</th>
          <td mat-cell *matCellDef="let element">
            {{ element.parsingLogic.parameters[0]?.spn | nullToNA }}
          </td>
        </ng-container>

        <ng-container matColumnDef="transformer">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Transformer</th>
          <td mat-cell *matCellDef="let element">
            <ng-container
              *ngIf="element.parsingLogic.transformer as transformerData"
            >
              <div
                class="view-transformer-link"
                (click)="transformerCellClick(transformerData)"
              >
                View Transformer
              </div>
            </ng-container>

            <ng-container *ngIf="!element.parsingLogic.transformer">
              N/A
            </ng-container>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          class="single-row"
          mat-row
          *matRowDef="let row; columns: displayedColumns"
        ></tr>
      </table>
    </div>
  </div>
</mat-card>
