import { Action, createReducer, on } from '@ngrx/store';
import * as actions from './data-source.actions';

const dataSourceInitialState: any = [];

const DataSourceReducer = createReducer(
  dataSourceInitialState,
  // @ts-ignore
  on(actions.addDataSource, (state, { dataSource }) => ({
    ...state,
    dataSource
  })),
  on(actions.cleanDataSource, () => dataSourceInitialState)
);

export const dataSourceReducer = (state = [], action: Action) =>
  DataSourceReducer(state, action);
