import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DeviceConfigMapping } from 'src/app/core/_models/DeviceConfigMapping';

@Component({
  selector: 'app-config-dialog',
  templateUrl: './config-dialog.component.html',
  styleUrls: ['./config-dialog.component.scss']
})
export class ConfigDialogComponent implements OnInit {
  configLabel: string;
  configData: string;

  constructor(
    public dialogRef: MatDialogRef<ConfigDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DeviceConfigMapping
  ) {
    this.parseConfigData();
  }

  ngOnInit(): void {}

  parseConfigData() {
    this.configLabel = this.data.config_label;
    if (this.data.config_json != null) {
      this.configData = this.data.config_json;
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
