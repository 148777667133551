<mat-card-title-group>
  <mat-card-title>{{ functionType }} Dataset</mat-card-title>
  <button mat-icon-button aria-label="close dialog" mat-dialog-close>
    <img src="assets/images/close-icon.png" width="30" height="30" />
  </button>
</mat-card-title-group>

<hr />
<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>

<ng-container [ngSwitch]="stepperOrientation | async">
  <div *ngSwitchCase="'horizontal'"></div>
  <div *ngSwitchCase="'vertical'"></div>
</ng-container>
<mat-stepper
  class="example-stepper"
  [orientation]="(stepperOrientation | async)!"
>
  <mat-step [stepControl]="datasetFormGroup" label="Dataset details">
    <form [formGroup]="datasetFormGroup">
      <div style="display: table; width: 100%">
        <div style="display: table-row">
          <div style="display: table-cell; vertical-align: top">
            <div>
              <mat-form-field appearance="standard">
                <mat-label>Dataset Name: </mat-label>
                <input
                  matInput
                  placeholder="dataset name"
                  formControlName="datasetNameFormControl"
                  name="datasetNameFormControl"
                />
                <mat-error
                  *ngIf="
                    datasetFormGroup
                      .get('datasetNameFormControl')
                      .hasError('required')
                  "
                >
                  Dataset name is required</mat-error
                >
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="standard">
                <mat-label>Description</mat-label>
                <textarea
                  matInput
                  formControlName="descriptionFormControl"
                  name="descriptionFormControl"
                ></textarea>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="standard">
                <mat-label>Type</mat-label>
                <mat-select
                  formControlName="typeFormControl"
                  name="typeFormControl"
                >
                  <mat-option
                    *ngFor="let type of typeList"
                    value="{{ type }}"
                    >{{ type }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="standard">
                <mat-label>Source ID: </mat-label>
                <input
                  matInput
                  placeholder="sourceId"
                  formControlName="sourceIDFormControl"
                  name="sourceIDFormControl"
                />
              </mat-form-field>
            </div>
          </div>
          <div style="display: table-cell; vertical-align: top">
            <h3><u>GPS Data Config</u></h3>
            <div>
              <mat-form-field appearance="standard">
                <mat-label>Include Precision Data:</mat-label>
                <mat-select
                  formControlName="includePrecisionDataFormControl"
                  name="includePrecisionDataFormControl"
                >
                  <mat-option
                    *ngFor="let attribute of jsonAttributeInputValue"
                    value="{{ attribute.value }}"
                    >{{ attribute.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>

            <br />
            <h3><u>Digital Input Config</u></h3>
            <div>
              <mat-form-field appearance="standard">
                <mat-label>Include States:</mat-label>
                <mat-select
                  formControlName="includeStatesFormControl"
                  name="includeStatesFormControl"
                >
                  <mat-option
                    *ngFor="let attribute of jsonAttributeInputValue"
                    value="{{ attribute.value }}"
                    >{{ attribute.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </form>
    <mat-divider></mat-divider>
    <mat-dialog-actions align="end">
      <button id="refresh-button" mat-button matStepperNext>Next</button>
    </mat-dialog-actions>
  </mat-step>
  <mat-step [stepControl]="appConfigFormGroup" label="App Config details">
    <form [formGroup]="appConfigFormGroup">
      <div style="display: table; width: 100%">
        <div style="display: table-row">
          <div style="display: table-cell; vertical-align: top">
            <div>
              <mat-form-field appearance="standard">
                <mat-label>GPS Fix State:</mat-label>
                <mat-select
                  formControlName="gpsFixStateFormControl"
                  name="gpsFixStateFormControl"
                >
                  <mat-option
                    *ngFor="let attribute of jsonAttributeInputValue"
                    value="{{ attribute.value }}"
                    >{{ attribute.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="standard">
                <mat-label>GPS Antenna State:</mat-label>
                <mat-select
                  formControlName="gpsAntennaStateFormControl"
                  name="gpsAntennaStateFormControl"
                >
                  <mat-option
                    *ngFor="let attribute of jsonAttributeInputValue"
                    value="{{ attribute.value }}"
                    >{{ attribute.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="standard">
                <mat-label>GPS Jamming State:</mat-label>
                <mat-select
                  formControlName="gpsJammingStateFormControl"
                  name="gpsJammingStateFormControl"
                >
                  <mat-option
                    *ngFor="let attribute of jsonAttributeInputValue"
                    value="{{ attribute.value }}"
                    >{{ attribute.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="standard">
                <mat-label>Panic Input State:</mat-label>
                <mat-select
                  formControlName="panicInputStateFormControl"
                  name="panicInputStateFormControl"
                >
                  <mat-option
                    *ngFor="let attribute of jsonAttributeInputValue"
                    value="{{ attribute.value }}"
                    >{{ attribute.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="standard">
                <mat-label>Engine State:</mat-label>
                <mat-select
                  formControlName="engineStateFormControl"
                  name="engineStateFormControl"
                >
                  <mat-option
                    *ngFor="let attribute of jsonAttributeInputValue"
                    value="{{ attribute.value }}"
                    >{{ attribute.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div style="display: table-cell; vertical-align: top">
            <div>
              <mat-form-field appearance="standard">
                <mat-label>Motion State:</mat-label>
                <mat-select
                  formControlName="motionStateFormControl"
                  name="motionStateFormControl"
                >
                  <mat-option
                    *ngFor="let attribute of jsonAttributeInputValue"
                    value="{{ attribute.value }}"
                    >{{ attribute.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="standard">
                <mat-label>Speed State:</mat-label>
                <mat-select
                  formControlName="speedStateFormControl"
                  name="speedStateFormControl"
                >
                  <mat-option
                    *ngFor="let attribute of jsonAttributeInputValue"
                    value="{{ attribute.value }}"
                    >{{ attribute.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="standard">
                <mat-label>Odometer State:</mat-label>
                <mat-select
                  formControlName="odometerStateFormControl"
                  name="odometerStateFormControl"
                >
                  <mat-option
                    *ngFor="let attribute of jsonAttributeInputValue"
                    value="{{ attribute.value }}"
                    >{{ attribute.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="standard">
                <mat-label>Battery Voltage State:</mat-label>
                <mat-select
                  formControlName="batteryVoltageStateFormControl"
                  name="batteryVoltageStateFormControl"
                >
                  <mat-option
                    *ngFor="let attribute of jsonAttributeInputValue"
                    value="{{ attribute.value }}"
                    >{{ attribute.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </form>
    <mat-divider></mat-divider>
    <mat-dialog-actions align="end">
      <button id="refresh-button" mat-button matStepperPrevious>Back</button>
      <button id="refresh-button" mat-button matStepperNext>Next</button>
    </mat-dialog-actions>
  </mat-step>
  <mat-step [stepControl]="pgnFormGroup" label="j1939 Configs details">
    <form [formGroup]="pgnFormGroup">
      <div style="display: table; width: 100%">
        <div style="display: table-row">
          <div
            style="
              display: table-cell;
              vertical-align: top;
              width: 25%;
              padding-right: 10px;
            "
          >
            <div>
              <mat-form-field appearance="standard">
                <mat-label>PGN: </mat-label>
                <input
                  matInput
                  placeholder="0"
                  formControlName="pgnFormControl"
                  name="pgnFormControl"
                  (keydown.enter)="onSearchChange($event.target.value)"
                />
                <button
                  matSuffix
                  mat-icon-button
                  aria-label="Clear"
                  (click)="getPgn()"
                >
                  <mat-icon class="mat-icon-color">search</mat-icon>
                </button>
                <mat-error
                  *ngIf="
                    pgnFormGroup.get('pgnFormControl').hasError('required')
                  "
                  >PGN is required</mat-error
                >
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="standard">
                <mat-label>Select PGN: </mat-label>
                <mat-select
                  formControlName="selectPgnFormControl"
                  name="selectPgnFormControl"
                >
                  <mat-option
                    style="height: auto"
                    *ngFor="let pgn of propPgnList"
                    value="{{ pgn | json }}"
                    multiLineOption
                  >
                    {{ pgn.label }} <br /><mat-hint>{{ pgn.id }}</mat-hint>
                  </mat-option>
                </mat-select>
                <!--  
                ---Commented  for future use
                <mat-error
                  *ngIf="
                    pgnFormGroup
                      .get('proprietaryIdFormControl')
                      .hasError('required')
                  "
                  >Proprietary ID is required</mat-error
                > -->
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="standard">
                <mat-label>Source: </mat-label>
                <input
                  matInput
                  placeholder="0"
                  formControlName="sourceFormControl"
                  name="sourceFormControl"
                />
                <!-- ---Commented  for future use
                 <mat-error
                  *ngIf="
                    pgnFormGroup.get('sourceFormControl').hasError('required')
                  "
                  >Source is required</mat-error
                > -->
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="standard">
                <mat-label>Channel: </mat-label>
                <mat-select
                  formControlName="channelFormControl"
                  name="channelFormControl"
                >
                  <mat-option
                    *ngFor="let channel of channelTypeList"
                    value="{{ channel }}"
                    >{{ channel }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
              <br />
              <button
                mat-stroked-button
                class="icon-button-small"
                [disabled]="!pgnFormGroup.valid"
                color="primary"
                (click)="addJ1939Config()"
              >
                Add -> j1939 Config
              </button>
            </div>
            <div>
              <mat-error *ngIf="pgnNumberExists"
                >PGN number already exists!</mat-error
              >
            </div>
          </div>
          <div
            style="
              display: table-cell;
              vertical-align: top;
              align-items: center;
              background-color: #f4f6f6;
            "
          >
            <table
              style="
                padding: 10px;
                display: block;
                height: 300px;
                overflow-y: scroll;
              "
            >
              <tr>
                <th style="width: 7%">PGN</th>
                <th style="width: 35%">Proprietary ID</th>
                <th style="width: 35%">Label</th>
                <th style="width: 10%">Source</th>
                <th style="width: 10%">Channel</th>
                <th style="width: 3%">Action</th>
              </tr>
              <tr *ngFor="let j1939Config of j1939ConfigList">
                <td>{{ j1939Config.pgn }}</td>
                <td *ngIf="showProprietaryId(j1939Config)">
                  {{ j1939Config.proprietaryId }}
                </td>
                <td *ngIf="!showProprietaryId(j1939Config)">N/A</td>
                <td>{{ j1939Config.label }}</td>
                <td>
                  <mat-form-field style="width: 75%" appearance="standard">
                    <input
                      matInput
                      value="{{ j1939Config.source }}"
                      (change)="updatePgnList($event, j1939Config.id, 'source')"
                    />
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field style="width: 75%" appearance="standard">
                    <mat-select
                      [(value)]="j1939Config.channel"
                      (selectionChange)="
                        updatePgnList($event, j1939Config.id, 'channel')
                      "
                    >
                      <mat-option
                        *ngFor="let channel of channelTypeList"
                        value="{{ channel }}"
                        >{{ channel }}</mat-option
                      >
                    </mat-select>
                  </mat-form-field>
                </td>
                <td>
                  <button
                    mat-icon-button
                    aria-label="Delete J1939 Config"
                    (click)="deleteJ1939Config(j1939Config)"
                    class="mat-icon-color"
                  >
                    <mat-icon>delete</mat-icon>
                  </button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </form>
    <mat-dialog-actions align="end">
      <button id="refresh-button" mat-button matStepperPrevious>Back</button>
      <button id="refresh-button" mat-button matStepperNext>Next</button>
    </mat-dialog-actions>
  </mat-step>

  <!--   This section is for future enhancement. Dont't uncomment it.
  <mat-step [stepControl]="futureFormGroup" label="Future Enhancement">
    <h3>
      This section is for future enhancement and is currently not required to
      create a dataset.
    </h3>
    <form [formGroup]="futureFormGroup">
      <div style="display: table; width: 100%">
        <div style="display: table-row">
          <div style="display: table-cell; vertical-align: top">
            <div>
              <mat-form-field appearance="standard">
                <mat-label>Accelerometer Config: </mat-label>
                <input matInput placeholder="accelerometerConfig" formControlName="accelerometerConfigFormControl"
                  name="accelerometerConfigFormControl" [disabled]="true" />
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="standard">
                <mat-label>Analytics Config: </mat-label>
                <input matInput placeholder="analyticsConfig" formControlName="analyticsConfigFormControl"
                  name="analyticsConfigFormControl" [disabled]="true" />
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="standard">
                <mat-label>j1587 Config: </mat-label>
                <input matInput placeholder="j1587Config" formControlName="j1587ConfigsFormControl"
                  name="j1587ConfigsFormControl" [disabled]="true" />
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="standard">
                <mat-label>OBD2 Configs: </mat-label>
                <input matInput placeholder="obd2Config" formControlName="obd2ConfigsFormControl"
                  name="obd2ConfigsFormControl" [disabled]="true" />
              </mat-form-field>
            </div>
          </div>
          <div style="display: table-cell; vertical-align: top"></div>
        </div>
      </div>
    </form>
    <mat-divider></mat-divider>
    <mat-dialog-actions align="end">
      <button id="refresh-button" mat-button matStepperPrevious>Back</button>
      <button id="refresh-button" mat-button matStepperNext>Next</button>
    </mat-dialog-actions>
  </mat-step> -->
  <mat-step>
    <ng-template matStepLabel>Submit</ng-template>
    <form [formGroup]="submitFormGroup">
      <div>
        <mat-form-field appearance="standard">
          <mat-label>Status</mat-label>
          <mat-select
            formControlName="statusFormControl"
            name="statusFormControl"
          >
            <mat-option
              *ngFor="let status of statusList"
              value="{{ status }}"
              >{{ status }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
    </form>
    <mat-divider></mat-divider>
    <mat-dialog-actions align="end">
      <button id="refresh-button" mat-button matStepperPrevious>Back</button>
      <button
        mat-stroked-button
        color="primary"
        type="submit"
        (click)="createDataset()"
        [disabled]="!datasetFormGroup.valid || !submitFormGroup.valid"
      >
        {{ buttonLabel }}
      </button>
      <button id="refresh-button" mat-stroked-button mat-dialog-close>
        Cancel
      </button>
    </mat-dialog-actions>
  </mat-step>
</mat-stepper>
