import { HttpParams } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DeviceModel } from 'src/app/core/_models/DeviceModel';
import { DeviceApiService } from 'src/app/core/_services/device-api.service';
import { forkJoin, of, Subscription } from 'rxjs';
import { endWith, startWith, switchMap } from 'rxjs/operators';
import { AssetApiService } from '../../core/_services/asset-api.service';
import { CompanyApiService } from '../../core/_services/company-api.service';
import { IotCoreService } from '../../core/_services/iot-core.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { DivisionApiService } from '../../core/_services/division-api.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit, OnDestroy {
  serialNumber = new FormControl('');
  displayedColumns = ['device_id', 'asset_id', 'status'];
  dataSource;
  deviceDetails: DeviceModel;
  queryParams = new HttpParams();

  dataDevice;
  dataAssets;
  dataCompany;
  dataIOT;

  assetId = '';
  accountCode = '';

  companySub: Subscription;
  assetSub: Subscription;
  divisionSub: Subscription;

  isDeviceLoading = false;
  isAssetLoading = false;
  isCompanyLoading = false;
  isIOTLoading = false;

  joined$;

  constructor(
    private deviceApiService: DeviceApiService,
    private assetApiService: AssetApiService,
    private companyApiService: CompanyApiService,
    private iotCoreService: IotCoreService,
    private divisionApiService: DivisionApiService
  ) {}

  ngOnInit(): void {}

  ngOnDestroy() {
    if (this.assetSub) {
      this.assetSub.unsubscribe();
    }
    if (this.divisionSub) {
      this.divisionSub.unsubscribe();
    }
    if (this.joined$) {
      this.joined$.unsubscribe();
    }
  }

  /**
   * getDetails()
   * Given a serial number this function triggers as
   * IOTConfig as Device Details observables using a forkJoin
   */

  getDetails() {
    if (this.serialNumber.value !== '') {
      this.isIOTLoading = true;
      this.isDeviceLoading = true;
      this.isCompanyLoading = true;
      this.isAssetLoading = true;
      this.queryParams = this.queryParams.append(
        'serialNumber',
        this.serialNumber.value
      );
      this.joined$ = forkJoin([
        this.deviceApiService.getDeviceDetails(this.serialNumber.value),
        this.iotCoreService.getIOTConfigDetails(this.serialNumber.value)
      ]);

      this.joined$.subscribe(
        ([deviceDetailsResp, IOTConfigRes]) => {
          startWith(this.isDeviceLoading);
          startWith(this.isIOTLoading);

          if (deviceDetailsResp.length !== 0) {
            this.dataDevice = deviceDetailsResp[0];
            this.accountCode = this.dataDevice.accountCode;
            this.assetId = this.dataDevice.assetId;
            this.isDeviceLoading = false;
            endWith(this.isDeviceLoading);

            if (this.accountCode) {
              this.getCompanyId(this.accountCode);
            }
            if (this.assetId) {
              this.getAssetsDetails(this.assetId);
            }
          } else {
            this.isDeviceLoading = false;
          }

          if (IOTConfigRes) {
            this.dataIOT = IOTConfigRes;
            this.isIOTLoading = false;
            endWith(this.isIOTLoading);
          } else {
            this.isIOTLoading = false;
          }
        },
        (error) => {
          this.errorHandler(error);
          this.isDeviceLoading = false;
          this.isIOTLoading = false;
          this.isAssetLoading = false;
          this.isCompanyLoading = false;
        }
      );
    } else {
      this.errorHandler('Please type a Device Serial Number.');
    }
  }

  /**
   * getCompanyId()
   * @param accountCode
   * Once Account Code is retrieved by Device Observable Division API
   * is called to get the Company Details
   */

  getCompanyId(accountCode) {
    this.divisionSub = this.divisionApiService
      .getCompanyIdByAccountCode(accountCode)
      .pipe(
        // @ts-ignore
        switchMap((companyId) =>
          this.companyApiService.getCompanies(companyId[0].companyId)
        )
      )
      .subscribe(
        (companyDetailsRes) => {
          startWith(this.isCompanyLoading);
          if (companyDetailsRes) {
            this.dataCompany = companyDetailsRes;
            this.isCompanyLoading = false;
            endWith(this.isCompanyLoading);
          }
        },
        (error) => {
          this.errorHandler(error);
          this.isCompanyLoading = false;
        }
      );
    this.isCompanyLoading = false;
  }

  /**
   * getAssetsDetails()
   * @param assetId
   * Once assetId  is retrieved by Device Observable then Asset API
   * is called to get the Asset Details Data
   */

  getAssetsDetails(assetId) {
    this.assetSub = this.assetApiService.getAssetDetailsById(assetId).subscribe(
      (AssetDetailsRes) => {
        startWith(this.isAssetLoading);
        if (AssetDetailsRes) {
          this.dataAssets = AssetDetailsRes;
          this.isAssetLoading = false;
          endWith(this.isAssetLoading);
        }
        this.isAssetLoading = false;
      },
      (error) => {
        this.errorHandler(error);
        this.isAssetLoading = false;
      }
    );
    this.isAssetLoading = false;
  }

  /**
   * errorHandler()
   */
  errorHandler(error) {
    Swal.fire({
      icon: 'error',
      title: 'Error!',
      text: error.message
    });
  }
}
