import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { get } from 'idb-keyval';
import {
  DEVICE_CONFIG_MAPPING_KEY,
  DEVICE_DIALOG_WIDTH
} from '../publishconfig/constants';
import { DeviceConfigMapping } from '../../core/_models/DeviceConfigMapping';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { DEVICE_SETTINGS_COLUMNS } from './constants';
import { EnvironmentValidatorService } from '../../core/_services/environment-validator.service';
import { Subscription } from 'rxjs';
import { DeviceSettingsService } from '../../core/_services/device-settings.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GlobalVariables } from 'src/app/core/_common/GlobalVariables';
import { DatadogService } from 'src/app/core/_services/datadog.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ScheduleModalComponent } from './schedule-modal/schedule-modal.component';
import { ModalResultComponent } from './modal-result/modal-result.component';
import { MatSort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.state';

@Component({
  selector: 'app-device-settings',
  templateUrl: './device-settings.component.html',
  styleUrls: ['./device-settings.component.scss']
})
export class DeviceSettingsComponent implements OnInit, OnDestroy {
  pageLoading = false;
  tableLoading = false;
  companyName = '';
  accountCode = '';
  companyId = '';
  actions = this.environmentValidatorService.environmentSelector();
  displayedColumns: string[] = DEVICE_SETTINGS_COLUMNS;
  dataSource;
  serialNumbersResult = [];
  actionSelected = '';
  user;
  serialNumberSelected = '';
  message = '';
  showButton;
  empForm: FormGroup;
  itemsSelected = [];

  userServiceSubscription: Subscription | undefined;
  deviceSettingsSubscription: Subscription | undefined;
  lastRebootSubscription: Subscription | undefined;
  loaderSub: Subscription;
  dataSourceSub: Subscription;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private router: Router,
    private environmentValidatorService: EnvironmentValidatorService,
    private deviceSettingsService: DeviceSettingsService,
    private formBuilder: FormBuilder,
    private datadogService: DatadogService,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<ScheduleModalComponent>,
    private dialogResult: MatDialogRef<ModalResultComponent>,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    this.dataDogLogs();
    this.validatingDataInCache();
    this.getCurrentUser();
    this.empForm = this.formBuilder.group({
      actionControl: [null]
    });
  }

  ngOnDestroy(): void {
    this.userServiceSubscription?.unsubscribe();
    this.deviceSettingsSubscription?.unsubscribe();
    this.lastRebootSubscription?.unsubscribe();
    this.loaderSub?.unsubscribe();
    this.dataSourceSub?.unsubscribe();
  }

  /**
   * getCurrentUser()
   * get the current user
   */
  getCurrentUser() {
    this.userServiceSubscription =
      this.environmentValidatorService.currentUser.subscribe((user) => {
        if (user) {
          this.user = user;
        }
      });
  }

  /**
   * dataDogLogs()
   * check and share the logs in DD
   */
  dataDogLogs() {
    this.datadogService.log(
      `${GlobalVariables.USER_EMAIL} accessed Device-Settings Page`,
      {
        name: 'Device-Settings Component',
        id: 1005,
        userName: GlobalVariables.USER_GIVEN_NAME,
        userEmail: GlobalVariables.USER_EMAIL
      },
      'info'
    );
  }

  /**
   * validatingDataInCache()
   * check if there is data stored in the cache
   * and populate the table otherwise it retrieves the
   * data source using getDataSource() method
   */
  validatingDataInCache() {
    this.companyName = localStorage.getItem('CUSTOMER_NAME');
    this.accountCode = localStorage.getItem('CUSTOMER_ACCOUNT_CODE');
    if (this.accountCode === null || this.accountCode === undefined) {
      this.router.navigate([
        '/account/select-account',
        { previousUrl: this.router.url }
      ]);
    } else {
      get(DEVICE_CONFIG_MAPPING_KEY).then((dataSource: any[]) => {
        if (dataSource !== undefined) {
          this.loadTable(dataSource);
        } else {
          this.getDataSource();
        }
      });
      this.pageLoading = false;
    }
  }

  /**
   * getDataSource()
   * retrieve the data source from the store
   * then the response is set in setLoadTable()
   */
  getDataSource() {
    this.loaderSub = this.store
      .select('ui')
      .subscribe((ui) => (this.tableLoading = ui.isLoading));
    this.dataSourceSub = this.store
      .select('dataSource')
      .subscribe((response) => {
        if (response['dataSource']?.length > 0) {
          this.setLoadTable(response['dataSource']);
        }
      });
  }

  /**
   * setLoadTable()
   * @param data
   * pass the data source to loadTable()
   */
  async setLoadTable(data: DeviceConfigMapping[]) {
    this.loadTable(data);
  }

  /**
   * loadTable()
   * @param deviceConfigMapping
   * Sort and Filter the Data Source then it populates the table
   * stop the loaders
   * set the pagination functionality
   * once the data is set then it calls lastReboot()
   */
  loadTable(deviceConfigMapping: DeviceConfigMapping[]): void {
    let arrayForSort = [...deviceConfigMapping];
    arrayForSort = arrayForSort.sort((a: any, b: any) => {
      return a.asset_name - b.asset_name;
    });

    arrayForSort = arrayForSort.filter(
      (obj, index, self) =>
        obj.serial_number !== undefined &&
        obj.serial_number !== null &&
        index === self.findIndex((o) => o.serial_number === obj.serial_number)
    );

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < arrayForSort.length; i++) {
      arrayForSort[i].action_list =
        this.environmentValidatorService.environmentSelector();
      arrayForSort[i].action_selected = '';
      arrayForSort[i].serial_number_selected = '';
      arrayForSort[i].enable_checkbox = false;
      arrayForSort[i].last_reboot = '';
    }

    arrayForSort = arrayForSort.filter(
      (obj, index, self) =>
        obj.serial_number !== undefined &&
        obj.serial_number !== null &&
        index === self.findIndex((o) => o.serial_number === obj.serial_number)
    );

    this.dataSource = new MatTableDataSource(arrayForSort);
    this.paginator.pageSize = 10;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.loaderSub = this.store
      .select('ui')
      .subscribe((ui) => (this.tableLoading = ui.isLoading));
  }

  /**
   * onSchedule()
   * gather the data selected from the table then shows
   * the Schedule Modal
   * uses preventDuplicates() to prevent items duplicated
   */
  onSchedule() {
    this.dataSource.data.forEach((x) => {
      if (
        x.serial_number_selected !== '' &&
        x.action_selected !== '' &&
        x.checked === true
      ) {
        this.preventDuplicates(this.itemsSelected, {
          assetName: x.asset_name,
          serialNumberSelected: x.serial_number_selected,
          actionSelected: x.action_selected,
          user: this.user
        });
      }
      if (x.action_selected === '' && x.checked === true) {
        x.checked = false;
      }
    });
    this.onScheduleModal();
  }

  /**
   * preventDuplicates()
   * @param array
   * @param item
   * prevents items duplicated in the array
   */
  preventDuplicates(array, item) {
    if (
      !array.find(
        ({ serialNumberSelected }) =>
          serialNumberSelected === item.serialNumberSelected
      )
    ) {
      array.push(item);
    }
  }

  /**
   * onScheduleModal()
   * show a modal with the items selected
   */
  onScheduleModal() {
    this.dialogRef = this.dialog.open(ScheduleModalComponent, {
      data: this.itemsSelected,
      width: DEVICE_DIALOG_WIDTH
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result.event === 'submit') {
        this.schedule(result.data);
        this.itemsSelected = [];
        this.reset();
      } else {
        this.itemsSelected = [];
      }
    });
  }

  /**
   * schedule()
   * @param data
   * process the data selected scheduling them
   * if the process is successful then it shows
   * a new modal with the results
   */
  schedule(data) {
    data.forEach((x) => {
      this.deviceSettingsSubscription = this.deviceSettingsService
        .postSchedule(x.serialNumberSelected, x.user, x.actionSelected)
        .subscribe(
          (res) => {
            if (res) {
              this.serialNumbersResult.push({
                assetName: x.assetName,
                serialNumber: x.serialNumberSelected,
                action: x.actionSelected,
                response: res
              });
            }
          },
          (err) => {
            this.errorHandler(err);
          },
          () => {
            if (
              this.compareOutput(data.length, this.serialNumbersResult.length)
            ) {
              this.openResultModal();
            }
          }
        );
    });
  }

  /**
   * compareOutput()
   * @param data
   * @param itemSelected
   * @return boolean
   */
  compareOutput(data, itemSelected) {
    return data === itemSelected;
  }

  /**
   * openResultModal()
   * open a modal with the results
   * clean the serialNumbersResult array
   */
  openResultModal() {
    this.dialogResult = this.dialog.open(ModalResultComponent, {
      data: this.serialNumbersResult,
      width: DEVICE_DIALOG_WIDTH
    });
    this.serialNumbersResult = [];
  }

  /**
   * updateChecked()
   * @param serialNumber
   * @param itemChecked
   * logic implemented to update when the user
   * selects or unselects an item
   * it sorts the items
   * enable or disable the schedule button
   */
  updateChecked(serialNumber, itemChecked) {
    const itemSelected = !itemChecked;

    this.dataSource.data.forEach((t) => {
      if (t.serial_number === serialNumber) {
        t.checked = itemSelected;
      }
    });

    this.dataSource.data.forEach((t) => {
      if (
        t.action_selected !== '' &&
        t.checked === false &&
        t.serial_number === serialNumber
      ) {
        t.action_list = null;
        t.action_selected = '';
        t.checked = false;
      }
    });

    const enableButton = this.dataSource.data.every(
      (item) => item.checked === false
    );
    this.showButton = !enableButton;
    this.dataSource.data.forEach((t) => {
      if (t.serial_number === serialNumber && t.action_selected !== '') {
        t.checked = itemSelected;
      }
    });

    this.dataSource.data = this.dataSource.data.sort((a: any, b: any) => {
      return b.checked - a.checked;
    });
  }

  /**
   * reset()
   * once the data is processed the table
   * returns to the initial conditions
   */
  reset() {
    this.dataSource.data.forEach((x) => {
      if (x.checked === true) {
        x.checked = false;
      }
      if (x.enable_checkbox === true) {
        x.enable_checkbox = false;
      }
      if (x.action_selected !== '') {
        x.action_selected = '';
        x.action_list = null;
      }
    });
    this.showButton = !this.showButton;
  }

  /**
   * onSearch()
   * @param event
   * logic implemented to search the element typed in the input
   */
  onSearch(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /**
   * @param device
   * @param actionSelected
   * logic implemented to handle the dropdown options
   */
  selected(device?: any, actionSelected?: string) {
    this.actionSelected = actionSelected;
    this.serialNumberSelected = device.serial_number;

    this.dataSource.data.forEach((t) => {
      t.engineSerialNumber === this.serialNumberSelected
        ? (t.action_selected = this.actionSelected)
        : // tslint:disable-next-line:no-unused-expression
          null;
    });
    this.dataSource.data.forEach((t) => {
      if (t.action_selected !== '') {
        t.serial_number_selected = t.serial_number;
      }
    });
  }

  /**
   * onRestartValues()
   * @param serialNumber
   * Restart values from the dropdown
   */
  onRestartValues(serialNumber) {
    this.dataSource.data.forEach((t) => {
      if (t.action_list === null && t.serial_number === serialNumber) {
        t.action_list = this.environmentValidatorService.environmentSelector();
      }
    });

    this.dataSource.data.forEach((t) => {
      if (t.action_selected === undefined) {
        t.checked = false;
        t.enable_checkbox = false;
      }
    });
  }

  /**
   * Error Handler
   * @params error
   * Show an error using an alert
   */
  errorHandler(error) {
    Swal.fire({
      icon: 'error',
      title: 'Error!',
      text: error.message
    });
  }
}
