<mat-card>
  <div id="table-title">Device Provisioning</div>

  <div id="refresh-container" class="submenu-margin"></div>

  <mat-card-content>
    <form [formGroup]="deviceProvisioningForm">
      <mat-form-field appearance="fill">
        <mat-label>Serial Number:</mat-label>
        <input
          matInput
          aria-label="Select Package"
          [matAutocomplete]="auto"
          formControlName="serialNumberFormControl"
          name="serialNumberFormControl"
          (keyup.enter)="onSearch($event.target.value)"
        />
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option
            (click)="onDeviceSelect(device)"
            *ngFor="let device of filteredDevices | async"
            [value]="device.mfrSerialNum"
          >
            {{ device.mfrSerialNum }}
          </mat-option>
        </mat-autocomplete>
        <mat-error
          *ngIf="
            deviceProvisioningForm
              .get('serialNumberFormControl')
              .hasError('required')
          "
          >Please select serial number</mat-error
        >
      </mat-form-field>
      <button
        mat-raised-button
        (click)="refreshData()"
        style="transform: scale(1)"
        id="refresh-button"
        [disabled]="tableLoading"
      >
        <mat-icon>refresh</mat-icon>
      </button>
      <div>
        <mat-radio-group
          aria-label="Select an option"
          formControlName="actionType"
          name="actionType"
          matTooltip="Provision process will enable device on Clearblade IoT"
          [matTooltipPosition]="'above'"
        >
          <mat-radio-button
            class="radio-selection"
            *ngFor="let item of radioActionList"
            [value]="item.action"
            [disabled]="item.disabled"
            color="primary"
            (change)="radioActionChange($event)"
          >
            {{ item.name }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </form>
    <mat-progress-bar
      mode="indeterminate"
      *ngIf="tableLoading"
    ></mat-progress-bar>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <!-- Asset Name Column -->
      <ng-container matColumnDef="asset_name">
        <th mat-header-cell *matHeaderCellDef>Asset Name</th>
        <td mat-cell *matCellDef="let element">{{ element.assetName }}</td>
      </ng-container>

      <!-- Serial Number Column -->
      <ng-container matColumnDef="serial_number">
        <th mat-header-cell *matHeaderCellDef>Serial Number</th>
        <td mat-cell *matCellDef="let element">
          {{ element.mfrSerialNum }}
        </td>
      </ng-container>

      <!-- Device ID Column -->
      <ng-container matColumnDef="device_id">
        <th mat-header-cell *matHeaderCellDef>Device ID</th>
        <td mat-cell *matCellDef="let element">
          {{ element.device_id }}
        </td>
      </ng-container>

      <!-- Device Type Column -->
      <ng-container matColumnDef="deviceType">
        <th mat-header-cell *matHeaderCellDef>Device Type</th>
        <td mat-cell *matCellDef="let element">{{ element.deviceType }}</td>
      </ng-container>

      <!-- VIN Number Column -->
      <ng-container matColumnDef="vin_number">
        <th mat-header-cell *matHeaderCellDef>VIN Number</th>
        <td mat-cell *matCellDef="let element">{{ element.vin_number }}</td>
      </ng-container>

      <!-- Registry Type Column -->
      <ng-container matColumnDef="registry_type">
        <th mat-header-cell *matHeaderCellDef>Registry Type</th>
        <td mat-cell *matCellDef="let element">
          {{ element.registryType }}
        </td>
      </ng-container>

      <!-- Firmware Version Column -->
      <ng-container matColumnDef="firmware_version">
        <th mat-header-cell *matHeaderCellDef>Firmware Version</th>
        <td mat-cell *matCellDef="let element">
          {{ element.firmware }}
        </td>
      </ng-container>
      <!-- Provisioning Status Number Column -->
      <ng-container matColumnDef="iot_status">
        <th mat-header-cell *matHeaderCellDef>Provisioning Status</th>
        <td mat-cell *matCellDef="let element">
          {{ element.iot_status }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </mat-card-content>
  <mat-card-footer align="end">
    <button
      mat-raised-button
      (click)="provisionDevice()"
      [disabled]="!deviceProvisioningForm.valid"
    >
      {{ buttonLabel }}
    </button>
  </mat-card-footer>
</mat-card>
