import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DeviceConfigMapping } from '../../core/_models/DeviceConfigMapping';
import { ConfigDialogComponent } from './config-dialog/config-dialog.component';
import { Router } from '@angular/router';
import {
  DEVICE_DIALOG_HEIGHT,
  DEVICE_DIALOG_WIDTH,
  DISPLAYED_COLUMNS
} from './constants';
import { clear, get } from 'idb-keyval';
import { DEVICE_CONFIG_MAPPING_KEY } from '../publishconfig/constants';
import { ViewDeviceDialogComponent } from './view-device-dialog/view-device-dialog.component';
import { MatSort } from '@angular/material/sort';
import { DatadogService } from 'src/app/core/_services/datadog.service';
import { GlobalVariables } from 'src/app/core/_common/GlobalVariables';
import { AppState } from '../../store/app.state';
import { Store } from '@ngrx/store';
import { DivisionsAssetsDevicesService } from '../../core/_services/divisions-assets-devices.service';
import { DataSourceMappedService } from '../../core/_services/data-source-mapped.service';
import { Subscription } from 'rxjs';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CsvExportService } from '../../csv-export.service';

@Component({
  selector: 'app-asset-mapping',
  templateUrl: './asset-mapping.component.html',
  styleUrls: ['./asset-mapping.component.scss']
})
export class AssetMappingComponent implements OnInit, OnDestroy {
  pageLoading = true;
  refreshLoader = false;
  tableLoading = false;
  displayedColumns: string[] = DISPLAYED_COLUMNS;
  dataSource;
  accountCode: string;
  loaderSub: Subscription;
  dataSourceSub: Subscription;
  assetNameSub: Subscription;
  assetNameList = [];
  tempDS = [];
  assetNameForm: FormGroup = this.formBuilder.group({
    assetName: new FormControl('')
  });

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private datadogService: DatadogService,
    private store: Store<AppState>,
    private divisionsAssetsDevicesService: DivisionsAssetsDevicesService,
    private dataSourceMappedService: DataSourceMappedService,
    private formBuilder: FormBuilder,
    private csvExport: CsvExportService
  ) {}

  ngOnInit() {
    this.dataDogLogs();
    this.validatingDataInCache();
    this.getAssetName();
  }

  ngOnDestroy() {
    this.loaderSub?.unsubscribe();
    this.dataSourceSub?.unsubscribe();
    this.assetNameSub?.unsubscribe();
  }

  getAssetName() {
    this.assetNameSub = this.assetNameForm.valueChanges.subscribe(
      (newValue: any) => {
        if (newValue.assetName !== '') {
          this.filteringTable(newValue.assetName);
        }
      }
    );
  }

  filteringTable(keyword) {
    let temp = [];
    if (keyword.length === this.assetNameList.length || keyword.length === 0) {
      get(DEVICE_CONFIG_MAPPING_KEY).then((dataSource: any[]) => {
        this.dataSource.data = dataSource;
      });
    } else {
      get(DEVICE_CONFIG_MAPPING_KEY).then((dataSource: any[]) => {
        this.tempDS = dataSource;
        this.tempDS.filter((element) => {
          return keyword.some((item) => {
            if (element.asset_name === item) {
              temp.push(element);
            }
          });
        });
        this.dataSource.data = temp;
      });
    }
  }

  /**
   * dataDogLogs()
   * check and share the logs in DD
   */
  dataDogLogs() {
    this.datadogService.log(
      `${GlobalVariables.USER_EMAIL} accessed Config-Mapping Page`,
      {
        name: 'Asset-Mapping Component',
        id: 1002,
        userName: GlobalVariables.USER_GIVEN_NAME,
        userEmail: GlobalVariables.USER_EMAIL
      },
      'info'
    );
  }

  /**
   * validatingDataInCache()
   * check if there is data stored in the cache
   * and populate the table otherwise it retrieves the
   * data source using getDataSource() method
   */
  validatingDataInCache() {
    this.store.select('account').subscribe((res) => {
      if (res.account?.length === 0 || res.account === undefined) {
        this.router.navigate([
          '/account/select-account',
          { previousUrl: this.router.url }
        ]);
      } else {
        get(DEVICE_CONFIG_MAPPING_KEY).then((dataSource: any[]) => {
          if (dataSource !== undefined) {
            this.loadTable(dataSource);
          } else {
            this.getDataSource();
          }
        });
        this.pageLoading = false;
      }
    });
  }

  /**
   * getDataSource()
   * retrieve the data source from the store
   * then the response is set in setLoadTable()
   */
  getDataSource() {
    this.loaderSub = this.store
      .select('ui')
      .subscribe((ui) => (this.tableLoading = ui.isLoading));
    this.dataSourceSub = this.store
      .select('dataSource')
      .subscribe((response) => {
        if (response['dataSource']?.length > 0) {
          this.setLoadTable(response['dataSource']);
        }
      });
  }

  /**
   * setLoadTable()
   * @param data
   * pass the data source to loadTable()
   */
  async setLoadTable(data: DeviceConfigMapping[]) {
    this.loadTable(data);
  }

  /**
   * loadTable()
   * @param deviceConfigMapping
   * Sort and Filter the Data Source then it populates the table
   * stop the loaders
   * set the pagination functionality
   */
  loadTable(deviceConfigMapping: DeviceConfigMapping[]): void {
    let arrayForSort = [...deviceConfigMapping];
    arrayForSort = arrayForSort.sort((a: any, b: any) => {
      return a.asset_name - b.asset_name;
    });
    arrayForSort = arrayForSort.filter(
      (obj, index, self) =>
        obj.serial_number !== undefined &&
        obj.serial_number !== null &&
        index === self.findIndex((o) => o.serial_number === obj.serial_number)
    );
    this.dataSource = new MatTableDataSource(arrayForSort);
    this.populateDropDown();
    this.paginator.pageSize = 10;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.loaderSub = this.store
      .select('ui')
      .subscribe((ui) => (this.tableLoading = ui.isLoading));
    this.refreshLoader = false;
  }

  populateDropDown() {
    if (this.dataSource.data.length > 0) {
      //  this.assetNameList.push({ title: 'All', value: 'All' });
      this.dataSource.data.forEach((x) => {
        this.preventDuplicates(this.assetNameList, {
          title: x.asset_name,
          value: x.asset_name
        });
      });
      this.assetNameList = this.assetNameList.sort((a, b) => a.title - b.title);
    }
  }

  preventDuplicates(array, item) {
    if (!array.find(({ value }) => value === item.value)) {
      array.push(item);
    }
  }

  /**
   * applyFilter()
   * @param event
   * filter the data given an input
   */
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /**
   * viewConfigDialog()
   * @param device
   * Shows View Config Modal
   */
  viewConfigDialog(device: any) {
    this.dialog.open(ConfigDialogComponent, {
      data: device,
      height: DEVICE_DIALOG_HEIGHT,
      width: DEVICE_DIALOG_WIDTH,
      panelClass: 'current-config'
    });
  }

  /**
   * viewDeviceDialog()
   * @param device
   * Shows View Device Modal
   */
  viewDeviceDialog(device: any) {
    this.dialog.open(ViewDeviceDialogComponent, {
      data: device,
      height: DEVICE_DIALOG_HEIGHT,
      width: DEVICE_DIALOG_WIDTH
    });
  }

  /**
   * refreshData()
   * clean all the containers and cache
   * triggers the Data Dog service
   * uses getDeviceList() to make a new API call from scratch
   * uses refreshingData() to retrieve the new data generated from the BE
   */
  refreshData() {
    this.refreshLoader = true;
    this.dataSourceMappedService.cleanContainersAndCache();
    this.divisionsAssetsDevicesService.getDeviceList();
    this.datadogService.log(
      `${GlobalVariables.USER_EMAIL} clicked "Refresh Data" button in "Config-Mapping" Page`,
      {
        name: 'Refresh button - Asset-Mapping Component',
        id: 10021,
        userName: GlobalVariables.USER_GIVEN_NAME,
        userEmail: GlobalVariables.USER_EMAIL
      },
      'info'
    );
    clear();
    this.refreshingData();
  }

  /**
   * refreshingData()
   * retrieves the new data generated from the BE
   */
  refreshingData() {
    this.dataSourceSub = this.store
      .select('dataSource')
      .subscribe((response) => {
        if (response['dataSource']?.length > 0) {
          this.setLoadTable(response['dataSource']);
        }
        if (response['dataSource']?.length === 0) {
          this.refreshLoader = false;
        }
      });
  }

  public exportCSV(data: any): void {
    this.csvExport.exportCSV(data.filteredData);
  }
}
