import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IotProvisioningApiService {
  constructor(private http: HttpClient) {}

  async provisionDevice(body: any): Promise<Observable<any>> {
    return await this.http
      .post(`${environment.iotprovisioningapi}/device-provisioning`, body)
      .pipe(
        catchError((err) => {
          console.log('Error caught in IoT Device Provisioing service');
          console.error(err);
          //Handle the error here
          return throwError(err); //Rethrow it back to component
        })
      );
  }
}
