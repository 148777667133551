<mat-card>
  <div id="table-title">Datasets</div>

  <div id="refresh-container">
    <button
      mat-raised-button
      id="refresh-button"
      matTooltip="Add New Dataset"
      (click)="actionDataset('Create')"
    >
      <mat-icon>add</mat-icon>
      Add New
    </button>
    <button
      mat-raised-button
      id="refresh-button"
      matTooltip=" Refresh Dataset List"
      (click)="refreshDataset()"
    >
      <mat-icon>refresh</mat-icon>
    </button>

    <button
      class="export-button"
      mat-raised-button
      (click)="exportCSV(dataSource)"
    >
      Export CSV
    </button>
  </div>

  <mat-card id="selections">
    <mat-card-content>
      <div id="top-row">
        <div class="top-row-item">
          <input
            id="search-input"
            matInput
            (keyup)="applyFilter($event)"
            placeholder="Ex. GPS_VEHICLE_DATA"
            #input
          />
        </div>

        <div class="top-row-item">
          <!--  <mat-select
              placeholder="Additional Selection"
              panelClass="my-select-panel-class"
            >
            </mat-select> -->
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <div class="mat-elevation-z8">
    <mat-progress-bar
      mode="indeterminate"
      *ngIf="tableLoading"
    ></mat-progress-bar>
    <table mat-table [dataSource]="dataSource" matSort>
      <!-- Name  Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let element">
          <a class="a-left" mat-button (click)="viewDatasetConfig(element)"
            >{{ element.name }}
          </a>
        </td>
      </ng-container>

      <!-- Type Column Column -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
        <td mat-cell *matCellDef="let element">{{ element.type }}</td>
      </ng-container>

      <!-- Status Column Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let element">{{ element.status }}</td>
      </ng-container>

      <!-- Creation Date Column -->
      <ng-container matColumnDef="created">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Creation Date</th>
        <td mat-cell *matCellDef="let element">
          {{ element.created | date: 'MM/dd/yyyy HH:mm:ss' }}
        </td>
      </ng-container>

      <!-- Created By Column -->
      <ng-container matColumnDef="modified">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Modified Date</th>
        <td mat-cell *matCellDef="let element">
          {{ element.modified | date: 'MM/dd/yyyy HH:mm:ss' }}
        </td>
      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            color="accent"
            aria-label="Clone package"
            (click)="actionDataset('Clone', element)"
          >
            <mat-icon class="mat-icon-color">file_copy</mat-icon>
          </button>
          <!-- <button mat-icon-button color="accent" aria-label="Edit Package" (click)="actionDataset('Edit', element)">
            <mat-icon>edit</mat-icon>
          </button>
           <button mat-icon-button color="accent" (click)="deletePackage()"
                                  aria-label="Delete button with a menu icon">
                                  <mat-icon>delete_forever</mat-icon>
                              </button> -->
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="6">
          No data matching the filter " {{ input.value }}"
        </td>
      </tr>
    </table>
    <mat-paginator
      [pageSizeOptions]="[5, 10, 20]"
      showFirstLastButtons
      aria-label="Select page of periodic elements"
    >
    </mat-paginator>
  </div>
</mat-card>
